import { useState, useContext } from "react";
import { Alert } from "../../../../components/Alert/Alert";
import { VARIANT } from "../../../../components/Alert/types";
import { Button } from "../../../../components/Button/Button";
import { Input } from "../../../../components/Input/Input/Input";
import { InvitePageContext } from "../../InvitePage/InvitePageContext";

// import { Factoid } from "../../../../components/Infographic/Factoid/Factoid";
// import { baseColors } from "../../../../constants/main";
import {
  StyledForm,
  StyledFormButtonContainer,
  // StyledFactoidsContainer,
} from "../../styled";

export const InviteForm = () => {
  const {
    setUser: setRootUser,
    setPass: setRootPass,
    id,
    // amountInvites,
    // name,
    inviteError,
    inviteUser,
  } = useContext(InvitePageContext);

  const [user, setUser] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const btnsEnabled = user !== "" && pass !== "" && !!id;
  const isError =
    inviteError.code !== undefined || inviteError.message !== undefined;

  const clickHandler = () => {
    setRootUser(user);
    setRootPass(pass);
    inviteUser(id!, user, pass);
    setUser("");
    setPass("");
  };

  // const Count = (
  //   <div
  //     style={{
  //       padding: "0 8px",
  //       borderRadius: "4px",
  //       background: baseColors.primary(),
  //       fontSize: "12px",
  //       lineHeight: "20px",
  //       fontWeight: "700",
  //       textAlign: "center",
  //     }}
  //   >
  //     {amountInvites}
  //   </div>
  // );

  return (
    <>
      {isError && (
        <Alert
          variant={VARIANT.ERROR}
          code={inviteError.code}
          message={inviteError.message}
        />
      )}
      <StyledForm>
        <Input label="User" value={user} onChange={(e) => setUser(e)} />
        <Input
          label="Password"
          value={pass}
          onChange={(e) => setPass(e)}
          type="password"
        />
        <StyledFormButtonContainer>
          <Button disabled={!btnsEnabled} onClick={clickHandler}>
            Create
          </Button>
        </StyledFormButtonContainer>
      </StyledForm>
    </>
  );
};
