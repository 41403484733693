import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    body{
        font-family: 'Source Sans Pro', sans-serif;
        color: #fff;
        background-color: #120D21;
    }
    h1, h2{
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 46px;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    h2{
        color: rgba(255, 255, 255, 0.72);
        text-align: left;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding: 0;
    }
    .Page{
        max-width: 804px;
        margin: 0 auto;
        min-height: calc(100vh - 136px);
    }
    button{
        outline: none;
        border: none;
        width: fit-content;
        color: #fff;
    }
`;
