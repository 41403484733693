import { nanoid } from '@reduxjs/toolkit';
import { baseColors } from '@/constants';
import { STATUS_LAB } from '@/constants';
import {
    StyledContainer,
    StyledSeparator,
    StyledWrapper,
    StyledTitle,
    StyledStatus,
} from './styled';
import {
    OkIcon,
    EmptyIcon,
    LoaderIcon,
    Tooltip,
    RejectIcon,
} from '@/components';
import { ILog } from '@/types/vm';
import { LOG_STATUS } from '@/constants/vm';

interface IProps {
    logs?: ILog[];
    status: STATUS_LAB;
}

export const StatusOfVM = ({ logs = [], status }: IProps) => {
    const icon = {
        [LOG_STATUS.FINISHED]: <OkIcon variant="S" fill={baseColors.ok()} />,
        [LOG_STATUS.QUEUED]: (
            <EmptyIcon variant="S" fill={baseColors.white(0.25)} />
        ),
        [LOG_STATUS.INSTALLING]: (
            <LoaderIcon variant="S" fill={baseColors.primary()} />
        ),
    };

    const RenderTooltipContent = (log: ILog) => {
        return (
            <StyledWrapper>
                <StyledTitle>{log.name}</StyledTitle>
                <StyledStatus>
                    {status !== STATUS_LAB.DEACTIVATED
                        ? log.status
                        : 'DESTROYED'}
                </StyledStatus>
            </StyledWrapper>
        );
    };
    return (
        <StyledContainer>
            {logs.map((l, idx, arr) => {
                return (
                    <StyledContainer key={idx}>
                        <Tooltip content={RenderTooltipContent(l)}>
                            {status !== STATUS_LAB.DEACTIVATED ? (
                                icon[l.status]
                            ) : (
                                <RejectIcon
                                    variant="S"
                                    fill={baseColors.white(0.25)}
                                />
                            )}
                        </Tooltip>
                        {idx !== arr.length - 1 && <StyledSeparator />}
                    </StyledContainer>
                );
            })}
        </StyledContainer>
    );
};
