import { ReactNode, useEffect, useState } from "react";
import { IconButton } from "../../../IconButton/IconButton";
import { ShevronUp, ShevronDown } from "../../../Icons/index";
import {
  StyledFooter,
  StyledFooterHeader,
  StyledFooterTitleWrapper,
  StyledFooterTitle,
  StyledFooterBody,
} from "../../styled";

interface CardFooterProps {
  children: ReactNode;
  header: string | ReactNode;
  isStaticallyOpen?: "opened" | "closed";

  onClickSchevron?: () => void;
}

export const CardFooter = (props: CardFooterProps) => {
  const { children, header, isStaticallyOpen, onClickSchevron } = props;
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const clickHandler = () => {
    setIsExpand(!isExpand);
    if (onClickSchevron) onClickSchevron();
  };
  useEffect(() => {
    if(isStaticallyOpen === 'closed'){
      setIsExpand(false);
    }
    if(isStaticallyOpen === 'opened'){
      setIsExpand(true);
    }

  }, [isStaticallyOpen, setIsExpand])
  return (
    <StyledFooter>
      <StyledFooterHeader>
        <StyledFooterTitleWrapper>
          {
            header && typeof header === 'string'
            ? <StyledFooterTitle>{header}</StyledFooterTitle>
            : header
          }
        </StyledFooterTitleWrapper>

        {!isStaticallyOpen ? (
          <IconButton
            icon={isExpand ? <ShevronUp /> : <ShevronDown />}
            onClick={clickHandler}
          />
        ) : isStaticallyOpen === "closed" ? (
          <IconButton icon={<ShevronDown />} />
        ) : (
          <IconButton icon={<ShevronUp />} />
        )}
      </StyledFooterHeader>
      <StyledFooterBody>{isExpand ? children : null}</StyledFooterBody>
    </StyledFooter>
  );
};
