import {
  StyledWrapper,
  StyledTop,
  StyledBottom,
  StyledItem,
  StyledHeadline,
  StyledText,
  StyledStatusWrapper,
  StyledStatusCircle,
  StyledTopLine,
  StyledBottomLine,
} from "../styled";

export const InfoHint = () => {
  return (
    <StyledItem width={96} style={{ textAlign: "right" }}>
      <StyledHeadline>Information</StyledHeadline>
    </StyledItem>
  );
};
