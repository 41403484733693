import { memo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PageHeader } from '@/components';
import { StyledPageContainer } from '../styled';
import { Actions, AddHighlight } from './components';
import { HIGHLIGHT_TYPE, LAB_TYPE, STATUS_LAB } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { runAttack, runWorkshopAttack } from '@/store/reducers/AttackSlice';
import { HighlightContext } from '@/context/highlightContext';
import { LabContext } from '@/context/labContext';
import {
    destroyWorkshopLab,
    runWorkshopLab,
} from '@/store/reducers/LaunchCardsSlice';

export const WorkshopLab = memo(function Lab() {
    const { LabsSlice, launchCardReducer } = useAppSelector((state) => state);
    const { lab, isLoadingLab } = LabsSlice;
    const { currentCard } = launchCardReducer;
    const dispatch = useAppDispatch();
    const [currentHLType, setCurrentHLType] = useState<
        HIGHLIGHT_TYPE | undefined
    >();
    const handleCurrentType = (ct: HIGHLIGHT_TYPE | undefined) => {
        setCurrentHLType(ct);
    };

    const [isOpened, setIsOpened] = useState(false);
    const handleOpening = (isOp: boolean) => {
        setIsOpened(isOp);
    };

    return (
        <HighlightContext.Provider
            value={{
                currentType: currentHLType,
                isOpened,
                setIsOpened: handleOpening,
                setCurrentType: handleCurrentType,
            }}
        >
            <LabContext.Provider
                value={{
                    labUrl: 'workshoplabs',
                    labType: LAB_TYPE.WORKSHOP,
                }}
            >
                <StyledPageContainer>
                    <AddHighlight labType={LAB_TYPE.WORKSHOP} />
                    <div
                        style={{
                            marginRight: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            gap: 10,
                        }}
                    >
                        {lab && currentCard && (
                            <PageHeader
                                title={isLoadingLab ? 'Loading...' : lab?.name}
                                actions={[
                                    <Actions
                                        recreated={currentCard.recreated}
                                        endTime={
                                            currentCard?.planned_destroy_date ||
                                            ''
                                        }
                                        status={
                                            currentCard?.status ||
                                            STATUS_LAB.OPERATIONAL
                                        }
                                        runLab={() => {
                                            if (lab) {
                                                dispatch(
                                                    runWorkshopLab(
                                                        currentCard.uuid
                                                    )
                                                );
                                            }
                                        }}
                                        destroyLab={() => {
                                            if (lab) {
                                                dispatch(
                                                    destroyWorkshopLab(
                                                        currentCard.uuid
                                                    )
                                                );
                                            }
                                        }}
                                        refreshLab={() => {
                                            if (lab) {
                                                dispatch(
                                                    runWorkshopLab(
                                                        currentCard.uuid
                                                    )
                                                );
                                            }
                                        }}
                                        runAttack={(playbookId) => {
                                            dispatch(
                                                runWorkshopAttack({
                                                    uuid: currentCard.uuid,
                                                    playbook_id:
                                                        playbookId as string,
                                                })
                                            );
                                        }}
                                    />,
                                ]}
                            />
                        )}
                        <Outlet />
                    </div>
                </StyledPageContainer>
            </LabContext.Provider>
        </HighlightContext.Provider>
    );
});
