import { css } from 'styled-components';
import { IStatsOfTheLab } from '@/types/labs';
import { StyledBlockContainer } from '../../styled';
import { StyledFactoids, StyledList, StyledTitle } from './styled';
import { Factoid, TextItem } from '@/components';

const styleThreeCols = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
`;

interface IProps {
    stats?: IStatsOfTheLab;
}

export const Information = ({ stats }: IProps) => {
    return (
        <StyledBlockContainer styleProps={styleThreeCols}>
            <StyledFactoids>
                <Factoid
                    asterisk
                    isCompared
                    value={stats?.points}
                    comparedValue={stats?.points_prev}
                    unit="Points"
                    description="My number of points for the lab"
                />
                <Factoid
                    value={stats?.avg_points}
                    unit="Points"
                    description="Average score for all users"
                />
            </StyledFactoids>
            <StyledFactoids>
                <Factoid
                    isCompared
                    value={stats?.delta}
                    comparedValue={stats?.delta_prev}
                    unit="Seconds"
                    description="My attack detection time"
                />
                <Factoid
                    value={stats?.delta_avg}
                    unit="Seconds"
                    description="Average time to detect by all users"
                />
            </StyledFactoids>
            <StyledList>
                <StyledTitle>Launch</StyledTitle>
                <TextItem
                    cut
                    widthKey={88}
                    keyText="Start date"
                    valueText="12.24.2022, 19:53:32 (UTC)"
                />
                <TextItem
                    cut
                    widthKey={88}
                    keyText="Destroy date"
                    valueText="12.24.2022, 19:53:32 (UTC)"
                />
            </StyledList>
        </StyledBlockContainer>
    );
};
