import { useEffect, useState } from 'react';
import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
} from 'react-router-dom';
import {
    StyledContainer,
    StyledLeftCol,
    StyledRightCol,
    StyledPromoContainer,
    StyledPromoTitle,
    StyledPromoText,
    StyledContainerFormLogoHeader,
    StyledTitle,
    StyledSubtitle,
    StyledTitleContainer,
    StyledLogo,
    StyledLogoText,
    StyledTitleWrapper,
} from './styled';
import { IconLogo } from '../../components/Icons/index';
import { SignInForm } from './components/SignInForm/SignInForm';
import { SignUpForm } from './components/SignUpForm/SignUpForm';
import { PagesTabs } from '../../components/Tabs/PagesTabs/PagesTabs';
import { IData as ITab } from '../../components/Tabs/PagesTabs/types';

const tabs: ITab[] = [
    {
        value: 'Sign In',
        id: 'signin',
    },
    {
        value: 'Sign Up',
        id: 'signup',
    },
    {
        value: 'Write To Us',
        id: 'write_to_us',
    },
];

const title = [
    {
        title: 'Welcome back!',
        subtitle: 'Nice to see you again',
    },
    {
        title: 'Hello, friend!',
        subtitle:
            'Choose your username and fill in your email. We will send you a verification link',
    },
    {
        title: 'Write to us!',
        subtitle:
            'We are open to cooperation and have many ideas to improve our service and user experience',
    },
];

export const AuthPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [titleIndex, setTitleIndex] = useState(0);
    useEffect(() => {
        switch (location.pathname) {
            case '/auth/write_to_us':
                setTitleIndex(2);
                break;
            case '/auth/signup':
                setTitleIndex(1);
                break;
            case '/auth/signin':
            default:
                setTitleIndex(0);
        }
    }, [location, setTitleIndex]);

    return (
        <StyledContainer>
            <StyledLeftCol>
                <StyledLogo
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <IconLogo size={32} />
                    <StyledLogoText>DefenseBox</StyledLogoText>
                </StyledLogo>
                <StyledTitleContainer>
                    <StyledTitleWrapper>
                        <StyledTitle>{title[titleIndex].title}</StyledTitle>
                        <StyledSubtitle>
                            {title[titleIndex].subtitle}
                        </StyledSubtitle>
                    </StyledTitleWrapper>
                </StyledTitleContainer>
            </StyledLeftCol>
            <StyledRightCol>
                <PagesTabs
                    data={tabs}
                    activeKey={location.pathname === '/auth/signup' ? 1 : 0}
                    navLink
                />
                <Outlet />
                <StyledPromoContainer>
                    <StyledPromoTitle>
                        Experience real-Life Cyber Threats in a safe Environment
                    </StyledPromoTitle>
                    <StyledPromoText>
                        Our platform offers an exersise where users will have to
                        defend against cyber attack and compete among each other
                        to see who is the best cyber-defender
                    </StyledPromoText>
                    <StyledPromoText>
                        Our blue team competition service provides a unique
                        opportunity for users to practice their defensive skills
                        in a safe, controlled environment.
                    </StyledPromoText>
                </StyledPromoContainer>
                <StyledContainerFormLogoHeader>
                    <IconLogo
                        color="rgba(255, 255, 255, 0.25)"
                        cursor="pointer"
                        figureFill="rgba(255, 255, 255, 0.64)"
                    />
                    <span
                        style={{
                            fontSize: '18px',
                            lineHeight: '20px',
                            opacity: 0.25,
                        }}
                    >
                        DefenseBox
                    </span>
                </StyledContainerFormLogoHeader>
            </StyledRightCol>
        </StyledContainer>
    );
};
