import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const EditIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.061 1.33285C10.5048 0.889049 11.2244 0.889049 11.6681 1.33285L14.6672 4.33198C15.1109 4.77579 15.1109 5.49534 14.6672 5.93914L6.93853 13.6681C6.77702 13.8296 6.57899 13.9323 6.37102 13.9763L2.41203 14.966C2.02478 15.0629 1.61512 14.9494 1.33287 14.6671C1.05061 14.3849 0.93714 13.9752 1.03395 13.5879L2.02947 9.6057C2.05713 9.48731 2.10398 9.3725 2.17003 9.26622C2.22135 9.18338 2.283 9.10769 2.35327 9.04092L10.061 1.33285ZM6.13536 11.2569L4.74345 9.86497L10.8646 3.7436L12.2565 5.13556L6.13536 11.2569Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.1205 2.3151C16.7004 1.89497 16.0192 1.89497 15.5991 2.3151L3.84908 14.0651C3.78441 14.1276 3.72719 14.1985 3.67936 14.2767C3.61743 14.3777 3.5737 14.4867 3.54818 14.5991L2.03214 20.6632C1.94049 21.0298 2.04791 21.4177 2.31513 21.6849C2.58234 21.9521 2.97016 22.0595 3.33678 21.9679L9.40095 20.4518C9.51326 20.4263 9.62228 20.3826 9.72328 20.3206C9.80152 20.2728 9.87245 20.2156 9.93492 20.1509L21.6849 8.40092C22.105 7.98078 22.105 7.2996 21.6849 6.87947L17.1205 2.3151ZM7.05716 18.8199L5.18012 16.9428L4.55444 19.4456L7.05716 18.8199ZM6.11864 14.8385L16.3598 4.59729L19.4027 7.64019L9.16155 17.8814L6.11864 14.8385Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
