import { StyledInnerMarkedText } from './styled';
import rect from './Rectangle.svg';

interface IProps {
    color?: string;
    offset?: number;
    radius?: number;
    text: string;
}

export const InnerMarkedText = (props: IProps) => {
    const { text } = props;
    return (
        <StyledInnerMarkedText
            {...props}
            // style={{ backgroundImage: `url(${rect})` }}
        >
            {text}
        </StyledInnerMarkedText>
    );
};
