import { useRef, FC, useEffect, useState, CSSProperties } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ITimeLineInnerElements } from '@/types';
import {
    StyledPointFootnote,
    StyledPointFootnoteContent,
    StyledPointFootnoteContentItem,
    StyledPointFootnoteContentItemDot,
    StyledPointFootnoteContentItemDate,
} from '../../styled';
import { getDateFormat } from '../../utils';
dayjs.extend(utc);

interface FootnoteProps {
    points: ITimeLineInnerElements[];
    height: number;
    style?: CSSProperties;
    format?: string;
}

export const Footnote: FC<FootnoteProps> = (props) => {
    const { points, height, style, format } = props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [direction, setDirection] = useState<'left' | 'right'>('left');

    useEffect(() => {
        if (contentRef) {
            if (
                contentRef &&
                contentRef.current!.getClientRects()[0].right >=
                    document.body.offsetWidth -
                        document.body.offsetWidth / 10 -
                        48
            ) {
                setDirection('right');
            } else {
                setDirection('left');
            }
        }
    }, [contentRef, setDirection]);

    return (
        <StyledPointFootnote height={height} style={style}>
            <StyledPointFootnoteContent ref={contentRef} direction={direction}>
                {points.map((p, idx) => {
                    return (
                        <StyledPointFootnoteContentItem
                            direction={direction}
                            key={idx}
                        >
                            {direction === 'left' && (
                                <StyledPointFootnoteContentItemDot
                                    // style={{ background: 'green' }}
                                    view={p.type}
                                />
                            )}
                            {p.date.split('to').length === 2 ? (
                                <>
                                    <StyledPointFootnoteContentItemDate>
                                        {getDateFormat(p.date.split('to')[0])}
                                    </StyledPointFootnoteContentItemDate>
                                    <StyledPointFootnoteContentItemDate
                                        style={{ opacity: 0.5 }}
                                    >
                                        to
                                    </StyledPointFootnoteContentItemDate>
                                    <StyledPointFootnoteContentItemDate>
                                        {getDateFormat(p.date.split('to')[1])}
                                    </StyledPointFootnoteContentItemDate>
                                </>
                            ) : (
                                <StyledPointFootnoteContentItemDate>
                                    {/* {p.date.split("to")[0]} */}
                                    {getDateFormat(p.date, format)}
                                </StyledPointFootnoteContentItemDate>
                            )}
                            {direction === 'right' && (
                                <StyledPointFootnoteContentItemDot
                                    // style={{ background: 'green' }}
                                    view={p.type}
                                />
                            )}
                        </StyledPointFootnoteContentItem>
                    );
                })}
            </StyledPointFootnoteContent>
        </StyledPointFootnote>
    );
};
