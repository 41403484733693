import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const GeoIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.4448 14.8317C7.44506 14.8319 7.4453 14.8321 8 14L7.4448 14.8317C7.7807 15.0557 8.2188 15.056 8.5547 14.8321L8 14C8.5547 14.8321 8.55444 14.8322 8.5547 14.8321L8.55581 14.8313L8.55739 14.8303L8.5619 14.8272L8.57627 14.8175C8.5882 14.8094 8.60476 14.7981 8.62566 14.7836C8.66744 14.7546 8.72658 14.7131 8.80062 14.6596C8.94859 14.5526 9.15662 14.3975 9.40477 14.1996C9.89948 13.8051 10.5616 13.234 11.2272 12.5291C12.5186 11.1611 14 9.10327 14 6.74074C14 3.52071 11.2629 1 8 1C4.73713 1 2 3.52071 2 6.74074C2 9.10327 3.48143 11.1611 4.77285 12.5291C5.43836 13.234 6.10052 13.8051 6.59523 14.1996C6.84338 14.3975 7.05142 14.5526 7.19938 14.6596C7.27342 14.7131 7.33256 14.7546 7.37434 14.7836C7.39524 14.7981 7.4118 14.8094 7.42373 14.8175L7.4381 14.8272L7.44261 14.8303L7.4448 14.8317ZM4 6.74074C4 4.72429 5.74002 3 8 3C10.26 3 12 4.72429 12 6.74074C12 8.30414 10.9814 9.87591 9.77285 11.1561C9.18836 11.7752 8.60052 12.2829 8.15773 12.636C8.10269 12.6799 8.05001 12.7213 8 12.7602C7.94999 12.7213 7.89731 12.6799 7.84227 12.636C7.39948 12.2829 6.81164 11.7752 6.22715 11.1561C5.01857 9.87591 4 8.30414 4 6.74074Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M11.4182 21.8134C11.4182 21.8134 11.4188 21.8137 12 21L12.5812 21.8137C12.2335 22.0621 11.7659 22.0617 11.4182 21.8134Z"
                            fill={fill}
                        />
                        <path
                            d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.4182 21.8134L12 21C12.5812 21.8137 12.5824 21.8129 12.5824 21.8129L12.5843 21.8115L12.5899 21.8075L12.6086 21.794C12.6243 21.7826 12.6464 21.7663 12.6747 21.7454C12.7311 21.7035 12.8117 21.6428 12.913 21.5643C13.1156 21.4074 13.4017 21.179 13.7434 20.887C14.4253 20.3042 15.3364 19.4622 16.2503 18.425C18.0459 16.3871 20 13.4299 20 10.1111C20 5.64637 16.4331 2 12 2C7.56693 2 4 5.64637 4 10.1111C4 13.4299 5.95405 16.3871 7.7497 18.425C8.6636 19.4622 9.57465 20.3042 10.2566 20.887C10.5983 21.179 10.8844 21.4074 11.087 21.5643C11.1883 21.6428 11.2689 21.7035 11.3253 21.7454C11.3536 21.7663 11.3757 21.7826 11.3914 21.794L11.4101 21.8075L11.4157 21.8115L11.4182 21.8134ZM6 10.1111C6 6.72113 8.70108 4 12 4C15.2989 4 18 6.72113 18 10.1111C18 12.6812 16.4541 15.1685 14.7497 17.1028C13.9136 18.0517 13.0747 18.8277 12.4441 19.3665C12.2802 19.5065 12.1309 19.6301 12 19.7361C11.8691 19.6301 11.7198 19.5065 11.5559 19.3665C10.9253 18.8277 10.0864 18.0517 9.2503 17.1028C7.54595 15.1685 6 12.6812 6 10.1111Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
