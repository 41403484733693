import {
  StyledWrapper,
  StyledTop,
  StyledBottom,
  StyledItem,
  StyledHeadline,
  StyledText,
  StyledStatusWrapper,
  StyledStatusCircle,
  StyledTopLine,
  StyledBottomLine,
} from "../styled";

import {
  statusCardMessage,
  statusCardText,
  statusCardColor,
} from "../../../../constants";

export const StatusHint = () => {
  return (
    <StyledItem width={305}>
      <StyledHeadline>A launch has four statuses:</StyledHeadline>
      <StyledStatusWrapper>
        <StyledStatusCircle color={statusCardColor.DEPLOY_VM} />
        <StyledText>
          {statusCardText.DEPLOY_VM + " – " + statusCardMessage.DEPLOY_VM}
        </StyledText>
      </StyledStatusWrapper>
      <StyledStatusWrapper>
        <StyledStatusCircle color={statusCardColor["SET_UP VM"]} />
        <StyledText>
          {statusCardText["SET_UP VM"] + " – " + statusCardMessage["SET_UP VM"]}
        </StyledText>
      </StyledStatusWrapper>
      <StyledStatusWrapper>
        <StyledStatusCircle color={statusCardColor.OPERATIONAL} />
        <StyledText>
          {statusCardText.OPERATIONAL + " – " + statusCardMessage.OPERATIONAL}
        </StyledText>
      </StyledStatusWrapper>
      <StyledStatusWrapper>
        <StyledStatusCircle color={statusCardColor.DEACTIVATED} />
        <StyledText>
          {statusCardText.DEACTIVATED + " – " + statusCardMessage.DEACTIVATED}
        </StyledText>
      </StyledStatusWrapper>
    </StyledItem>
  );
};
