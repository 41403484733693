import {
    StyledWrapper,
    StyledTop,
    StyledText,
    StyledHeadline,
    StyledTopLine,
    StyledBottom,
    StyledSection,
    StyledImgWrapper,
    StyledBottomLine,
} from '../styled';
import image_1 from './img/image_1.png';
import image_2 from './img/image_2.png';
import image_3 from './img/image_3.png';
import image_4 from './img/image_4.png';
import image_5 from './img/image_5.png';
import image_6 from './img/image_6.png';
import image_7 from './img/image_7.png';
import image_8 from './img/image_8.png';
import image_9 from './img/image_9.png';
import image_10 from './img/image_10.png';
import image_11 from './img/image_11.png';
import image_12 from './img/image_12.png';
import image_13 from './img/image_13.png';

export const HowToMakeFirstDetect = () => {
    return (
        <StyledBottom style={{ paddingTop: 0 }}>
            <StyledSection>
                <StyledHeadline>Preparations</StyledHeadline>
                <StyledText>
                    If you set everything correct, you should see 1 host under
                    <br />
                    Security &rarr; Explore &rarr; Hosts section:
                </StyledText>
                <img
                    src={image_1}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    You can also use the Discover section to see raw events:
                </StyledText>
                <img
                    src={image_2}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    If you encounter the error below, just clear your browser's
                    cache
                </StyledText>
                <img
                    src={image_3}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
            </StyledSection>
            <StyledSection>
                <StyledHeadline>Attack</StyledHeadline>
                <StyledText>
                    Now you took a look into events, let's hack our stand. Click
                    on the "Attack button". The zero difficulty attack will
                    upload shell.py file and will run that file to deploy a
                    reverse shell and disable the service
                </StyledText>
                <img
                    src={image_4}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    5 minutes after let's take a look at the events, filtering
                    out all proccesses - seems that somebody ran shell.py and
                    stopped the service!
                </StyledText>
                <img
                    src={image_5}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
            </StyledSection>
            <StyledSection>
                <StyledHeadline>Detection</StyledHeadline>
                <StyledText>
                    Let's write a rule to detect such attack. Go to Security
                    &rarr; Manage &rarr; Rules and create a rule
                </StyledText>
                <img
                    src={image_6}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>Choose Custom query:</StyledText>
                <img
                    src={image_7}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    Write a query to detect our attack - event.category :
                    "process" and process.name : "python3" and process.args:
                    *shell*
                </StyledText>
                <img
                    src={image_8}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>Describe the rule</StyledText>
                <img
                    src={image_9}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    Choose how often it will be running queries::
                </StyledText>
                <img
                    src={image_10}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    To send detects select webhook as rule action:
                </StyledText>
                <img
                    src={image_11}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    And configure webhook according to your lab card, we don't
                    care of request body, you can write anything there
                </StyledText>
                <img
                    src={image_12}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
                <StyledText>
                    When you finished, you should see new alerts in the Security
                    &rarr; Alerts section:
                </StyledText>
                <img
                    src={image_13}
                    alt="kjbjhb "
                    style={{ width: '100%', borderRadius: '8px' }}
                />
            </StyledSection>
        </StyledBottom>
    );
};
