import { FC, useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import { ITimeLineInnerElements, TimeLineProps } from "./types";
import { createTimePoint, getReduceTimepointsWithAttack, getAllMinutes, getPosition, getDateFormat } from "./utils";
import { TIMELINE_ELEMENT } from "./constants";
import {
  StyledTimeLineContainer,
  StyledTimeLine,
  StyledTimeLneLine,
  StyledNoData,
  StyledDateNowContainer,
  StyledDateNowLine
} from "./styled";
import { TimePoint } from './components/TimePoint/TimePoint'
import { STATUS_CARD } from "../../constants"

dayjs.extend(utc)

export const TimeLine: FC<TimeLineProps> = ({ elements, isHover, status }) => {
  const refTM = useRef<HTMLDivElement>(null);
  const [timeLineWidth, setTimeLineWidth] = useState(0);
  const [nowPosition, setNowPosition] = useState(-20);

  
  useEffect(() => {
    const wait = {
      getTimePointNow: () => setInterval(() => {
        setNowPosition(getPosition(
          dayjs().toISOString(),
          elements[0].date,
          
          getAllMinutes(
            dayjs
            .utc(elements[0].date)
            .format("MMMM DD YYYY HH:mm:ss")
            .toString(),
            dayjs
            .utc(elements[elements.length - 1].date)
            .format("MMMM DD YYYY HH:mm:ss")
            .toString()
           )))
      }, 1000)
    }  
    if(status === STATUS_CARD.OPERATIONAL && elements.length && timeLineWidth > 0){
      wait.getTimePointNow();
    } else {
      clearInterval(wait.getTimePointNow());
    }
    return () => {
      clearInterval(wait.getTimePointNow());
    }
  }, [status, elements, timeLineWidth])

  const resizeWindowHandler = () => {
    if (refTM && refTM.current?.offsetWidth)
      setTimeLineWidth(refTM.current?.offsetWidth);
  };

  useEffect(() => {
    resizeWindowHandler();
    window.addEventListener("resize", resizeWindowHandler);
    return () => {
      window.removeEventListener("resize", resizeWindowHandler);
    };
  }, []);

  const timePoints: ITimeLineInnerElements[] = useMemo(() => {
    let reduceResults: ITimeLineInnerElements[] = [];
    if (elements.length > 1) {
      let start: string = dayjs
        .utc(elements[0].date)
        .format("MMMM DD YYYY HH:mm:ss")
        .toString();

      let end: string = dayjs
        .utc(elements[elements.length - 1].date)
        .format("MMMM DD YYYY HH:mm:ss")
        .toString();

      let allMinutes = getAllMinutes(start, end);

      let tmpElements = [...elements];
      reduceResults = tmpElements
        .reduce((acc: ITimeLineInnerElements[], curr, idx, arr) => {
          if (idx === 0) {
            acc.push(
              createTimePoint({
                inputElem: curr,
                start,
                allMinutes,
                timeLineWidth,
              })
            );
          } else {
            let tmpPoint = createTimePoint({
              inputElem: curr,
              start,
              allMinutes,
              timeLineWidth,
            });
            if (tmpPoint.leftPixels - acc[acc.length - 1].leftPixels < 40) {
              acc[acc.length - 1].timepoints.push(tmpPoint);
              acc[acc.length - 1].wordBook.push(tmpPoint.type);
            } else {
              acc.push(
                createTimePoint({
                  inputElem: curr,
                  start,
                  allMinutes,
                  timeLineWidth,
                })
              );
            }
          }
          return acc;
        }, [])
        .map((point) => {
          if (point.timepoints.length) {
            point.timepoints = [
              { ...point, timepoints: [], wordBook: [] },
              ...point.timepoints,
            ];
            point.wordBook = [point.type, ...point.wordBook];
            point.timepointsReduced = getReduceTimepointsWithAttack(point.timepoints);
            if  (
                  point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) && 
                  point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                ) {
                    point.type = TIMELINE_ELEMENT.GROUP_DETECTED_WITH_ATTACK;
                  }
            if  (
                  point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) && 
                  !point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                ) {
                    point.type = TIMELINE_ELEMENT.GROUP_ATTACK;
                  }
            if  (
                  !point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) && 
                  point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                ) {
                    point.type = TIMELINE_ELEMENT.GROUP_DETECTED;
                  }
          }
          return point;
        });
    }
    return reduceResults;
  }, [elements, timeLineWidth]);

  const heightsPoints: number[] = useMemo(() => {
    let heights: number[] = [];
    timePoints.forEach((point, idx) => {
      if (!heights.length) {
        if (point.timepointsReduced.length) {
          heights.push(point.timepointsReduced.length);
        } else {
          heights.push(1);
        }
      } else {
        if (point.timepointsReduced.length) {
          heights.push(
            heights[heights.length - 1] + point.timepointsReduced.length
          );
        } else {
          heights.push(heights[heights.length - 1] + 1);
        }
      }
    });

    return heights;
  }, [timePoints]);

  return (
    <StyledTimeLineContainer>
      <StyledTimeLine
        ref={refTM}
        height={
          Number.isNaN(heightsPoints[heightsPoints.length - 1] * 20)
            ? 0
            : heightsPoints[heightsPoints.length - 1] * 20
        }
      >
       {
        status === STATUS_CARD.OPERATIONAL && (
          <StyledDateNowContainer left={nowPosition}>
            <span>Now {getDateFormat(dayjs().utc().toString())}</span>
            <StyledDateNowLine />
          </StyledDateNowContainer>
        )
       }
        {timePoints.length ? (
          timePoints.map((point, idx) => {
            return (
              <TimePoint
                point={point}
                heightsPoints={heightsPoints}
                idx={idx}
                lastIdx={timePoints.length - 1}
                key={point.id}
              />
            );
          })
        ) : (
          <StyledNoData hover={isHover}>
            <p>No detects and attack</p>
          </StyledNoData>
        )}
        <StyledTimeLneLine />
      </StyledTimeLine>
    </StyledTimeLineContainer>
  );
};
