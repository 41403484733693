import { AddIcon, MinusIcon, IconButton } from '@/components';
import {
    StyledAccordion,
    StyledItem,
    StyleHeader,
    StyledTitleContainer,
    StyledTitle,
} from './styled';
import { IProps } from './types';

export const Accordion = ({
    openedItems = [],
    list = [],
    openItem,
    closeItem,
}: IProps) => {
    return (
        <StyledAccordion>
            {list.map((i) => (
                <StyledItem key={i.name}>
                    <StyleHeader>
                        <StyledTitleContainer>
                            {i.icon}
                            <StyledTitle>{i.title}</StyledTitle>
                        </StyledTitleContainer>
                        <IconButton
                            onClick={() => {
                                if (!openedItems.includes(i.name)) {
                                    if (openItem) openItem(i.name);
                                } else {
                                    if (closeItem) closeItem(i.name);
                                }
                            }}
                            icon={
                                openedItems.includes(i.name) ? (
                                    <MinusIcon />
                                ) : (
                                    <AddIcon />
                                )
                            }
                        />
                    </StyleHeader>
                    {openedItems.includes(i.name) && i.content}
                </StyledItem>
            ))}
        </StyledAccordion>
    );
};
