import { IProps } from './types';
import {
    StyledTextItem,
    StyledKey,
    StyledContainerValue,
    StyledValue,
    StyledLink,
    StyledContainerIcon,
} from '../styled';

export const TextItem = (props: IProps) => {
    const {
        keyText,
        valueText,
        widthKey = 102,
        cut,
        icon,
        link,
        direction = 'row',
        size = 'M',
        keySize,
        valueSize,
    } = props;
    return (
        <StyledTextItem direction={direction} size={size}>
            {keyText && (
                <StyledKey
                    width={widthKey}
                    keySize={keySize}
                    cut={cut}
                    size={size}
                >
                    {keyText}
                </StyledKey>
            )}
            <StyledContainerValue>
                {icon && (
                    <StyledContainerIcon valueSize={valueSize} size={size}>
                        {icon}
                    </StyledContainerIcon>
                )}
                {link ? (
                    <StyledLink
                        href={link}
                        cut={cut}
                        valueSize={valueSize}
                        size={size}
                        target="_blank"
                    >
                        {valueText ?? '–'}
                    </StyledLink>
                ) : (
                    <StyledValue valueSize={valueSize} size={size} cut={cut}>
                        {valueText ?? '–'}
                    </StyledValue>
                )}
            </StyledContainerValue>
        </StyledTextItem>
    );
};
