import { SHAPE } from "../../../../components/Button/types";
import {
  StyledBckg,
  StyledHint,
  StyledArrow,
  StyledTextWrapper,
  StyledTitle,
  StyledText,
  StyledTerminalButtons,
  StyledButtonsWrapper,
} from "./styled";
import { Button } from "../../../../components/Button/Button";
import { OnboardingContextType } from "../../types";
import { totalSteps } from "../../OnboardingProvider";

type HintType = Omit<OnboardingContextType, "start" | "isShow">;

export const Hint = (props: HintType) => {
  const { step, next, end, prev, numberOfSteps } = props;
  return (
    <StyledBckg>
      <StyledHint position={step.position}>
        <StyledArrow align={step.align} />
        <StyledTextWrapper>
          <StyledTitle>
            {step.step + "/" + totalSteps + ". " + step.title}
          </StyledTitle>
          <StyledText>{step.text}</StyledText>
        </StyledTextWrapper>
        <StyledTerminalButtons>
          <StyledButtonsWrapper>
            <Button
              view={SHAPE.GHOST}
              onClick={prev}
              disabled={step.step === 1}
            >
              prev
            </Button>
            <Button
              view={SHAPE.SECONDARY}
              onClick={next}
              disabled={step.step === numberOfSteps - 1}
            >
              next
            </Button>
          </StyledButtonsWrapper>
          <Button view={SHAPE.GHOST} onClick={end}>
            close
          </Button>
        </StyledTerminalButtons>
      </StyledHint>
    </StyledBckg>
  );
};
