import { FC, useState } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { ActionBarProps } from './types';
import { IconButton } from '../../../IconButton/IconButton';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { StyledActionBar, StyledInfoButton } from './styled';
import {
    IconInfo,
    IconStop,
    IconPlay,
    IconMore,
    // IconRefresh,
} from '../../../Icons/index';
import { STATUS_CARD, WIDTH_CARD } from '../../constants';
import { buttonsLabels, InfoMenu } from './constants';
import { useOnboardingHint } from '../../hooks/OnboardingHint/useOnboardingHint';
import { DropdownMenu } from '../../../Dropdown/DropdownMenu/DropdownMenu';
import { TooltipStatic } from '../../../TooltipStatic/TooltipStatic';
import { SHAPE } from '../../../Button/types';

export const ActionBar: FC<ActionBarProps> = (props) => {
    const {
        isHover,
        status,
        attack_button_pressed,
        callBack,
        widthBar,
        isStatically,
        onboardingConfig,
        scrolled,
    } = props;
    const { cards } = useAppSelector((state) => state.launchCardReducer);
    const [isShowTooltipStatic, setIsShowTooltipStatic] =
        useState<boolean>(true);

    const isRenderTooltipStatic = isShowTooltipStatic && cards.length === 1;

    const InfoButon = (
        <Tooltip text="Explain the card">
            <DropdownMenu
                list={InfoMenu}
                onClick={(numberOfStep) =>
                    typeof numberOfStep === 'number' &&
                    callBack.info(numberOfStep)
                }
            >
                <StyledInfoButton>
                    <span>Info</span>
                    <IconInfo bckgFill="#817F89" />
                </StyledInfoButton>
            </DropdownMenu>
        </Tooltip>
    );

    const { hint } = useOnboardingHint({
        isShow: isStatically,
        config: onboardingConfig,
    });

    const PreparationsButtons: FC = () => {
        return <>{InfoButon}</>;
    };

    const OpenedStartButtons: FC = () => {
        return (
            <>
                {InfoButon}
                <Tooltip text="Destroy this lab">
                    <div style={{ position: 'relative' }}>
                        {hint('DESTROY')}
                        <IconButton
                            label={buttonsLabels.destroy[widthBar]}
                            onClick={callBack.destroy}
                            icon={<IconStop />}
                        />
                    </div>
                </Tooltip>
                <Tooltip text="Attack the server">
                    <div style={{ position: 'relative' }}>
                        {hint('ATTACK')}
                        <IconButton
                            disabled={attack_button_pressed ? true : false}
                            label={buttonsLabels.attack[widthBar]}
                            onClick={callBack.attack}
                            icon={<IconPlay />}
                        />
                    </div>
                </Tooltip>
            </>
        );
    };

    const StartButtonsIsNarrow: FC = () => {
        return (
            <>
                {!isHover ? (
                    <div style={{ position: 'relative' }}>
                        {hint('MORE')}
                        <IconMore />
                    </div>
                ) : (
                    <>
                        {isStatically ? (
                            <div style={{ position: 'relative' }}>
                                {hint('MORE')}
                                <IconMore />
                            </div>
                        ) : (
                            <OpenedStartButtons />
                        )}
                    </>
                )}
            </>
        );
    };

    const StartButtons: FC = () => {
        return (
            <>
                {(widthBar === WIDTH_CARD.WIDE ||
                    widthBar === WIDTH_CARD.MEDIUM) && <OpenedStartButtons />}
                {widthBar === WIDTH_CARD.NARROW && <StartButtonsIsNarrow />}
            </>
        );
    };

    const DestroyedLabButtons: FC = () => {
        return <>{!isHover ? <IconMore /> : <>{InfoButon}</>}</>;
    };

    return (
        <>
            {isRenderTooltipStatic ? (
                <TooltipStatic
                    icon={<IconInfo size={32} />}
                    isShow={isShowTooltipStatic}
                    text="The server should be connected to you now. Press the button below to see step-by-step guide on how to solve your first challenge."
                    title="You set up your first lab, what's next?"
                    onClose={() => setIsShowTooltipStatic(false)}
                    buttons={[
                        {
                            id: 0,
                            title: 'SHOW',
                            view: SHAPE.PRIMARY,
                            action: () => callBack.info(6),
                        },
                    ]}
                >
                    <StyledActionBar isHover={isHover} scrolled={scrolled}>
                        {status === STATUS_CARD.DEPLOY ||
                        status === STATUS_CARD.SET_UP_VM ||
                        status === STATUS_CARD.TEAR_DOWN ? (
                            <PreparationsButtons />
                        ) : null}
                        {status === STATUS_CARD.OPERATIONAL && <StartButtons />}
                        {status === STATUS_CARD.DEACTIVATED && (
                            <DestroyedLabButtons />
                        )}
                    </StyledActionBar>
                </TooltipStatic>
            ) : (
                <StyledActionBar isHover={isHover} scrolled={scrolled}>
                    {status === STATUS_CARD.DEPLOY ||
                    status === STATUS_CARD.SET_UP_VM ||
                    status === STATUS_CARD.TEAR_DOWN ? (
                        <PreparationsButtons />
                    ) : null}
                    {status === STATUS_CARD.OPERATIONAL && <StartButtons />}
                    {status === STATUS_CARD.DEACTIVATED && (
                        <DestroyedLabButtons />
                    )}
                </StyledActionBar>
            )}
        </>
    );
};
