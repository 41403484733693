import { apiClient } from '@/api/apiClient';
import { Alert, Button, Input } from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { FormEventHandler, useState } from 'react';

export const ChangePasswordForm = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState('');
    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setError('');
        if (!oldPassword) {
            setError('Old password id required');
            return;
        }
        if (!newPassword) {
            setError('New password id required');
            return;
        }
        if (newPassword !== repeatPassword) {
            setError('The repeated password does not match the new password');
            return;
        }
        try {
            await apiClient.post('api/v1/userinfo/change_password/', {
                old_password: oldPassword,
                new_password: newPassword,
            });
        } catch (error: any) {
            if (error.response && error.response.data) {
                setError(error.response.data.status);
            }
        }
    };
    return (
        <form
            className="passwordForm"
            onSubmit={onSubmit}
            // style={{ display: 'flex', gap: 8, flexDirection: 'column' }}
        >
            <Input
                label="Old password"
                value={oldPassword}
                onChange={setOldPassword}
                // placeholder="Old password"
                type="password"
            ></Input>
            <Input
                label="New password"
                value={newPassword}
                onChange={setNewPassword}
                // placeholder="New password"
                type="password"
            ></Input>
            <Input
                label="Repeat password"
                value={repeatPassword}
                onChange={setRepeatPassword}
                // placeholder="Repeat password"
                type="password"
            ></Input>
            <Button>Save</Button>
            {error && <Alert variant={VARIANT.ERROR} message={error} />}
        </form>
    );
};
