import { baseColors } from '../../constants/main';

export enum STATUS_CARD {
    DEPLOY = 'DEPLOY_VM',
    SET_UP_VM = 'SET_UP VM',
    OPERATIONAL = 'OPERATIONAL',
    DEACTIVATED = 'DEACTIVATED',
    TEAR_DOWN = 'TEAR_DOWN',
}

export const statusCardColor = {
    DEPLOY_VM: baseColors.warning(),
    'SET_UP VM': baseColors.critical(),
    OPERATIONAL: baseColors.primary(),
    DEACTIVATED: baseColors.disabled(),
    TEAR_DOWN: baseColors.critical(),
};

export const statusCardText = {
    DEPLOY_VM: 'Deploy VM',
    'SET_UP VM': 'Set up VM',
    OPERATIONAL: 'Operation',
    DEACTIVATED: 'Deactivated',
    TEAR_DOWN: 'Destruction VM',
};

export const statusCardMessage = {
    DEPLOY_VM: 'We are preparing virtual machines',
    'SET_UP VM': 'We install software on the machines',
    OPERATIONAL: 'Operational',
    DEACTIVATED: 'Laboratory destroyed',
    TEAR_DOWN: 'Destruction VM',
};

export enum CARD_FIELDS {
    STATUS = 'STATUS',
    LAB_NAME = 'LAB_NAME',
    PLANED_DESTROY_DATA = 'PLANED_DESTROY_DATA',
    TIME_TO_DESTROY = 'TIME_TO_DESTROY',
    POINTS = 'POINTS',
    DIFFICULTY = 'DIFFICULTY',
}

export enum ACTION_BAR_BUTTONS {
    ATTACK = 'ATTACK',
    DESTROY = 'DESTROY',
    INFO = 'INFO',
}

export enum CARD_FOOTER_FIELDS {
    SYSTEM_LINK = 'SYSTEM_LINK',
    POST_REQUEST_LINK = 'POST_REQUEST_LINK',
}

export enum WIDTH_CARD {
    WIDE = 'wide',
    MEDIUM = 'medium',
    NARROW = 'narrow',
}
