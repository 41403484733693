import { ITimeLineElements } from './components/TimeLineOne/types';
import { TIMELINE_ELEMENT } from './components/TimeLineOne/constants';
import { baseColors } from '../../constants/main';

export const getCountersForTimeLineHeader = (elems: ITimeLineElements[]) => {
    const counters = {
        detect: {
            amount: 0,
            color: baseColors.warning(),
        },
        attack: {
            amount: 0,
            color: baseColors.error(),
        },
    };
    elems.forEach((el) => {
        if (el.type === TIMELINE_ELEMENT.DETECTED) {
            counters.detect.amount += 1;
        }
        if (el.type === TIMELINE_ELEMENT.ATTACK) {
            counters.attack.amount += 1;
        }
    });
    return counters;
};
