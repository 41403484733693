import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const ArrowDownIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8.12635 14.9921C8.04244 15.0027 7.95745 15.0027 7.87355 14.9921C7.81089 14.9842 7.75003 14.9705 7.69154 14.9515C7.54576 14.9044 7.40867 14.8229 7.29289 14.7072L4.29289 11.7072C3.90237 11.3166 3.90237 10.6835 4.29289 10.293C4.68342 9.90243 5.31658 9.90243 5.70711 10.293L7 11.5858L7 2C7 1.44771 7.44772 1 8 1C8.55229 1 9 1.44771 9 2L9 11.5858L10.2929 10.293C10.6834 9.90243 11.3166 9.90243 11.7071 10.293C12.0976 10.6835 12.0976 11.3166 11.7071 11.7072L8.70711 14.7072C8.59133 14.8229 8.45424 14.9044 8.30846 14.9515C8.24993 14.9705 8.18904 14.9842 8.12635 14.9921Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M13 18.5858L16.2929 15.293C16.6834 14.9024 17.3166 14.9024 17.7071 15.293C18.0976 15.6835 18.0976 16.3166 17.7071 16.7072L12.7071 21.7072C12.3166 22.0977 11.6834 22.0977 11.2929 21.7072L6.29289 16.7072C5.90237 16.3166 5.90237 15.6835 6.29289 15.293C6.68342 14.9024 7.31658 14.9024 7.70711 15.293L11 18.5858L11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3L13 18.5858Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
