import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledHeader = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.span`
    ${typography.text.L};
`;

export const StyledSubtitle = styled.span`
    ${typography.text.S};
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledLabel = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`;

export const StyledText = styled.p`
    ${typography.text.L};
    max-width: 600px;
`;

export const StyledPointsContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledPoints = styled.span`
    ${typography.title.XXXL};
`;

export const StyledPointsDescription = styled.span`
    ${typography.text.S};
`;

export const StyledIconContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledBlockContainer = styled.div`
    box-sizing: border-box;
    padding: 48px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledSeparator = styled.div`
    height: 1px;
    width: 100%;
    background: ${baseColors.white(0.1)};
`;

export const StyledButtons = styled.div`
    display: flex;
    gap: 8px;
`;
