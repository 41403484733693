import { StyledIconButton, StyledLabel } from './styled';
import { IProps } from './types';

export const IconButton = (props: IProps) => {
    const {
        icon,
        disabled = false,
        label,
        size = 'S',
        iconLeft,
        stylePropContainer,
        stylePropLabel,
        onClick,
        onMouseEnter,
        onMouseLeave,
    } = props;

    return (
        <StyledIconButton
            size={size}
            onClick={onClick && ((e) => onClick(e))}
            onMouseEnter={onMouseEnter && ((e) => onMouseEnter(e))}
            onMouseLeave={onMouseLeave && ((e) => onMouseLeave(e))}
            disabled={disabled}
            $CSSProp={stylePropContainer}
        >
            {iconLeft}
            {label && (
                <StyledLabel size={size} $CSSProp={stylePropLabel}>
                    {label}
                </StyledLabel>
            )}
            {icon}
        </StyledIconButton>
    );
};
