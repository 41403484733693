import { useState, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { STATUS_LAB } from '@/constants';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { TaskCard } from './components/TaskCard/TaskCard';
import { NoData, Select, TerminalIcon, Button, Alert } from '@/components';
import { SideBar, SideBarContext } from '@/components/SideBar';
import { ISideCell } from '@/components/SideBar/types';
import { StyledPage, StyledContainer, StyledBodyText } from '../../styled';
import { VMTerminal } from '../../components/VMTerminal';
import { SubscribeBlock } from '../../components/SubscribeBlock/SubscribeBlock';
import { checkTasks } from '@/store/reducers/TaskSlice';

interface IVmOption extends IDropdownMenuItem {
    vmId: string;
}

export const Tasks = memo(() => {
    const dispatch = useAppDispatch();
    const { uuid } = useParams();
    const { tasks, checkTaskState } = useAppSelector(
        (state) => state.TaskSlice
    );

    const { error: checkError } = checkTaskState;

    const { currentCard } = useAppSelector((state) => state.launchCardReducer);
    const { profile, isLoadingProfile } = useAppSelector(
        (state) => state.ProfileSlice
    );
    const [currentVm, setCurrenthVm] = useState<IVmOption>();

    const vmOptions: IVmOption[] = useMemo(() => {
        if (tasks) {
            return tasks.map(({ name, target_machine, text, id }) => ({
                title: name,
                id,
                description: text,
                vmId: target_machine,
            }));
        }
        return [];
    }, [tasks]);

    const isDisabled = currentCard?.status !== STATUS_LAB.OPERATIONAL;
    const isNoData = !tasks || !tasks.length;
    const [openedCellId, setOpenCellId] = useState<ISideCell['id'] | undefined>(
        undefined
    );

    const isSidebar =
        currentCard &&
        currentCard.status === STATUS_LAB.OPERATIONAL &&
        !isLoadingProfile;

    return (
        <SideBarContext.Provider
            value={{
                cells: [
                    {
                        id: `terminal`,
                        title: 'SSH terminal',
                        width: 'calc(100vw - 240px)',
                        children: (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '32px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <StyledBodyText>
                                    {currentVm?.description}
                                </StyledBodyText>
                                {profile?.is_premium ? (
                                    <>
                                        <Select
                                            options={vmOptions}
                                            onChange={(item) => {
                                                setCurrenthVm(
                                                    item as IVmOption
                                                );
                                            }}
                                            label="Select task"
                                            defaultValue={currentVm?.id}
                                        />
                                        {currentVm && (
                                            <VMTerminal
                                                uuid={String(currentVm?.vmId)}
                                            />
                                        )}
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                if (uuid && currentVm) {
                                                    dispatch(
                                                        checkTasks({
                                                            uuid,
                                                            cardId: Number(
                                                                currentVm.id
                                                            ),
                                                        })
                                                    );
                                                }
                                                setCurrenthVm(undefined);
                                                setOpenCellId(undefined);
                                            }}
                                        >
                                            CHECK TASK
                                        </Button>
                                    </>
                                ) : (
                                    <SubscribeBlock>
                                        SSH access to all VMs
                                    </SubscribeBlock>
                                )}
                            </div>
                        ),
                        icon: <TerminalIcon />,
                    },
                ],
                openedCellId,
                setOpenCellId(cellId) {
                    if (typeof cellId === 'undefined') {
                        setCurrenthVm(undefined);
                    }
                    setOpenCellId(cellId);
                },
            }}
        >
            <StyledContainer style={{ paddingRight: isSidebar ? '52px' : '0' }}>
                <StyledPage>
                    {checkError.code && (
                        <Alert
                            code={checkError.code}
                            message={checkError.message}
                        />
                    )}
                    {tasks?.map((t) => {
                        return (
                            <TaskCard
                                {...t}
                                disabled={isDisabled}
                                key={t.id}
                                openTerminal={() => {
                                    setCurrenthVm({
                                        id: t.id,
                                        title: t.name,
                                        description: t.text,
                                        vmId: t.target_machine,
                                    });
                                    setOpenCellId('terminal');
                                }}
                            />
                        );
                    })}
                    {isNoData && (
                        <NoData
                            title="No tasks"
                            text="But we will add them soon."
                        />
                    )}
                </StyledPage>
                {isSidebar && <SideBar />}
            </StyledContainer>
        </SideBarContext.Provider>
    );
});
