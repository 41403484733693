import { createContext } from 'react';
import { HIGHLIGHT_TYPE } from '@/constants/highlights';

interface IHighlightContext {
    currentType?: HIGHLIGHT_TYPE;
    setCurrentType?: (ct: HIGHLIGHT_TYPE | undefined) => void;
    isOpened: boolean;
    setIsOpened?: (isOp: boolean) => void;
}

export const HighlightContext = createContext({} as IHighlightContext);
