import { useContext } from 'react';
import { IconButton } from '../../../IconButton/IconButton';
import { IconLink } from '../../../Icons/Link/IconLink';
import { IconCopy } from '../../../Icons/Copy/IconCopy';
import { StyledMoreInformations } from './styled';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { ToastContext } from '../../../Toast/ToastContext';
import { KeyValue } from '../UI/KeyValue/KeyValue';
import { LabContext } from '@/context/labContext';

export const MoreInfo = ({ url, uuid }: { url: string; uuid: string }) => {
    const { setIsShow, setTitle } = useContext(ToastContext);
    const {labUrl} = useContext(LabContext);
    const clickOnCopyHandler = () => {
        const link = `https://defbox.io/api/v1/${labUrl}/${uuid}/detect/`;
        navigator.clipboard.writeText(link);
        setIsShow(true);
        setTitle('Link successfully copied to clipboard');
    };
    return (
        <StyledMoreInformations>
            <KeyValue
                keyText="The system is deployed and is working"
                valueText={url}
                icon={
                    <a href={url} rel="noreferrer" target="_blank">
                        <Tooltip text="Link">
                            <IconButton icon={<IconLink size={20} />} />
                        </Tooltip>
                    </a>
                }
            />

            <KeyValue
                keyText="To detect an compromisation you shall send POST request"
                valueText={`https://keeton.anotherdomain.xyz/api/v1/${labUrl}/${uuid}/detect/`}
                icon={
                    <Tooltip text="Copy link">
                        <IconButton
                            icon={<IconCopy size={20} />}
                            onClick={() => clickOnCopyHandler()}
                        />
                    </Tooltip>
                }
            />
        </StyledMoreInformations>
    );
};
