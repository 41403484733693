import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/AuthSlice';
import launchCardReducer from './reducers/LaunchCardsSlice';
import createLaunchReducer from './reducers/CreateLaunchSlice';
import deployElasticAndCreateSlice from './reducers/DeployElasticAndCreateSlice';
import errorReducer from './reducers/ErrorSlice';
import modalReducer from './reducers/ModalSlice';
import scoreboardSlice from './reducers/ScoreboardSlice';
import LabsSlice from './reducers/LabsSlice';
import SiemSlice from './reducers/SiemSlice';
import HighlightsSlice from './reducers/HighlightsSlice';
import HealthChecksSlice from './reducers/HealthChecksSlice';
import TimelineSllice from './reducers/TimelineSlice';
import AchivementsSlice from './reducers/AchievementsSlice';
import AttackSlice from './reducers/AttackSlice';
import VMSlice from './reducers/VMSlice';
import ProfileSlice from './reducers/ProfileSlice';
import LayoutSlice from './reducers/LayoutSlice';
import WorkshopsSlice from './reducers/WorkshopsSlice';
import TaskSlice from './reducers/TaskSlice';

const rootReducer = combineReducers({
    authReducer,
    launchCardReducer,
    createLaunchReducer,
    deployElasticAndCreateSlice,
    modalReducer,
    errorReducer,
    scoreboardSlice,
    LabsSlice,
    WorkshopsSlice,
    SiemSlice,
    HighlightsSlice,
    HealthChecksSlice,
    TimelineSllice,
    AchivementsSlice,
    AttackSlice,
    VMSlice,
    ProfileSlice,
    LayoutSlice,
    TaskSlice,
});

const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
