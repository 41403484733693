import { useAppSelector } from '../../../../../hooks/redux';
import { NewLaunchCard } from '../../../../../components/Card/Card';
import { LaunchCardProps } from '../../../../../components/Card/types';
import { STATUS_CARD } from '../../../../../components/Card/constants';
import scrinOne from './img/scrinOne.jpg';
import scrinTwo from './img/scrinTwo.jpeg';

import {
    StyledWrapper,
    StyledTop,
    StyledText,
    StyledHeadline,
    StyledTopLine,
    StyledBottom,
    StyledSection,
    StyledImgWrapper,
    StyledBottomLine,
} from '../styled';

export const CardFooter = () => {
    const { access, refresh } = useAppSelector((state) => state.authReducer);

    const cardConfig: LaunchCardProps = {
        status: STATUS_CARD.OPERATIONAL,
        deploy_date: '10.05.2023, 00:00',
        planned_destroy_date: '10.05.2024, 00:00',
        uuid: 'ajhsdhjcg767',
        points: 23,
        lab_url: 'https:example.xuy',
        // access,
        // refresh,
        isStaticallyOpen: 'opened',
        index: 0,
        delay: 0,
        name: 'Python RCE',
    };

    return (
        <StyledWrapper>
            <StyledTop>
                <StyledTopLine
                    style={{ height: 'calc(100% + 280px)', left: '16px' }}
                />
                <StyledHeadline>
                    These are your ELK stand credentials
                </StyledHeadline>
                <StyledText>
                    You can use username and password provided to access ELK
                    where you should see the logs. Don’t forget to add auditbeat
                    and filebeat indices in the discovery tab to see the logs
                </StyledText>
            </StyledTop>
            <StyledTop>
                <StyledTopLine />
                <StyledHeadline>
                    Our system is up and running, but it will be hacked.
                </StyledHeadline>
                <StyledText>
                    Your task is to inform us when the system has been
                    compromised. We recommend that you familiarize yourself with
                    the system by visiting it before the attack occurs. This
                    will allow you to better understand the environment and
                    increase your chances of successfully defending against the
                    attack.
                </StyledText>
            </StyledTop>
            <NewLaunchCard {...cardConfig} />
            <StyledBottom>
                <StyledBottomLine />
                <StyledSection>
                    <StyledHeadline>
                        Once the system has been hacked, please send us a POST
                        request to inform us of the breach.
                    </StyledHeadline>
                    <StyledText>
                        This process should be fully automated. We recommend
                        using an ELK-stack to facilitate this process, and you
                        can either create your own rules or utilize pre-existing
                        ones.
                    </StyledText>
                    <StyledText>
                        To send us POST requests automatically, we suggest
                        configuring rules actions here:
                    </StyledText>
                </StyledSection>
                <StyledSection>
                    <StyledText>Click on rule:</StyledText>
                    <img
                        src={scrinOne}
                        alt="kjbjhb "
                        style={{ width: '100%', borderRadius: '8px' }}
                    />
                </StyledSection>
                <StyledSection>
                    <StyledText>
                        Then click on the “edit rule settings”:
                    </StyledText>
                    <img
                        src={scrinTwo}
                        alt="kjbjhb "
                        style={{ width: '100%', borderRadius: '8px' }}
                    />
                </StyledSection>
                <StyledSection>
                    <StyledText>
                        And set up actions as you wish. Happy defending!:
                    </StyledText>
                </StyledSection>
            </StyledBottom>
        </StyledWrapper>
    );
};
