import styled from "styled-components";
import { typography } from "../../../../../constants/main";

export const StyledWrapper = styled.div``;

export const StyledTop = styled.div`
  padding: 16px 24px;
  padding-bottom: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  gap: 32px;
  position: relative;
`;

export const StyledBottom = styled.div`
  padding: 16px 24px;
  padding-top: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledStatusCircle = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border: 3px solid rgba(255, 255, 255, 0.22);
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 3px;
  position: relative;
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    display: block;
    background: ${({ color }) => color};
    position: absolute;
    border-radius: 12px;
  }
`;

export const StyledItem = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: ${({ width }) => width}px;
  position: relative;
`;

export const StyledTopLine = styled.div`
  width: 1px;
  height: calc(100% + 52px);
  background: rgba(255, 255, 255, 0.12);
  position: absolute;
  left: -14px;
  top: 8px;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    height: 1px;
    background: rgba(255, 255, 255, 0.12);
    top: 0;
    left: 0;
  }
  &::before {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    height: 1px;
    background: rgba(255, 255, 255, 0.12);
    top: 100%;
    left: 0;
  }
`;

export const StyledBottomLine = styled.div<{
  afterLeft: number;
  beforeLeft: number;
}>`
  width: 1px;
  height: calc(100% + 52px);
  background: rgba(255, 255, 255, 0.12);
  position: absolute;
  left: -14px;
  top: 8px;
  &::after {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    height: 1px;
    background: rgba(255, 255, 255, 0.12);
    top: 0;
    left: ${({ afterLeft }) => afterLeft}px;
  }
  &::before {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    height: 1px;
    background: rgba(255, 255, 255, 0.12);
    top: 100%;
    left: ${({ beforeLeft }) => beforeLeft}px;
  }
`;

export const StyledText = styled.p`
  ${typography.text.M}
`;

export const StyledHeadline = styled.span`
  ${typography.text.M};
  font-weight: 600;
`;
