import styled, { CSSProp } from 'styled-components';
import { scrollStyles, typography, baseColors } from '@/constants';

export const StyledContainer = styled.div`
    position: relative;
    padding-right: 52px;
    box-sizing: border-box;
    overflow: hidden;
`;

export const StyledPage = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    overflow-y: auto;
    ${scrollStyles};
    height: calc(100vh - 57px);
`;

export const StyledBlockContainer = styled.div<{
    styleProps?: CSSProp;
}>`
    box-sizing: border-box;
    padding: 48px;
    padding-top: 20px;
    display: grid;
    ${({ styleProps }) => styleProps ?? null};
`;

export const StyledSeparator = styled.div`
    height: 1px;
    width: 100%;
    background: ${baseColors.white(0.12)};
`;

export const StyledBlockFooter = styled.div`
    padding: 0 48px 48px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StyledBlockFooterContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
`;
export const StyledBodyText = styled.p`
    ${typography.text.L};
`;
