import { useState, useContext, useEffect } from 'react';
import axios, { isAxiosError } from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux';
import { fetchAuthFromInvite } from '../../../store/reducers/AuthSlice';
import { InvitePageContext } from './InvitePageContext';
import { IconLogo, IconChecked } from '../../../components/Icons/index';
import { IError } from '../../../types/error';
import { ToastContext } from '../../../components/Toast/ToastContext';
import { PagesTabs } from '../../../components/Tabs/PagesTabs/PagesTabs';
import { IData } from '../../../components/Tabs/PagesTabs/types';
import { AuthForm } from '../Forms/AuthForm/AuthForm';
import { InviteForm } from '../Forms/InviteForm/InviteForm';
import { apiClient } from '../../../api/apiClient';

import poligon from './img/poligon.svg';
import {
    StyledInvite,
    StyledContainer,
    StyledTicket,
    StyledTicketHeader,
    StyledKeyAndValue,
    StyledValue,
    StyledProffitList,
    StyledProffitItem,
    StyledSeparator,
    StyledSepatorItem,
    StyledSeparatorItemBig,
    StyledTicketFooter,
    StyledFactoid,
    StyledFactoidValue,
    StyledFactoidKey,
    StyledContainerForm,
    StyledPoligonContainer,
    StyledContainerFormLogoHeader,
    StyledPromoContainer,
    StyledPromoTitle,
    StyledPromoText,
    // StyledPropmo,
    StyledRightSide,
} from '../styled';
import { proffitList } from '../constants';
import { baseColors } from '../../../constants/main';

export const InvitePage = () => {
    const [currForm, setCurrForm] = useState<'invite' | 'login' | string>(
        'invite'
    );
    const { setIsShow: setToastIsShow, setTitle: setToastText } =
        useContext(ToastContext);

    const navigate = useNavigate();
    const goToPage = (page: string) => {
        navigate(page);
    };

    const dispatch = useAppDispatch();
    const [inviteError, setInviteError] = useState<IError>({
        code: undefined,
        message: undefined,
    });
    const [user, setUser] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [amountInvites, setAmountInvites] = useState<number>(0);
    const [name, setName] = useState<string>('–');
    const { id } = useParams();

    const goToMain = () => {
        navigate('/');
    };

    const logIn = (argUser: string, argPass: string) => {
        dispatch(
            fetchAuthFromInvite({ name: argUser, pass: argPass, goToMain })
        );
    };

    const inviteUser = async (
        argId: string,
        argUser: string,
        argPass: string
    ) => {
        setInviteError({
            code: undefined,
            message: undefined,
        });
        try {
            apiClient
                .post('/api/invite/create_user/', {
                    link: argId,
                    username: argUser,
                    password: argPass,
                })
                .then(() => {
                    setToastText(`User ${argUser} created`);
                    setToastIsShow(true);
                    checkInvite();
                });
        } catch (err) {
            if (isAxiosError(err)) {
                setInviteError({
                    code: err.response?.status,
                    message: err.response?.data.status,
                });
            }
        }
    };

    const tabs: IData[] = [
        {
            id: 'invite',
            value: 'Sign Up',
        },
        {
            id: 'login',
            value: 'Sign In',
        },
    ];

    const checkInvite = async () => {
        try {
            apiClient.get(`api/invite/check_link/${id}/`, {}).then((data) => {
                setAmountInvites(data.data.count);
                setName(data.data.name);
            });
        } catch (err) {
            goToPage('*');
        }
    };

    useEffect(() => {
        checkInvite();
    }, []);

    return (
        <StyledInvite>
            <StyledContainer>
                <StyledTicket>
                    <StyledTicketHeader>
                        <StyledKeyAndValue>
                            <StyledValue>{name}</StyledValue>
                        </StyledKeyAndValue>
                        <StyledProffitList>
                            {proffitList.map((p) => (
                                <StyledProffitItem key={p.key}>
                                    <IconChecked />
                                    <p>{p.value}</p>
                                </StyledProffitItem>
                            ))}
                        </StyledProffitList>
                    </StyledTicketHeader>
                    <StyledSeparator>
                        {[...Array(9)].map((_, idx, arr) => {
                            if (idx === 0) {
                                return (
                                    <StyledSeparatorItemBig
                                        key={idx}
                                        position="first"
                                    />
                                );
                            }
                            if (idx === arr.length - 1) {
                                return (
                                    <StyledSeparatorItemBig
                                        key={idx}
                                        position="last"
                                    />
                                );
                            }
                            return <StyledSepatorItem key={idx} />;
                        })}
                    </StyledSeparator>

                    <StyledTicketFooter>
                        <StyledFactoid>
                            <StyledFactoidValue>
                                {amountInvites}
                            </StyledFactoidValue>
                            <StyledFactoidKey>
                                invitations
                                <br />
                                left
                            </StyledFactoidKey>
                        </StyledFactoid>
                    </StyledTicketFooter>
                    <StyledPoligonContainer
                        bottom={-80}
                        left={-24}
                        style={{ zIndex: 0 }}
                        rotate={30}
                    >
                        <img src={poligon} alt="" />
                    </StyledPoligonContainer>
                </StyledTicket>
                {/*  */}
                <StyledRightSide>
                    <StyledContainerForm>
                        <StyledContainerFormLogoHeader onClick={goToMain}>
                            <IconLogo size={56} cursor="pointer" />
                            <span>DefenseBox</span>
                        </StyledContainerFormLogoHeader>
                        <PagesTabs
                            data={tabs}
                            onChange={(pg) =>
                                typeof pg === 'string' ? setCurrForm(pg) : null
                            }
                            style={{ gap: '16px' }}
                            titleStyle={{
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '22px',
                            }}
                            stylesConditions={{
                                normal: `padding-right: 16px; :nth-child(odd){border-right: 3px solid ${baseColors.primary()};}`,
                                active: `color: rgba(255, 255, 255, 0.64); padding-right: 16px; :nth-child(odd){border-right: 3px solid ${baseColors.primary()};}`,
                            }}
                        />
                        <InvitePageContext.Provider
                            value={{
                                user,
                                setUser,
                                pass,
                                setPass,
                                id,
                                amountInvites,
                                name,
                                inviteError,
                                inviteUser,
                                checkInvite,
                                logIn,
                            }}
                        >
                            {currForm === 'login' && <AuthForm />}
                            {currForm === 'invite' && <InviteForm />}
                        </InvitePageContext.Provider>
                        <StyledPromoContainer>
                            <StyledPromoTitle>
                                Experience real-Life Cyber Threats in a safe
                                Environment
                            </StyledPromoTitle>
                            <StyledPromoText>
                                Our platform offers an exersise where users will
                                have to defend against cyber attack and compete
                                among each other to see who is the best
                                cyber-defender
                            </StyledPromoText>
                            <StyledPromoText>
                                Our blue team competition service provides a
                                unique opportunity for users to practice their
                                defensive skills in a safe, controlled
                                environment.
                            </StyledPromoText>
                        </StyledPromoContainer>
                    </StyledContainerForm>
                    <StyledContainerFormLogoHeader onClick={goToMain}>
                        <IconLogo
                            color="rgba(255, 255, 255, 0.25)"
                            cursor="pointer"
                            figureFill="rgba(255, 255, 255, 0.64)"
                        />
                        <span
                            style={{
                                fontSize: '18px',
                                lineHeight: '20px',
                                opacity: 0.25,
                            }}
                        >
                            DefenseBox
                        </span>
                    </StyledContainerFormLogoHeader>
                </StyledRightSide>
            </StyledContainer>
        </StyledInvite>
    );
};
