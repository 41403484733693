import { Icon } from "../Icon";

export const IconMore = () => {
  return (
    <Icon
      content={
        <>
          <path
            d="M10 12C10 13.3807 8.88071 14.5 7.5 14.5C6.11929 14.5 5 13.3807 5 12C5 10.6193 6.11929 9.5 7.5 9.5C8.88071 9.5 10 10.6193 10 12Z"
            fill="white"
            fillOpacity="0.72"
          />
          <path
            d="M19 12C19 13.3807 17.8807 14.5 16.5 14.5C15.1193 14.5 14 13.3807 14 12C14 10.6193 15.1193 9.5 16.5 9.5C17.8807 9.5 19 10.6193 19 12Z"
            fill="white"
            fillOpacity="0.72"
          />
        </>
      }
    />
  );
};
