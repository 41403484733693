import { css } from 'styled-components';
import { StyledBlockContainer } from '../../styled';
import {
    StyledItem,
    StyledTextContainer,
    StyledTitle,
    StyledDescription,
    StyledIconContainer,
} from './styled';
import { IHealthChecks } from '@/types/healthChecks';
import { FailedIcon, InProgressIcon } from '@/components';
import { baseColors } from '@/constants';

interface IProps {
    items?: IHealthChecks[];
}

const containerStyle = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
`;

export const HelthChecks = ({ items }: IProps) => {
    return (
        <StyledBlockContainer styleProps={containerStyle}>
            {items?.map((i, idx) => (
                <StyledItem key={idx}>
                    <StyledIconContainer status={i.result}>
                        {i.result === 'OK' ? (
                            <InProgressIcon fill={baseColors.ok()} />
                        ) : (
                            <FailedIcon fill={baseColors.error()} />
                        )}
                    </StyledIconContainer>
                    <StyledTextContainer>
                        <StyledTitle>{i.name}</StyledTitle>
                        <StyledDescription>{i.description}</StyledDescription>
                    </StyledTextContainer>
                </StyledItem>
            ))}
        </StyledBlockContainer>
    );
};
