import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledTextContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 32px;
    padding-bottom: 56px;
`;

export const StyledTextBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledTitle = styled.p`
    ${typography.title.S};
`;

export const StyledText = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledLifeCycle = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledBottomContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const StyledItem = styled.div<{ active?: boolean }>`
    display: flex;
    gap: 8px;
    padding: 2px 6px;
    align-items: center;
    box-sizing: border-box;
    width: fit-content;
    color: ${({ active }) => (active ? baseColors.ok() : baseColors.white())};
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    > span {
        ${typography.text.M};
    }
`;

export const StyledArrowContainer = styled.div<{
    direction: 'row' | 'column';
    jContent: 'flex-end' | 'flex-start';
}>`
    width: ${({ direction }) => (direction === 'column' ? '10px' : '100%')};
    height: ${({ direction }) => (direction === 'column' ? '52px' : '10px')};
    display: flex;
    flex-direction: ${({ direction }) => direction};
    justify-content: ${({ jContent }) => jContent};
    opacity: 0.25;
    position: relative;
`;

export const StyledHorizontalLine = styled.div<{ direction: 'left' | 'right' }>`
    height: 1px;
    top: 4.5px;
    ${({ direction }) => direction}: 4px;
    width: calc(100% - 4px);
    background: ${baseColors.white()};
    position: absolute;
`;

export const StyledVerticalLine = styled.div<{ direction: 'top' | 'bottom' }>`
    width: 1px;
    height: calc(100% - 4px);
    left: 4.5px;
    ${({ direction }) => direction}: 4px;
    background: ${baseColors.white()};
    position: absolute;
`;
