import styled from 'styled-components';
import { baseColors, typography, scrollStyles } from '@/constants';

export const StyledScoreTable = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 4px 0 8px;
    height: calc(100vh - 49px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    ${scrollStyles};
`;

export const StyledRow = styled.div<{ active?: boolean }>`
    display: flex;
    gap: 24px;
    padding: 8px 16px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid
        ${({ active }) =>
            active ? baseColors.white(0) : baseColors.white(0.1)};
    background: ${({ active }) =>
        active ? baseColors.primary() : baseColors.levelTwo()};
    &:hover {
        background: ${({ active }) =>
            active ? baseColors.primary() : baseColors.levelThree()};
    }
`;

export const StyledRowHeader = styled.div`
    display: flex;
    gap: 24px;
    padding: 12px 4px;
    box-sizing: border-box;
    align-items: center;
    background: ${baseColors.levelOne()};
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
`;

export const StyledCell = styled.div<{
    width: string;
    textAlign?: 'left' | 'right';
}>`
    width: ${({ width }) => width};
    max-width: ${({ width }) => width};
    min-width: ${({ width }) => width};
    > p {
        ${typography.title.M};
        text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    }
`;

export const StyledCellHeader = styled(StyledCell)`
    > p {
        ${typography.text.S};
        opacity: 0.5;
        text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    }
`;

export const StyledAchiveCell = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    > p {
        ${typography.title.M};
        opacity: 0.25;
    }
`;

export const StyledAchieveCounter = styled.span`
    ${typography.title.S};
    opacity: 0.5;
`;

export const StyledScoreDetail = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 24px;
    overflow-y: auto;
    overflow-x: hidden;
    ${scrollStyles};
`;

export const StyledScoreDetailSection = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledScoreDetailGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 24px;
`;

export const StyledScoreDetailTitle = styled.p`
    ${typography.title.XXXL};
`;

export const StyledStatisticItem = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledStatisticItemIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledScoreSectionTitle = styled.p`
    ${typography.title.M};
`;

export const StyledScoreDescription = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`;

export const StyledSeparator = styled.div`
    width: 100%;
    height: 1px;
    background: ${baseColors.white(0.1)};
`;

export const StyledLoaderContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding-top: 96px;
`;
