import { Terminal as XTerm } from 'xterm';
import { ForwardedRef, Ref, forwardRef, useEffect, useRef } from 'react';

export const Terminal = forwardRef(({
    onData
}: {onData: (data: string) => void,}, ref: ForwardedRef<XTerm>) => {
    const terminalElementRef = useRef<HTMLDivElement>(null);
    const terminalRef = useRef<XTerm>();
    
    
    useEffect(() => {
        if(terminalRef.current) {
            terminalRef.current.dispose()
        }
        terminalRef.current = new XTerm();
        terminalRef.current.open(terminalElementRef.current!);

        if(!terminalRef.current || !onData) return
        terminalRef.current.onData(onData);
    }, [onData])

    useEffect(() => {
        if(ref) {
            if(typeof ref === "function") {
                ref(terminalRef.current!);
            } else {
                ref.current = terminalRef.current!;
            }
        }
    }, [ref])
    return <div ref={terminalElementRef}></div>
})
