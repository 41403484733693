import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

const openedOverlayStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
`;

const closedOverlayStyle = css`
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
`;

export const StyledOverlay = styled.div<{ isOpened: boolean }>`
    background: ${baseColors.black(0.8)};
    ${({ isOpened }) => (isOpened ? openedOverlayStyle : closedOverlayStyle)};
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
`;

export const StyledAddHighlight = styled.div<{ isOpened: boolean }>`
    background: ${baseColors.black()};
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: ${({ isOpened }) => (isOpened ? '640px' : '48px')};
`;

const openedHeaderStyle = css`
    height: 48px;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid ${baseColors.white(0.1)};
`;

const closedHeaderStyle = css`
    height: 100vh;
    width: 48px;
    max-width: 48px;
    align-items: flex-start;
    border-left: 1px solid ${baseColors.white(0.1)};
`;

export const StyledHeader = styled.div<{ isOpened: boolean }>`
    ${({ isOpened }) => (isOpened ? openedHeaderStyle : closedHeaderStyle)};
    display: flex;
    position: relative;
    gap: 16px;
`;

export const StyledExpandBtn = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const closedTitleStyle = css`
    position: absolute;
    transform: rotate(-90deg);
    left: -42px;
    top: 119px;
`;

export const StyledTitleContainer = styled.div<{ isOpened: boolean }>`
    ${({ isOpened }) => !isOpened && closedTitleStyle};
    display: flex;
    gap: 12px;
    align-items: center;
    width: max-content;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
    padding: 96px 56px 0;
`;
