import { StyledItem, StyledHeadline, StyledText } from "../styled";

export const AttackHint = () => {
  return (
    <StyledItem width={270} style={{ textAlign: "right" }}>
      <StyledHeadline>Start attack</StyledHeadline>
      <StyledText>
        To start an attack, click on this button. It will trigger an attack that
        you must catch
      </StyledText>
    </StyledItem>
  );
};
