import styled, { css } from 'styled-components';
import { baseColors, typography } from '../../../../constants/main';

// hint

export const StyledBckg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    min-width: 1120px;
    background: rgba(18, 13, 33, 0.4);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledHint = styled.div<{
    position: any;
}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px 24px;
    gap: 20px;
    position: absolute;
    width: 470px;
    background: ${baseColors.primary()};
    border-radius: 8px;
    box-sizing: border-box;
    ${({ position }) => position}
`;

function generateAlignArrow(align: 'left' | 'right' | 'center') {
    switch (align) {
        case 'left':
            return css`
                left: 24px;
            `;
        case 'right':
            return css`
                right: 24px;
            `;
        case 'center':
            return css`
                left: calc(100% / 2 - 12px);
            `;
    }
}

export const StyledArrow = styled.div<{ align: 'left' | 'right' | 'center' }>`
    width: 12px;
    height: 12px;
    background: ${baseColors.primary()};
    position: absolute;
    top: -5px;
    border-radius: 3px;
    transform: rotate(45deg);
    ${({ align }) => generateAlignArrow(align)}
`;

export const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
`;

export const StyledTitle = styled.p`
    ${typography.text.L};
    font-weight: 600;
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

export const StyledTerminalButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
`;

export const StyledButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    box-sizing: border-box;
    width: 100%;
`;

// window

export const StyledWindow = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    min-width: 1120px;
    background: ${baseColors.primary(0.9)};
    z-index: 1001;
    overflow: auto;
    backdrop-filter: blur(6px);
`;

export const StyledWindowHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 20px 20px 32px;
    gap: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const StyledWindowBody = styled.div`
    padding: 0 10%;
    padding-top: 60px;
    max-width: 1920px;
    min-width: 940px;
    height: calc(100vh - 147px);
    overflow: auto;
    box-sizing: border-box;
`;
