import styled from 'styled-components'
import {
    baseColors,
    baseColorHover,
    typography,
} from '../../../../constants/main'

export const StyledActionBar = styled.div<{
    isHover: boolean
    scrolled: boolean
}>`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
    position: absolute;
    right: 0;
    top: 16px;
    padding-bottom: ${({ scrolled }) => (scrolled ? 0 : '24px')};
    padding-right: 24px;
    background-color: ${({ isHover }) =>
        isHover ? baseColorHover.card() : baseColors.card()};
    ::before {
        content: '';
        display: block;
        width: 24px;
        height: 100%;
        position: absolute;
        left: -24px;
        top: 0;
        background: ${({ isHover }) =>
            isHover
                ? `linear-gradient(270deg, ${baseColorHover.card()}, ${baseColorHover.card()},  ${baseColorHover.card(
                      0
                  )})`
                : `linear-gradient(270deg, ${baseColors.card()}, ${baseColors.card()}, ${baseColors.card(
                      0
                  )})`};
    }
`

export const StyledInfoButton = styled.div`
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    height: 24px;
    > span {
        ${typography.text.M};
        text-transform: uppercase;
        line-height: 20px;
        font-weight: 600;
    }
    :hover {
        opacity: 0.8;
    }
`
