import { CSSProperties, ReactNode } from 'react';
import { StyledTag } from './styled';

interface IProps {
    icon?: ReactNode;
    title: string;
    style?: CSSProperties;
}

export const Tag = ({ title, icon, style }: IProps) => {
    return (
        <StyledTag style={style}>
            {icon && icon}
            <span>{title}</span>
        </StyledTag>
    );
};
