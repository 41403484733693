import { useState, useEffect, useContext } from 'react';
import { LaunchCardProps, ICardErr } from '../types';
import { ITimeLineElements } from '../components/TimeLineOne/types';
import { TIMELINE_ELEMENT } from '../components/TimeLineOne/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { STATUS_CARD } from '../constants';
import { OnboardingContext } from '../../../modules/onboarding/OnboardingContext';
import {
    runAttack,
    checkStatusLaunchCard,
    destroyLaunch,
} from '../../../store/reducers/ActionCreators';
import { getSiems } from '../../../store/reducers/SiemSlice';
import { siemSlice } from '../../../store/reducers/SiemSlice';
import { apiClient } from '../../../api/apiClient';
dayjs.extend(utc);

export const useLaunchCard = ({
    status,
    uuid,
    index,
    isStaticallyOpen,
}: LaunchCardProps) => {
    const dispatch = useAppDispatch();
    const { siem: siemData } = useAppSelector((state) => state.SiemSlice);
    const [isExpand, setIsExpand] = useState<boolean>(false);
    const [timeLineList, setTimeLineList] = useState<ITimeLineElements[]>([]);
    const [isFooter, setIsFooter] = useState<boolean>(false);
    const [cardErr, setCardErr] = useState<ICardErr>({
        code: undefined,
        message: undefined,
    });

    const { start: startOnboarding } = useContext(OnboardingContext);

    const destroyCard = () => {
        dispatch(destroyLaunch(uuid, setCardErr));
    };

    const attackHandler = () => {
        dispatch(runAttack(uuid, setCardErr));
    };

    useEffect(() => {
        const getTimeLine = async () => {
            try {
                apiClient.get(`api/v1/labs/${uuid}/timeline/`).then((resp) => {
                    let tmp: ITimeLineElements[] = [];
                    resp.data.result.forEach(
                        (
                            elem: {
                                date: string;
                                message: string;
                                type: TIMELINE_ELEMENT;
                            },
                            idx: number
                        ) => {
                            tmp.push({
                                id: idx,
                                type: elem.type,
                                message: elem.message,
                                date: elem.date,
                                width: 0,
                            });
                        }
                    );
                    setTimeLineList(tmp);
                });
            } catch (err) {
                if (isAxiosError(err)) {
                    console.log('timeline error: ', err);
                }
            }
        };
        const waitGettingTimeLine = {
            wait: () =>
                setInterval(() => {
                    getTimeLine();
                }, 10000),
        };
        if (isStaticallyOpen === undefined) {
            if (status === STATUS_CARD.OPERATIONAL) {
                getTimeLine();
                waitGettingTimeLine.wait();
            } else {
                clearInterval(waitGettingTimeLine.wait());
            }
            if (isExpand && status === STATUS_CARD.DEACTIVATED) {
                getTimeLine();
            }
            return () => {
                clearInterval(waitGettingTimeLine.wait());
            };
        }
    }, [isExpand, status, isStaticallyOpen, uuid]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                status === STATUS_CARD.DEPLOY ||
                status === STATUS_CARD.SET_UP_VM ||
                status === STATUS_CARD.TEAR_DOWN
            ) {
                dispatch(checkStatusLaunchCard(status));
            } else {
                clearInterval(interval);
            }
        }, 10000);
        if (
            status === STATUS_CARD.DEACTIVATED ||
            status === STATUS_CARD.OPERATIONAL
        ) {
            setIsFooter(true);
        }
        if (status === STATUS_CARD.OPERATIONAL) {
            setIsExpand(true);
        }
        return () => clearInterval(interval);
    }, [status, setIsFooter, setIsExpand, dispatch]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (status !== STATUS_CARD.DEACTIVATED) {
                if (
                    typeof siemData === 'undefined' ||
                    siemData.interface_url === null ||
                    siemData.password === null ||
                    siemData.username === null
                ) {
                    dispatch(getSiems());
                } else {
                    clearInterval(interval);
                }
            }
        }, 10000);

        if (index === 1 && status === STATUS_CARD.DEACTIVATED) {
            clearInterval(interval);
            dispatch(siemSlice.actions.resetSiem());
        }
        return () => {
            return clearInterval(interval);
        };
    }, [status, siemData, dispatch, index]);

    return {
        isExpand,
        setIsExpand,
        destroyCard,
        timeLineList,
        isFooter,
        cardErr,
        setCardErr,
        attackHandler,
        startOnboarding,
        siemData,
    };
};
