import styled from 'styled-components';
import { typography, baseColors } from '../../../../constants';

export const StyledFactoids = styled.div`
    display: flex;
    gap: 16px;
`;

export const StyledList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
`;

export const StyledHorizontalList = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledTitle = styled.span`
    ${typography.title.M};
`;

export const StyledDescription = styled.span`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;
