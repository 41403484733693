import { memo, useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { HIGHLIGHT_TYPE } from '@/constants';
import {
    getLaunchCards,
    runLab,
    destroyLab,
    recreateLab,
} from '@/store/reducers/LaunchCardsSlice';
import { StyledTitleContainer, StyledTitle } from '../../styled';
import { StyledPageContainer } from '../../../styled';
import {
    PageHeader,
    IconButton,
    PayIcon,
    NoData,
    LoaderDots,
} from '@/components';
import { NoDataProps } from '@/components/NoData/types';
import { Actions, AddHighlight } from '../';
import { SideBar } from '@/components';
import { SideBarContext } from '@/context/sideBarContext';
import { HighlightContext } from '@/context/highlightContext';
import { useStatusPollingRequest } from '@/hooks/useStatusPollingRequest/useStatusPollingRequest';
import { ILab, ILabTeamplate } from '@/types';
import { runAttack } from '@/store/reducers/AttackSlice';
import { TOAST_TYPE, ToastContext } from '@/components/Toast/ToastContext';

export const LabContent = memo(function LabContent({
    lab,
}: {
    lab: ILabTeamplate;
}) {
    const { launchCardReducer } = useAppSelector((state) => state);
    const { cards, currentCard, isLoadingCards } = launchCardReducer;
    const [currentHLType, setCurrentHLType] = useState<
        HIGHLIGHT_TYPE | undefined
    >();
    const handleCurrentType = (ct: HIGHLIGHT_TYPE | undefined) => {
        setCurrentHLType(ct);
    };

    const [isOpened, setIsOpened] = useState(false);
    const handleOpening = (isOp: boolean) => {
        setIsOpened(isOp);
    };

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLaunchCards(lab.name));
    }, [dispatch, lab.name]);

    useStatusPollingRequest({ currentCard, lab, cards });

    return (
        // <StyledPageContainer style={{ padding: '0 48px 0 0' }}>
        <StyledPageContainer style={{ padding: '0' }}>
            <SideBarContext.Provider
                value={{
                    isOpened,
                    setIsOpened: handleOpening,
                }}
            >
                {/* <HighlightContext.Provider
                    value={{
                        currentType: currentHLType,
                        isOpened,
                        setIsOpened: handleOpening,
                        setCurrentType: handleCurrentType,
                    }}
                > */}
                {isLoadingCards && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '96px',
                        }}
                    >
                        <LoaderDots />
                    </div>
                )}

                {cards && !isLoadingCards && <Cards cards={cards} lab={lab} />}
                {/* </HighlightContext.Provider> */}
            </SideBarContext.Provider>
        </StyledPageContainer>
    );
});

const Cards = memo(function Cards({
    cards,
    lab,
}: {
    cards: ILab[];
    lab: ILabTeamplate;
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const iCanRun = lab.you_can_run;
    const { toast } = useContext(ToastContext);
    const NDProps: NoDataProps = iCanRun
        ? {
              title: "You haven't tried this lab yet",
              text: 'Make your first launch to explore this lab',
              buttons: [
                  {
                      title: 'Start a lab',
                      action: async () => {
                          if (!lab) return;
                          const res = await dispatch(
                              runLab({
                                  name: lab.name,
                                  labId: String(lab.id),
                                  difficulty: 1,
                                  goToFirstCard: navigate,
                              })
                          );
                          if ('error' in res && res.error) {
                              toast({
                                  title: res.payload?.message,
                                  type: TOAST_TYPE.ERROR,
                              });
                          }
                      },
                  },
              ],
          }
        : {
              title: 'No launches',
              text: 'To start the laboratory, buy a premium subscription',
              buttons: [
                  {
                      title: 'Subscribe',
                      action: () => {
                          window.open(
                              process.env.REACT_APP_PAYMENT_URL,
                              '_blank'
                          );
                      },
                  },
                  {
                      title: 'Plans',
                      action: () => {
                          Navigate({ to: 'prices' });
                      },
                  },
              ],
          };

    if (!cards.length) {
        return <NoData {...NDProps} />;
    }

    return (
        <>
            {/* <AddHighlight /> */}
            {/* <SideBar title="Add highlight" /> */}
            {/* <SideBar title="Add hightlight">
                <AddHightlightForm />
            </SideBar> */}
            <PageHeader
                title={
                    <StyledTitleContainer>
                        <StyledTitle>{lab.name}</StyledTitle>
                    </StyledTitleContainer>
                }
                tabs={[
                    {
                        value: 'Info',
                        id: pathname.replace(/\/[aA-zZ]*$/, `/info`),
                    },
                    {
                        value: 'Tasks',
                        id: pathname.replace(/\/[aA-zZ]*$/, `/tasks`),
                    },
                ]}
                actions={
                    iCanRun ? (
                        <Actions
                            recreated={cards[0].recreated}
                            endTime={cards[0].planned_destroy_date}
                            status={cards[0].status}
                            runLab={() => {
                                if (lab) {
                                    dispatch(
                                        runLab({
                                            name: lab.name,
                                            labId: String(lab.id),
                                            difficulty: 1,
                                            goToFirstCard: navigate,
                                        })
                                    );
                                }
                            }}
                            destroyLab={() => {
                                if (lab) {
                                    dispatch(
                                        destroyLab({
                                            name: lab.name,
                                            uuid: cards[0].uuid,
                                        })
                                    );
                                }
                            }}
                            refreshLab={() => {
                                if (lab) {
                                    dispatch(
                                        recreateLab({
                                            lab,
                                            goToFirstCard: navigate,
                                        })
                                    );
                                }
                            }}
                            runAttack={(playbookId) => {
                                dispatch(
                                    runAttack({
                                        labName: lab.name,
                                        uuid: cards[0].uuid,
                                        playbook_id: String(playbookId),
                                    })
                                );
                            }}
                        />
                    ) : (
                        <IconButton
                            size="M"
                            label="Subscribe"
                            iconLeft={<PayIcon />}
                            onClick={() => {
                                window.open(
                                    process.env.REACT_APP_PAYMENT_URL,
                                    '_blank'
                                );
                            }}
                        />
                    )
                }
            />
            <Outlet />
        </>
    );
});
