import { createContext } from "react";
import { IError } from "../../../types/error";

export type InvitePageType = {
  user: string;
  setUser: Function;
  pass: string;
  setPass: Function;
  id?: string;
  amountInvites: number;
  name: string;
  inviteError: IError;
  inviteUser: (id: string, user: string, pass: string) => void;
  checkInvite: (id: string) => void;
  logIn: (user: string, pass: string) => void;
};

export const InvitePageContext = createContext<InvitePageType>(
  {} as InvitePageType
);
