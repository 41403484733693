import { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ITimeLineInnerElements, ITimeLineElements } from '@/types/timeline';
import { checkTimeline } from '@/store/reducers/TimelineSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    createTimePoint,
    getReduceTimepointsWithAttack,
    getAllMinutes,
    getPosition,
    getDateFormat,
} from './utils';
import { TIMELINE_ELEMENT } from '@/constants/timeline';
import {
    StyledTimeLineContainer,
    StyledTimeLine,
    StyledTimeLneLine,
    StyledNoData,
    StyledDateNowContainer,
    StyledDateNowLine,
} from './styled';
import { LoaderDots } from '@/components';
import { TimePoint } from './components/TimePoint/TimePoint';
import { STATUS_LAB } from '@/constants';
import { useCurrentLabRequestConfig } from '../../pages/Lab/composable/useCurrentLabConfig';
dayjs.extend(utc);

interface IProps {
    isHover: boolean;
    status?: STATUS_LAB;
    uuid?: string;
    attak?: string | null;
}

export const TimeLine: FC<IProps> = ({ isHover, status, uuid, attak }) => {
    const dispatch = useAppDispatch();
    const { timeline, isLoadingTimeline } = useAppSelector(
        (state) => state.TimelineSllice
    );
    const currentLabRequestConfig = useCurrentLabRequestConfig()

    const elements = useMemo<ITimeLineElements[]>(() => {
        if (timeline && timeline.length) {
            return timeline.map((tl, idx) => ({ ...tl, id: idx }));
        }
        return [];
    }, [timeline]);

    const refTM = useRef<HTMLDivElement>(null);
    const [timeLineWidth, setTimeLineWidth] = useState(0);
    const [nowPosition, setNowPosition] = useState(-20);

    useEffect(() => {
        const intervalAttack = setInterval(() => {
            if (uuid && attak && status === STATUS_LAB.OPERATIONAL) {
                dispatch(checkTimeline(currentLabRequestConfig));
            } else {
                clearInterval(intervalAttack);
            }
        }, 10000);
        return () => clearInterval(intervalAttack);
    }, [dispatch, uuid, status, attak, currentLabRequestConfig]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                status === STATUS_LAB.OPERATIONAL &&
                elements.length &&
                timeLineWidth > 0
            ) {
                setNowPosition(
                    getPosition(
                        dayjs().toISOString(),
                        elements[0].date,

                        getAllMinutes(
                            dayjs
                                .utc(elements[0].date)
                                .format('MMMM DD YYYY HH:mm:ss')
                                .toString(),
                            dayjs
                                .utc(elements[elements.length - 1].date)
                                .format('MMMM DD YYYY HH:mm:ss')
                                .toString()
                        )
                    )
                );
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            return clearInterval(interval);
        };
    }, [status, elements, timeLineWidth, dispatch]);

    const resizeWindowHandler = () => {
        if (refTM && refTM.current?.offsetWidth)
            setTimeLineWidth(refTM.current?.offsetWidth);
    };

    useEffect(() => {
        resizeWindowHandler();
        window.addEventListener('resize', resizeWindowHandler);
        return () => {
            window.removeEventListener('resize', resizeWindowHandler);
        };
    }, []);

    const timePoints: ITimeLineInnerElements[] = useMemo(() => {
        let reduceResults: ITimeLineInnerElements[] = [];
        if (elements.length > 1) {
            let start: string = dayjs
                .utc(elements[0].date)
                .format('MMMM DD YYYY HH:mm:ss')
                .toString();

            let end: string = dayjs
                .utc(elements[elements.length - 1].date)
                .format('MMMM DD YYYY HH:mm:ss')
                .toString();

            let allMinutes = getAllMinutes(start, end);

            let tmpElements = [...elements];
            reduceResults = tmpElements
                .reduce((acc: ITimeLineInnerElements[], curr, idx, arr) => {
                    if (idx === 0) {
                        acc.push(
                            createTimePoint({
                                inputElem: curr,
                                start,
                                allMinutes,
                                timeLineWidth,
                            })
                        );
                    } else {
                        let tmpPoint = createTimePoint({
                            inputElem: curr,
                            start,
                            allMinutes,
                            timeLineWidth,
                        });
                        if (
                            tmpPoint.leftPixels -
                                acc[acc.length - 1].leftPixels <
                            40
                        ) {
                            acc[acc.length - 1].timepoints.push(tmpPoint);
                            acc[acc.length - 1].wordBook.push(tmpPoint.type);
                        } else {
                            acc.push(
                                createTimePoint({
                                    inputElem: curr,
                                    start,
                                    allMinutes,
                                    timeLineWidth,
                                })
                            );
                        }
                    }
                    return acc;
                }, [])
                .map((point) => {
                    if (point.timepoints.length) {
                        point.timepoints = [
                            { ...point, timepoints: [], wordBook: [] },
                            ...point.timepoints,
                        ];
                        point.wordBook = [point.type, ...point.wordBook];
                        point.timepointsReduced = getReduceTimepointsWithAttack(
                            point.timepoints
                        );
                        if (
                            point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) &&
                            point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                        ) {
                            point.type =
                                TIMELINE_ELEMENT.GROUP_DETECTED_WITH_ATTACK;
                        }
                        if (
                            point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) &&
                            !point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                        ) {
                            point.type = TIMELINE_ELEMENT.GROUP_ATTACK;
                        }
                        if (
                            !point.wordBook.includes(TIMELINE_ELEMENT.ATTACK) &&
                            point.wordBook.includes(TIMELINE_ELEMENT.DETECTED)
                        ) {
                            point.type = TIMELINE_ELEMENT.GROUP_DETECTED;
                        }
                    }
                    return point;
                });
        }

        return reduceResults;
    }, [elements, timeLineWidth]);

    const heightsPoints: number[] = useMemo(() => {
        let heights: number[] = [];
        timePoints.forEach((point, idx) => {
            if (!heights.length) {
                if (point.timepointsReduced.length) {
                    heights.push(point.timepointsReduced.length);
                } else {
                    heights.push(1);
                }
            } else {
                if (point.timepointsReduced.length) {
                    heights.push(
                        heights[heights.length - 1] +
                            point.timepointsReduced.length
                    );
                } else {
                    heights.push(heights[heights.length - 1] + 1);
                }
            }
        });

        return heights;
    }, [timePoints]);

    return (
        <StyledTimeLineContainer>
            <StyledTimeLine
                ref={refTM}
                height={
                    Number.isNaN(heightsPoints[heightsPoints.length - 1] * 20)
                        ? 0
                        : heightsPoints[heightsPoints.length - 1] * 20
                }
            >
                {status === STATUS_LAB.OPERATIONAL && (
                    <StyledDateNowContainer left={nowPosition}>
                        <span>
                            Now {getDateFormat(dayjs().utc().toString())}
                        </span>
                        <StyledDateNowLine />
                    </StyledDateNowContainer>
                )}
                {timePoints.length ? (
                    timePoints.map((point, idx) => {
                        return (
                            <TimePoint
                                point={point}
                                heightsPoints={heightsPoints}
                                idx={idx}
                                lastIdx={timePoints.length - 1}
                                key={point.id}
                            />
                        );
                    })
                ) : (
                    <StyledNoData hover={isHover}>
                        {isLoadingTimeline ? (
                            <LoaderDots />
                        ) : (
                            <p>No detects and attack</p>
                        )}
                    </StyledNoData>
                )}
                <StyledTimeLneLine />
            </StyledTimeLine>
        </StyledTimeLineContainer>
    );
};
