import styled from 'styled-components';
import { baseColors, typography } from '../../../../../../constants';

export const StyledContainer = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
    height: 20px;
`;

export const StyledSeparator = styled.div`
    height: 1px;
    width: 6px;
    background: ${baseColors.white(0.12)};
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const StyledTitle = styled.span`
    ${typography.title.M};
    color: ${baseColors.black()};
`;
export const StyledStatus = styled.span`
    ${typography.text.S};
    color: ${baseColors.black()};
`;
