import { useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    getScoreboard,
    getScoreDetail,
} from '@/store/reducers/ScoreboardSlice';
import { ScoreboardTable } from '@/components';
import { StyledScorePage } from '../styled';

export const ScoreboardAllUsers = () => {
    const { scoreId } = useParams();
    const navigate = useNavigate();
    const { scoreList, isLoadingScoreList, scoreDetail } = useAppSelector(
        (state) => state.scoreboardSlice
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getScoreboard());
    }, [dispatch]);

    useEffect(() => {
        if (scoreList && scoreList.length && !scoreId) {
            navigate(String(scoreList[0].id));
        }
    }, [scoreList, scoreId, navigate]);

    useEffect(() => {
        if (scoreId) {
            dispatch(getScoreDetail(Number(scoreId)));
        }
    }, [scoreId, dispatch]);

    return (
        <StyledScorePage>
            {!!scoreDetail ? <Outlet /> : <div />}
            <ScoreboardTable
                isLoader={isLoadingScoreList}
                items={scoreList}
                clickOnRow={(id) => navigate(String(id))}
            />
        </StyledScorePage>
    );
};
