import styled from 'styled-components'
import { typography, baseColors } from '../../../../../../constants/main'

export const StyledMore = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: ${baseColors.card()};
    z-index: 10000;
    padding: 16px 24px 16px 12px;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.33));
    max-height: 286px;
    overflow: auto;
`

export const StyledMoreItem = styled.div`
    display: flex;
    gap: 4px;
`

export const StyledMoreItemDotContainer = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledMoreItemTextContainer = styled.div``

export const StyledMoreItemTitle = styled.p`
    ${typography.text.S};
`

export const StyledMoreItemMessage = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`

export const StyledMoreTooltipContent = styled.div``

export const StyledTooltipTitle = styled.p`
    ${typography.text.M};
    color: ${baseColors.bckg()};
`

export const StyledTooltipText = styled.p`
    ${typography.text.S};
    color: ${baseColors.bckg()};
`
