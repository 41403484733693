import { ReactNode } from "react";

import {
  StyledContainer,
  StyledKey,
  StyledValueContainer,
  StyledValue,
  StyledIconContainer,
} from "./styled";

interface KeyValueProps {
  keyText?: string | null;
  valueText?: string | null;
  icon?: ReactNode;
}

export const KeyValue = (props: KeyValueProps) => {
  const { keyText, valueText, icon } = props;

  return (
    <StyledContainer>
      <StyledKey>{keyText || "..."}</StyledKey>
      <StyledValueContainer>
        <StyledValue>{valueText || "..."}</StyledValue>
        <StyledIconContainer>{icon && icon}</StyledIconContainer>
      </StyledValueContainer>
    </StyledContainer>
  );
};
