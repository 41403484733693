import { STATUS_LAB } from '@/constants';
import { useEffect } from 'react';
import { useAppDispatch } from '../redux';
import { checkAchievements } from '@/store/reducers/AchievementsSlice';
import { getHealthChecks } from '@/store/reducers/HealthChecksSlice';
import { getVM } from '@/store/reducers/VMSlice';
import { ILab, ILabTeamplate } from '@/types';
import { checkWorkshopLaunchById } from '../../store/reducers/LaunchCardsSlice';
import { checkTimeline } from '@/store/reducers/TimelineSlice';
import { useCurrentLabRequestConfig } from '@/pages/Lab/composable/useCurrentLabConfig';

export const useWorkshopStatusPollingRequest = ({
    currentCard,
    lab
}: {
    currentCard?: ILab;
    lab?: ILabTeamplate;
}) => {
    const dispatch = useAppDispatch();
    const currentLabRequestConfig = useCurrentLabRequestConfig()
    useEffect(() => {
        const healthCheckStatus = [
            STATUS_LAB.SET_UP_VM,
            STATUS_LAB.OPERATIONAL,
            STATUS_LAB.TEAR_DOWN,
        ];
        const statusesForChecking = [
            STATUS_LAB.DEPLOY,
            STATUS_LAB.SET_UP_VM,
            STATUS_LAB.TEAR_DOWN,
        ];

        const interval = setInterval(() => {
            if (currentCard && lab) {
                const status = currentCard.status;
                if (status !== STATUS_LAB.DEACTIVATED) {
                    dispatch(checkWorkshopLaunchById(currentCard.uuid));
                }
                if (status === STATUS_LAB.OPERATIONAL) {
                    if (currentCard.attack_button_pressed) {
                        dispatch(checkTimeline(currentLabRequestConfig));
                    }
                    dispatch(checkAchievements(currentLabRequestConfig));
                }
                if (healthCheckStatus.includes(status)) {
                    dispatch(getHealthChecks(currentLabRequestConfig));
                }
                if (
                    currentCard?.uuid &&
                    [...statusesForChecking, ...healthCheckStatus].includes(
                        currentCard.status
                    )
                ) {
                    dispatch(getVM(currentLabRequestConfig));
                }
                if (status === STATUS_LAB.DEACTIVATED) {
                    clearInterval(interval);
                }
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [dispatch, lab, currentCard, currentLabRequestConfig]);
};
