import { Icon } from "../Icon";

export const IconChecked = () => {
  return (
    <Icon
      content={
        <>
          <path
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            fill="white"
            fillOpacity="0.4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0523 7.93102C18.6427 8.51218 18.6501 9.4619 18.069 10.0523L12.1627 16.0523C11.8808 16.3387 11.4957 16.5 11.0938 16.5C10.6918 16.5 10.3067 16.3387 10.0248 16.0523L6.93102 12.9094C6.34987 12.319 6.35734 11.3693 6.94772 10.7882C7.53811 10.207 8.48782 10.2145 9.06898 10.8049L11.0938 12.8618L15.931 7.94772C16.5122 7.35734 17.4619 7.34987 18.0523 7.93102Z"
            fill="white"
          />
        </>
      }
    />
  );
};
