import { useState } from "react";
import { StyledHint, StyledLine, StyledContainerChildren } from "./styled";
import {
  IOnboardingHintConfig,
  IOnboardingHint,
  OnboardingHintKeysType,
} from "../../types";

interface UseOnboardingHintProps {
  isShow: boolean;
  config?: IOnboardingHintConfig;
}

export const useOnboardingHint = (props: UseOnboardingHintProps) => {
  const { isShow, config } = props;
  const [keys, _] = useState<string[]>(Object.keys(config ?? []));

  const hint = (key: OnboardingHintKeysType) => {
    if (isShow && config) {
      if (keys.includes(key)) {
        return (
          <StyledHint size={config[key].size} direction={config[key].direction}>
            <StyledLine
              lineHeight={config[key].lineHeight}
              direction={config[key].direction}
            >
              <StyledContainerChildren
                direction={config[key].direction}
                contentDirection={config[key].contentDirection}
              >
                {config[key].children}
              </StyledContainerChildren>
            </StyledLine>
          </StyledHint>
        );
      }
      return null;
    }
    return null;
  };
  return { hint };
};
