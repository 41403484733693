import { ILastCheck } from '@/types';
import {
    StyledTable,
    StyledRow,
    StyledCell,
    StyledHeader,
    StyledValue,
    StyledNoDataContainer,
} from './styled';
import { LoaderDots, OkIcon, FailedIcon, InfoIcon, Tag } from '@/components';
import { baseColors } from '@/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
    data: ILastCheck[];
}

export const CheckHistory = ({ data }: IProps) => {
    return (
        <StyledTable>
            <StyledRow>
                <StyledCell>
                    <StyledHeader>Date</StyledHeader>
                </StyledCell>
                <StyledCell>
                    <StyledHeader>Status</StyledHeader>
                </StyledCell>
                <StyledCell>
                    <StyledHeader>Description</StyledHeader>
                </StyledCell>
            </StyledRow>
            {data.length ? (
                data.map((d) => {
                    const { created, in_progress, reason, result } = d;
                    const renderStatus = () => {
                        if (in_progress) {
                            return <LoaderDots />;
                        }
                        if (result) {
                            return (
                                <Tag
                                    style={{ background: baseColors.ok() }}
                                    title="SUCCESS"
                                    icon={<OkIcon variant="S" />}
                                />
                            );
                        }
                        return (
                            <Tag
                                style={{ background: baseColors.error() }}
                                title="FAILED"
                                icon={<FailedIcon variant="S" />}
                            />
                        );
                    };
                    const renderReason = () => {
                        if (in_progress) {
                            return <StyledValue>{reason || '–'}</StyledValue>;
                        }
                        if (result) {
                            return <StyledValue>{reason || '–'}</StyledValue>;
                        }
                        return (
                            <StyledValue style={{ color: baseColors.error() }}>
                                {reason || '–'}
                            </StyledValue>
                        );
                    };
                    return (
                        <StyledRow>
                            <StyledCell>
                                <StyledValue>
                                    {dayjs
                                        .utc(created)
                                        .format('MM.DD.YYYY, HH:mm')
                                        .toString()}
                                </StyledValue>
                            </StyledCell>
                            <StyledCell>{renderStatus()}</StyledCell>
                            <StyledCell>{renderReason()}</StyledCell>
                        </StyledRow>
                    );
                })
            ) : (
                <StyledNoDataContainer>
                    <StyledValue>Check history is empty</StyledValue>
                    <StyledValue style={{ color: baseColors.white(0.5) }}>
                        Your check history will appear here. Check how it works
                    </StyledValue>
                </StyledNoDataContainer>
            )}
            {data.length ? (
                <StyledCell style={{ opacity: 0.5 }}>
                    <InfoIcon variant="S" />
                    <StyledValue>Date format is UTC</StyledValue>
                </StyledCell>
            ) : null}
        </StyledTable>
    );
};
