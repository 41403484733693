import styled, { css } from 'styled-components';
import { TIMELINE_ELEMENT } from '@/constants';
import { typography, baseColors, baseColorHover } from '@/constants/main';

export const StyledTimeLineContainer = styled.div`
    padding: 48px;
`;

export const StyledLegend = styled.div`
    display: flex;
    gap: 32px;
    box-sizing: border-box;
    padding: 20px 0;
    padding-top: 8px;
`;

export const StyledLegendCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledLegendItem = styled.div`
    display: flex;
    gap: 12px;
    position: relative;
    align-items: center;
    opacity: 0.5;
`;

export const StyledLegendItemText = styled.span`
    ${typography.text.S};
`;

export const StyledStartEndDateBlock = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 24px;
`;

export const StyledDateWrapper = styled.div`
    display: inline-flex;
    gap: 4px;
`;

export const StyledStartFinishDate = styled.span`
    ${typography.text.S};
    font-weight: 600;
`;

export const StyledTimeLine = styled.div<{ height: number }>`
    width: 100%;
    height: ${({ height }) => height}px;
    box-sizing: border-box;
    position: relative;
`;

export const StyledTimeLneLine = styled.div`
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
    top: 10px;
    ::before {
        content: '';
        position: absolute;
        display: block;
        height: 12px;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        left: 0;
        top: -5px;
    }
    ::after {
        content: '';
        position: absolute;
        display: block;
        height: 12px;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        right: 0;
        top: -5px;
    }
`;

export const StyledNoData = styled.div<{ hover: boolean }>`
    width: 200px;
    position: absolute;
    left: calc(50% - 100px);
    background-color: ${({ hover }) =>
        hover ? baseColorHover.board() : baseColors.board()};
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    > p {
        ${typography.text.S};
        text-align: center;
    }
`;

export const StyledTimePoint = styled.div<{ left: number }>`
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 40px;
    background-color: ${baseColors.board()};
    z-index: 1;
    cursor: pointer;
`;

export const StyledCounter = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    > p {
        ${typography.text.S};
        font-weight: 700;
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        line-height: 10px;
        text-align: center;
    }
`;

const generateStyleDot = (view: TIMELINE_ELEMENT) => {
    switch (view) {
        case TIMELINE_ELEMENT.MITRE_STAGE:
            return css`
                width: 6px;
                height: 6px;
                top: 7px;
                left: 7px;
                background: ${baseColors.link()};
            `;
        case TIMELINE_ELEMENT.ATTACK:
            return css`
                width: 6px;
                height: 6px;
                top: 7px;
                left: 7px;
                background: ${baseColors.error()};
            `;
        case TIMELINE_ELEMENT.DETECTED:
            return css`
                width: 6px;
                height: 6px;
                top: 7px;
                left: 7px;
                background: ${baseColors.warning()};
            `;
        case TIMELINE_ELEMENT.GROUP_DETECTED:
            return css`
                width: 12px;
                height: 12px;
                top: 4px;
                left: 4px;
                box-sizing: border-box;
                border: 2px solid ${baseColors.warning()};
            `;
        case TIMELINE_ELEMENT.GROUP_ATTACK:
            return css`
                width: 12px;
                height: 12px;
                top: 4px;
                left: 4px;
                box-sizing: border-box;
                border: 2px solid ${baseColors.error()};
            `;
        case TIMELINE_ELEMENT.GROUP_DETECTED_WITH_ATTACK:
            return css`
                width: 12px;
                height: 12px;
                top: 4px;
                left: 4px;
                box-sizing: border-box;
                border: 2px solid ${baseColors.error()};
                ::before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    border-radius: 20px;
                    background: ${baseColors.warning()};
                }
            `;
    }
};

export const StyledTimePointDot = styled.div<{ view: TIMELINE_ELEMENT }>`
    position: absolute;
    border-radius: 20px;
    ${({ view }) => generateStyleDot(view)};
`;

export const StyledPointFootnote = styled.div<{ height: number }>`
    height: ${({ height }) => height}px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: 9px;
    top: 20px;
`;

const generateDirectionContent = (direction: 'left' | 'right') => {
    switch (direction) {
        case 'left':
            return css`
                left: -2px;
            `;
        case 'right':
            return css`
                right: -2px;
            `;
    }
};

export const StyledPointFootnoteContent = styled.div<{
    direction: 'left' | 'right';
}>`
    position: absolute;
    bottom: -12px;
    ${({ direction }) => generateDirectionContent(direction)};
`;

export const StyledPointFootnoteContentItem = styled.div<{
    direction: 'left' | 'right';
}>`
    height: 20px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: ${({ direction }) =>
        direction === 'left' ? 'flex-start' : 'flex-end'};
`;

export const StyledPointFootnoteContentItemDate = styled.span`
    ${typography.text.S};
    white-space: nowrap;
`;

const generateStyleFootnoteDot = (view: TIMELINE_ELEMENT) => {
    switch (view) {
        case TIMELINE_ELEMENT.ATTACK:
            return css`
                width: 5px;
                min-width: 5px;
                height: 5px;
                min-height: 5px;
                top: 7px;
                left: 7px;
                background: ${baseColors.error()};
            `;
        case TIMELINE_ELEMENT.MITRE_STAGE:
            return css`
                width: 5px;
                min-width: 5px;
                height: 5px;
                min-height: 5px;
                top: 7px;
                left: 7px;
                background: ${baseColors.link()};
            `;
        case TIMELINE_ELEMENT.DETECTED:
            return css`
                width: 5px;
                min-width: 5px;
                height: 5px;
                min-height: 5px;
                top: 7px;
                left: 7px;
                background: ${baseColors.warning()};
            `;
    }
};

export const StyledPointFootnoteContentItemDot = styled.div<{
    view: TIMELINE_ELEMENT;
}>`
    ${({ view }) => generateStyleFootnoteDot(view)};
    border-radius: 20px;
`;
export const StyledDateNowContainer = styled.div<{ left: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96px;
    position: absolute;
    top: -40px;
    left: ${({ left }) => `calc(${left}% - 48px)`};
    > span {
        ${typography.text.S};
        opacity: 0.25;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
`;

export const StyledDateNowLine = styled.div`
    height: 16px;
    width: 1px;
    background: ${baseColors.primary(0.5)};
`;

export const StyledDateNowPoint = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: ${baseColors.primary(0.5)};
`;
