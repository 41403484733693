import { useState } from 'react';
import {
    TextItem,
    EmptyIcon,
    CheckIcon,
    MoreIcon,
    IconButton,
    SendIcon,
} from '@/components';
import { getDateUTC } from '@/utils/date';
import { baseColors } from '@/constants';
import { IHighlights } from '@/types/highlights';
import {
    StyledHighlights,
    StyledIcon,
    StyledTitle,
    StyledSubtitle,
    StyledContainer,
    StyledPointsContainer,
    StyledPointKey,
    StyledPointValue,
} from '../../styled';

interface IProps {
    isFound: boolean;
    highlight: IHighlights;
    onClick?: (hl: IHighlights['type']) => void;
}

export const Highlight = ({ isFound, highlight, onClick }: IProps) => {
    const [isHover, setIsHover] = useState(false);
    const { name, description, created, value, points } = highlight;

    return (
        <StyledHighlights
            isFound={isFound}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <StyledIcon>
                {isFound ? (
                    <CheckIcon fill={baseColors.ok()} />
                ) : (
                    <EmptyIcon fill={baseColors.white(0.25)} />
                )}
            </StyledIcon>
            <StyledContainer gap={12}>
                <StyledContainer gap={2}>
                    <StyledTitle>{name}</StyledTitle>
                    {isFound && created ? (
                        <StyledSubtitle>
                            {getDateUTC(created, 'DD.MM.YYYY, HH:mm')}
                        </StyledSubtitle>
                    ) : (
                        <StyledSubtitle>Not found</StyledSubtitle>
                    )}
                    {/* <StyledSubtitle>
                        <StyledSubtitleKey>{subtitleKey}</StyledSubtitleKey>
                        {created
                            ? getDateUTC(created, 'DD.MM.YYYY, HH:mm')
                            : ''}
                    </StyledSubtitle> */}
                </StyledContainer>
                <StyledContainer
                    gap={8}
                    style={{
                        padding: '12px 0 24px 0',
                        borderBottom: `1px solid ${baseColors.white(0.1)}`,
                        borderTop: `1px solid ${baseColors.white(0.1)}`,
                    }}
                >
                    <TextItem
                        keySize="S"
                        keyText="Description"
                        valueSize="S"
                        valueText={description}
                        direction="column"
                    />
                    <TextItem
                        keySize="S"
                        keyText="Value"
                        valueSize="S"
                        valueText={value ?? undefined}
                        direction="column"
                    />
                </StyledContainer>
                <StyledPointsContainer style={{ paddingTop: '8px' }}>
                    <StyledPointValue>{points}</StyledPointValue>
                    <StyledPointKey>Points</StyledPointKey>
                </StyledPointsContainer>
            </StyledContainer>
            <StyledIcon>
                {!isFound && (
                    <IconButton
                        icon={isHover ? <SendIcon /> : <MoreIcon />}
                        onClick={() => {
                            if (onClick) onClick(highlight.type);
                        }}
                    />
                )}
            </StyledIcon>
        </StyledHighlights>
    );
};
