import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const ArrowIndtIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M7.69154 14.9515C7.75003 14.9705 7.81089 14.9842 7.87355 14.9921C7.95745 15.0027 8.04244 15.0027 8.12635 14.9921C8.18904 14.9842 8.24993 14.9705 8.30846 14.9515C8.45424 14.9044 8.59133 14.8229 8.70711 14.7072L11.7071 11.7072C12.0976 11.3166 12.0976 10.6835 11.7071 10.293C11.3166 9.90243 10.6834 9.90243 10.2929 10.293L9 11.5858V4.41421L10.2929 5.70711C10.6834 6.09763 11.3166 6.09763 11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289L8.70797 1.29376C8.70537 1.29115 8.70275 1.28855 8.70012 1.28597C8.60649 1.19413 8.49928 1.12441 8.38503 1.07682C8.26652 1.02733 8.13645 1 8 1C7.86425 1 7.73481 1.02705 7.61679 1.07606C7.499 1.12485 7.38865 1.19713 7.29289 1.29289L4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711C4.68342 6.09763 5.31658 6.09763 5.70711 5.70711L7 4.41421V11.5858L5.70711 10.293C5.31658 9.90243 4.68342 9.90243 4.29289 10.293C3.90237 10.6835 3.90237 11.3166 4.29289 11.7072L7.29289 14.7072C7.40867 14.8229 7.54576 14.9044 7.69154 14.9515Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M7.70711 8.70711L11 5.41422L11 18.5858L7.70711 15.293C7.31658 14.9024 6.68342 14.9024 6.29289 15.293C5.90237 15.6835 5.90237 16.3166 6.29289 16.7072L11.2929 21.7072C11.6834 22.0977 12.3166 22.0977 12.7071 21.7072L17.7071 16.7072C18.0976 16.3166 18.0976 15.6835 17.7071 15.293C17.3166 14.9024 16.6834 14.9024 16.2929 15.293L13 18.5858L13 5.41422L16.2929 8.70711C16.6834 9.09763 17.3166 9.09763 17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L12.7081 2.29385C12.7056 2.29136 12.7031 2.28887 12.7006 2.2864C12.5201 2.10925 12.2728 2 12 2C11.8644 2 11.7351 2.02699 11.6172 2.07588C11.502 2.12357 11.3938 2.19374 11.2995 2.2864C11.297 2.28879 11.2946 2.29119 11.2922 2.29361L6.29289 7.29289C5.90237 7.68342 5.90237 8.31658 6.29289 8.70711C6.68342 9.09763 7.31658 9.09763 7.70711 8.70711Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
