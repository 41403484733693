import { FC, SyntheticEvent } from 'react';
import { StyledButton } from './styled';
import { IButton, SHAPE } from './types';

export const Button: FC<IButton> = ({
    icon,
    style,
    children = 'Start a stand',
    view = SHAPE.PRIMARY,
    disabled,
    onClick,
}) => {
    const clickHandler = (e: SyntheticEvent) => {
        if (onClick) onClick(e);
    };
    return (
        <StyledButton
            onClick={clickHandler}
            style={{ ...style }}
            view={view}
            disabled={disabled}
        >
            {icon && <div className="icon">{icon}</div>}
            {children}
        </StyledButton>
    );
};
