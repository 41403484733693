import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/redux';
import { getProfile } from '@/store/reducers/ProfileSlice';
import { MainHeader } from '../../components';
import { StyledMainLayout } from './styled';
import { SideBarContext } from '@/context/sideBarContext';

export const Layout = () => {
    const dispath = useAppDispatch();
    useEffect(() => {
        dispath(getProfile());
    }, [dispath]);
    const [isOpened, setIsOpened] = useState(false);
    return (
        <StyledMainLayout>
            <SideBarContext.Provider value={{ isOpened, setIsOpened }}>
                <MainHeader />
                <Outlet />
            </SideBarContext.Provider>
        </StyledMainLayout>
    );
};
