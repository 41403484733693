import { difficulity as difficultyConfig } from "../constants/launch";
import { baseColors } from "../constants/main";
import { IDifficulity } from "../types/difficulty";

type generateDifficultyVolumeType = (
  diffic: number,
  difficLength: number
) => IDifficulity;

export const generateDifficultyVolume: generateDifficultyVolumeType = (
  difficValue,
  difficLength
) => {
  let activeColors: string[] = [];
  let disabledColors: string[] = [];

  if (difficLength <= 3) {
    activeColors = [baseColors.ok(), baseColors.warning(), baseColors.error()];
    disabledColors = [
      baseColors.ok(0.25),
      baseColors.warning(0.25),
      baseColors.error(0.25),
    ];
  }
  if (difficLength > 3 && difficLength <= 6) {
    activeColors = [
      baseColors.ok(),
      baseColors.ok(),
      baseColors.warning(),
      baseColors.warning(),
      baseColors.error(),
      baseColors.error(),
    ];
    disabledColors = [
      baseColors.ok(0.25),
      baseColors.ok(0.25),
      baseColors.warning(0.25),
      baseColors.warning(0.25),
      baseColors.error(0.25),
      baseColors.error(0.25),
    ];
    return {
      activeColor: activeColors[difficValue],
      disabledColor: disabledColors[difficValue],
      itemsAmount: difficLength,
      indexActiveIndex: difficValue,
    };
  }
  if (difficLength > 6 && difficLength <= 9) {
    activeColors = [
      baseColors.ok(),
      baseColors.ok(),
      baseColors.ok(),
      baseColors.warning(),
      baseColors.warning(),
      baseColors.warning(),
      baseColors.error(),
      baseColors.error(),
      baseColors.error(),
    ];
    disabledColors = [
      baseColors.ok(0.25),
      baseColors.ok(0.25),
      baseColors.ok(0.25),
      baseColors.warning(0.25),
      baseColors.warning(0.25),
      baseColors.warning(0.25),
      baseColors.error(0.25),
      baseColors.error(0.25),
      baseColors.error(0.25),
    ];
  }
  return {
    activeColor: activeColors[difficValue],
    disabledColor: disabledColors[difficValue],
    itemsAmount: difficLength,
    indexActiveIndex: difficValue,
  };
};

export const getCurrentDifficultyConfig = (innerValue: string) => {
  if (Object.keys(difficultyConfig).includes(innerValue)) {
    return difficultyConfig[innerValue];
  }
  return {
    activeColor: baseColors.ok(),
    disabledColor: baseColors.ok(0.25),
    itemsAmount: 3,
    indexActiveIndex: undefined,
  };
};
