import styled from "styled-components";
import { typography, baseColors } from "../../constants/main";

export const StyledTooltipStatic = styled.div<{ left: number; top: number }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 20px;
  gap: 16px;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ top, left }) => (top === 0 || left === 0 ? 0 : 1)};
  background: #fff;
  border-radius: 8px;
  width: 400px;
  box-sizing: border-box;
  z-index: 9;
  ::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: -5px;
    right: 24px;
    background: #ffffff;
    border-radius: 3px;
    transform: rotate(45deg);
  }
`;

export const StyledTooltipStaticContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: calc(100% - 96px);
  gap: 20px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledTitle = styled.p`
  ${typography.text.L};
  line-height: 20px;
  font-weight: 600;
  color: ${baseColors.bckg()};
`;

export const StyledText = styled.p`
  ${typography.text.M};
  color: ${baseColors.bckg()};
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
