import { Button } from "@/components"
import { StyledSubscribeBlock } from './styles';
import React from 'react';

export const SubscribeBlock = ({children}: React.PropsWithChildren<{}>) => {
    return <StyledSubscribeBlock>
        <p>{children}</p>
        <Button onClick={() => window.open(process.env.REACT_APP_PAYMENT_URL)}>
            Subscribe
        </Button>
    </StyledSubscribeBlock>
}