import styled from 'styled-components'
import { typography } from '../../../../../constants/main'

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 34px;
    box-sizing: border-box;
`

export const StyledKey = styled.span`
    box-sizing: border-box;
    padding-top: 2px;
    opacity: 0.72;
    ${typography.text.M};
    width: 278px;
    min-width: 278px;
`

export const StyledValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    box-sizing: border-box;
    width: 100%;
`

export const StyledValue = styled.p`
    box-sizing: border-box;
    ${typography.text.M};
`

export const StyledIconContainer = styled.div`
    width: 20px;
    height: 20px;
`
