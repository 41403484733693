import { Icon } from "../Icon";

interface IconLinkProps {
  size?: number;
}

export const IconLink = ({ size }: IconLinkProps) => {
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M1.14111 10.7501C1.14111 8.54098 2.93197 6.75012 5.14111 6.75012H13.1411C15.3503 6.75012 17.1411 8.54098 17.1411 10.7501V18.7501C17.1411 20.9593 15.3503 22.7501 13.1411 22.7501H5.14111C2.93197 22.7501 1.14111 20.9593 1.14111 18.7501V10.7501Z"
            fill="white"
            fillOpacity="0.4"
          />
          <path
            d="M14.3736 2.57825C13.5452 2.57825 12.8736 3.24982 12.8736 4.07825C12.8736 4.90667 13.5452 5.57825 14.3736 5.57825H16.4091L11.1916 10.7958C10.6058 11.3816 10.6058 12.3313 11.1916 12.9171C11.7774 13.5029 12.7271 13.5029 13.3129 12.9171L18.5305 7.69957V9.7351C18.5305 10.5635 19.202 11.2351 20.0305 11.2351C20.8589 11.2351 21.5305 10.5635 21.5305 9.7351V4.07825C21.5305 3.24982 20.8589 2.57825 20.0305 2.57825H14.3736Z"
            fill="white"
          />
        </>
      }
    />
  );
};
