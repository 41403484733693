import { Icon } from "../Icon";

interface IconCopyProps {
  size?: number;
}

export const IconCopy = ({ size }: IconCopyProps) => {
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M2 11C2 8.79086 3.79086 7 6 7H12C14.2091 7 16 8.79086 16 11V17C16 19.2091 14.2091 21 12 21H6C3.79086 21 2 19.2091 2 17V11Z"
            fill="white"
            fillOpacity="0.4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 6H12C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14H18C18.5523 14 19 13.5523 19 13V7C19 6.44772 18.5523 6 18 6ZM12 3C9.79086 3 8 4.79086 8 7V13C8 15.2091 9.79086 17 12 17H18C20.2091 17 22 15.2091 22 13V7C22 4.79086 20.2091 3 18 3H12Z"
            fill="white"
          />
        </>
      }
    />
  );
};
