interface IProffitItem {
  key: number | string;
  value: string;
}

export const proffitList: IProffitItem[] = [
  {
    key: 0,
    value: "Environment to train your defensive skills",
  },
  {
    key: 1,
    value: "Various attacks to detect",
  },
  {
    key: 2,
    value: "Scoreboard both public and private",
  },
  {
    key: 3,
    value: "In-depth tutorial that guide you",
  },
];
