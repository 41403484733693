import { ReactNode } from 'react';
import { Button } from '../../../../components/Button/Button';
import { SHAPE } from '../../../../components/Button/types';
import { steps, totalSteps } from '../../OnboardingProvider';
import { OnboardingContextType } from '../../types';
import {
    StyledButtonsWrapper,
    StyledTerminalButtons,
    StyledTitle,
    StyledWindow,
    StyledWindowBody,
    StyledWindowHeader,
} from './styled';

type WindowType = Omit<OnboardingContextType, 'start' | 'isShow'> & {
    children: ReactNode;
};

export const Window = (props: WindowType) => {
    const { step, children, end, next, prev, numberOfSteps } = props;
    return (
        <StyledWindow>
            <StyledWindowHeader>
                {/* <StyledTitle>{`${step.step}/${totalSteps}. ${step.title}`}</StyledTitle>{' '} */}
                <StyledTitle>{step.title}</StyledTitle>
            </StyledWindowHeader>
            <StyledWindowBody>{children}</StyledWindowBody>
            <StyledTerminalButtons
                style={{
                    position: 'absolute',
                    bottom: 0,
                    padding: '20px',
                    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                }}
            >
                {/* <StyledButtonsWrapper>
                    <Button
                        view={SHAPE.GHOST}
                        onClick={prev}
                        disabled={step.step === 1}
                    >
                        prev
                    </Button>
                    <Button
                        view={SHAPE.SECONDARY}
                        onClick={next}
                        disabled={step.step === numberOfSteps}
                    >
                        next
                    </Button>
                </StyledButtonsWrapper> */}
                <Button view={SHAPE.GHOST} onClick={end}>
                    close
                </Button>
            </StyledTerminalButtons>
        </StyledWindow>
    );
};
