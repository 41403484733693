import { STATUS_LAB } from '@/constants';
import { LoaderDots, TextItem } from '@/components';
import { CopyItem, StatusOfVM } from './components';
import { IVM } from '@/types/vm';
import {
    StyledList,
    StyledTitle,
    StyledHorizontalList,
    StyledDescription,
} from './styled';

interface IProps {
    status: STATUS_LAB;
    vms?: IVM[];
    isLoading?: boolean;
}

export const VMs = ({ vms = [], isLoading, status }: IProps) => {
    return (
        <StyledList>
            <StyledTitle>VM</StyledTitle>
            {isLoading && <LoaderDots />}
            {!isLoading && (
                <StyledHorizontalList>
                    <StyledList>
                        <StyledDescription style={{ lineHeight: '20px' }}>
                            Name
                        </StyledDescription>

                        {vms.map((vm) => (
                            <TextItem
                                cut
                                valueText={vm.name}
                                key={vm.ip_addr}
                            />
                        ))}
                    </StyledList>
                    <StyledList>
                        <StyledDescription style={{ lineHeight: '20px' }}>
                            IP-address
                        </StyledDescription>
                        {vms.map((vm) => (
                            <CopyItem
                                cut
                                valueText={vm.ip_addr}
                                key={vm.ip_addr}
                            />
                        ))}
                    </StyledList>
                    <StyledList style={{ flexShrink: 0 }}>
                        <StyledDescription style={{ lineHeight: '20px' }}>
                            Status
                        </StyledDescription>
                        {vms.map((vm) => (
                            <StatusOfVM
                                key={vm.ip_addr}
                                logs={vm.logs}
                                status={status}
                            />
                        ))}
                    </StyledList>
                </StyledHorizontalList>
            )}
        </StyledList>
    );
};
