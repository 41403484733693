import { FC } from 'react';
import { Button } from '@/components/Button/Button';
import {
    StyledNoData,
    StyledTitle,
    StyledText,
    StyledTextWrapper,
    StyledButtons,
} from './styled';
import { NoDataProps } from './types';

export const NoData: FC<NoDataProps> = ({ title, text, buttons }) => {
    return (
        <StyledNoData>
            <StyledTextWrapper>
                <StyledTitle>{title}</StyledTitle>
                <StyledText>{text}</StyledText>
            </StyledTextWrapper>
            {buttons && (
                <StyledButtons>
                    {buttons.map((btn, idx) => {
                        return (
                            <Button
                                key={idx}
                                onClick={btn.action}
                                view={btn.variants}
                            >
                                {btn.title}
                            </Button>
                        );
                    })}
                </StyledButtons>
            )}
        </StyledNoData>
    );
};
