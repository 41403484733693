import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledAchivement = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledImg = styled.img<{ $received: boolean }>`
    height: 90px;
    opacity: ${({ $received }) => ($received ? 1 : 0.25)};
`;

export const StyledTextContainer = styled.div<{ received: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: ${({ received }) => (received ? 1 : 0.25)};
`;

export const StyledTitle = styled.p`
    ${typography.title.M};
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
`;
