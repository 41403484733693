import { Icon } from "../Icon";

interface IconInfoProps {
  figureFill?: string;
  bckgFill?: string;
  size?: number;
}

export const IconInfo = (props: IconInfoProps) => {
  const { figureFill, bckgFill, size } = props;
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill={bckgFill || "#145ADB"}
          />
          <path
            d="M11.1118 8.40381C10.8394 8.12842 10.7031 7.7959 10.7031 7.40625C10.7031 7.0166 10.8394 6.68555 11.1118 6.41309C11.3872 6.1377 11.7197 6 12.1094 6C12.499 6 12.8301 6.1377 13.1025 6.41309C13.3779 6.68555 13.5156 7.0166 13.5156 7.40625C13.5156 7.7959 13.3779 8.12842 13.1025 8.40381C12.8301 8.67627 12.499 8.8125 12.1094 8.8125C11.7197 8.8125 11.3872 8.67627 11.1118 8.40381ZM10 9.33984H13.3398V16.0195H14.2188V17.4258H10V16.0195H10.8789V10.7461H10V9.33984Z"
            fill={figureFill || "white"}
          />
        </>
      }
    />
  );
};
