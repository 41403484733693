import { memo, useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLaunchCardById } from '@/store/reducers/LaunchCardsSlice';
import { StyledPageContainer, StyledLaunchesContainer } from './styled';
import { Launch } from './components/Launch/Launch';
import { ILab } from '@/types';
import { LabLaunch } from '@/pages';

const LaunchItem = memo<{ lab: ILab }>(function LaunchItem({ lab }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const onClick = useCallback(() => {
        navigate(`${lab.uuid}/info`);
        dispatch(getLaunchCardById(lab.uuid));
    }, [lab.uuid, dispatch, navigate]);
    return <Launch lab={lab} onClick={onClick} />;
});

export const LabLaunches = () => {
    const navigate = useNavigate();
    const { launchCardReducer } = useAppSelector((state) => state);
    const { cards, currentCard } = launchCardReducer;

    const dispatch = useAppDispatch();

    const { uuid } = useParams();

    useEffect(() => {
        const cardId = cards.map((c) => c.uuid);
        if ((uuid && !cardId.includes(uuid)) || !uuid) {
            navigate(`${cards[0].uuid}/info`);
            dispatch(getLaunchCardById(cards[0].uuid));
            return;
        }
        if (uuid && !currentCard) {
            dispatch(getLaunchCardById(uuid));
        }
    }, [cards, navigate, uuid, dispatch, currentCard]);

    return (
        <StyledPageContainer>
            <StyledLaunchesContainer>
                {cards && cards.length > 0
                    ? cards.map((c) => <LaunchItem lab={c} key={c.uuid} />)
                    : null}
            </StyledLaunchesContainer>
            <LabLaunch />
        </StyledPageContainer>
    );
};
