import { useEffect, useMemo } from 'react';
import { getLabsList } from '../../store/reducers/LabsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Card } from './Card/Card';
import { ICard } from './types';
import { ILabTeamplate } from '@/types';
import { getLayout } from '@/store/reducers/LayoutSlice';
import { StyledPageContainer } from '../styled';
import { LoaderDots as Loader } from '../../components';

export const Labs = () => {
    const { LabsSlice, LayoutSlice } = useAppSelector((state) => state);
    const { labs: inputLabs, isLoading } = LabsSlice;
    const { layout } = LayoutSlice;

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getLabsList());
        dispatch(getLayout());
    }, [dispatch]);

    const labs: ICard[] = useMemo(() => {
        let cards: ICard[] = [];
        if (inputLabs && inputLabs.length && layout && layout.length) {
            layout.forEach((c) => {
                cards.push({
                    direction: c.direction,
                    templates: [...inputLabs].filter((i) => {
                        const il = i as unknown as ILabTeamplate;
                        return c.templates.includes(il.id);
                    }),
                });
            });
        }
        return cards;
    }, [inputLabs, layout]);

    return (
        <StyledPageContainer
            style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                boxSizing: 'border-box',
                padding: '8px',
            }}
        >
            {labs.map((l, idx) => (
                <Card {...l} top={idx === 0} key={idx} />
            ))}
            {isLoading && <Loader />}
        </StyledPageContainer>
    );
};
