import { ReactNode, useMemo, useState, useContext } from 'react';
// import { HighlightContext } from '@/context/highlightContext';
import { SideBarContext } from '@/components/SideBar/context';
import { StyledBlockContainer } from '../../styled';
import { StyledTextBody, StyledTextWrapper } from './styled';
import { Highlight } from './components/Highlight/Highlight';
import { IHighlights, IGetHighlightResponse } from '@/types';
import { HIGHLIGHT_TYPE } from '@/constants';
import {
    Button,
    Accordion,
    VarIcon,
    DocIcon,
    WebIcon,
    UserIcon,
} from '@/components';
import { SHAPE } from '@/components/Button/types';
import { IItem as AccItem } from '@/components/Accordion/types';

interface IHighlightsWithIsFound extends IHighlights {
    isFound: boolean;
}

interface IHighlightsListItems {
    [name: HIGHLIGHT_TYPE | string]: IHighlightsWithIsFound[];
}

interface IProps {
    list: IGetHighlightResponse;
    setCurrentType?: (ct: HIGHLIGHT_TYPE | undefined) => void;
    currentType?: HIGHLIGHT_TYPE;
}

export const HighlightsList = ({
    list,
    setCurrentType,
    currentType,
}: IProps) => {
    const [openedItems, setOpenedItems] = useState<(string | number)[]>([]);
    const { setOpenCellId } = useContext(SideBarContext);
    // const { setCurrentType, setIsOpened } = useContext(HighlightContext);

    const sortedItems: IHighlightsListItems = useMemo(() => {
        const result: IHighlightsListItems = {
            [HIGHLIGHT_TYPE.ENVIRONMENTAL_VAR]: [],
            [HIGHLIGHT_TYPE.FILE]: [],
            [HIGHLIGHT_TYPE.URL]: [],
            [HIGHLIGHT_TYPE.USER_NAME]: [],
        };
        [
            ...list.found.map((lf) => ({ ...lf, isFound: true })),
            ...list.not_found.map((lf) => ({ ...lf, isFound: false })),
        ]
            .sort((a, b) => {
                if (a < b) return 1;
                if (a > b) return -1;
                return 0;
            })
            .forEach((h) => {
                result[h.type] = [...result[h.type], h];
            });

        for (let key in result) {
            if (result[key].length === 0) {
                delete result[key];
            }
        }
        return result;
    }, [list]);

    const AccList: AccItem[] = useMemo(() => {
        const renderContent = (items: IHighlightsWithIsFound[]) => {
            return (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '8px',
                        paddingBottom: '40px',
                    }}
                >
                    {items.map((i) => {
                        return (
                            <Highlight
                                onClick={(hl) => {
                                    // if (setCurrentType && setIsOpened) {
                                    //     setCurrentType(hl);
                                    //     setIsOpened(true);
                                    // }
                                    if (setCurrentType) {
                                        setCurrentType(hl);
                                    }
                                    setOpenCellId('heigh_light');
                                }}
                                key={i.name}
                                highlight={i}
                                isFound={i.isFound}
                            />
                        );
                    })}
                </div>
            );
        };
        const icons: { [key: HIGHLIGHT_TYPE | string]: ReactNode } = {
            [HIGHLIGHT_TYPE.ENVIRONMENTAL_VAR]: <VarIcon />,
            [HIGHLIGHT_TYPE.FILE]: <DocIcon />,
            [HIGHLIGHT_TYPE.URL]: <WebIcon />,
            [HIGHLIGHT_TYPE.USER_NAME]: <UserIcon />,
        };
        const keys = Object.keys(sortedItems);
        return keys.map((k) => ({
            icon: icons[k],
            title: k,
            name: k,
            content: renderContent(sortedItems[k]),
        })) as AccItem[];
    }, [sortedItems, setCurrentType, setOpenCellId]);

    return (
        <StyledBlockContainer>
            <StyledTextWrapper>
                <StyledTextBody>
                    Flags - are points of interests for that lab. It could be
                    endpoints, interesting files, environmental variables They
                    give you a sneak peak of what is inside the lab. Finding
                    them will help you to get to know the lab and defend it
                </StyledTextBody>
                <Button
                    view={SHAPE.SECONDARY}
                    onClick={() => {
                        // if (setIsOpened) setIsOpened(true);
                        setOpenCellId('heigh_light');
                    }}
                >
                    Send flags
                </Button>
            </StyledTextWrapper>
            <Accordion
                list={AccList}
                openedItems={openedItems}
                openItem={(name) => setOpenedItems([...openedItems, name])}
                closeItem={(name) =>
                    setOpenedItems([...openedItems].filter((n) => n !== name))
                }
            />
        </StyledBlockContainer>
    );
};
