import { ReactNode, FC } from "react";
import {
  StyledScheme,
  StyledBlock,
  StyledBlockIcon,
  StyledBlockTextWrapp,
  StyledText,
  StyledTitle,
  StyledLineWrapp,
  StyledLineInnerEl,
  StyledLine,
  StyledLineTxtBlock,
  StyledStyledSchemeWrapper,
  StyledSchemeTextList,
  StyledSchemeTextItem,
  StyledSchemeTextItemBullit,
  StyledSchemeTextItemTxt,
} from "./styled";
import actor from "./icons/actor.svg";
import elastic from "./icons/elastic.svg";
import website from "./icons/website.svg";

interface BlockSchemeProps {
  name: "Lab stand" | "User" | "Actor" | "Website" | "Elastic";
  text: string;
  textOnCloud: string;
  bckg: string;
  img: any;
}

const BockScheme: FC<BlockSchemeProps> = (props) => {
  const { bckg, img, name, text, textOnCloud } = props;
  return (
    <StyledBlock bckg={bckg}>
      {name === "User" || name === "Lab stand" ? (
        <StyledLine top={name === "User" ? "50%" : "75%"}>
          <StyledLineTxtBlock>{textOnCloud}</StyledLineTxtBlock>
          <StyledLineWrapp>
            <StyledLineInnerEl />
          </StyledLineWrapp>
        </StyledLine>
      ) : null}

      <StyledBlockIcon>
        <img src={img} alt="actor" style={{ width: "40px", height: "40px" }} />
      </StyledBlockIcon>
      <StyledBlockTextWrapp>
        <StyledTitle>{name}</StyledTitle>
        <StyledText>{text}</StyledText>
      </StyledBlockTextWrapp>
    </StyledBlock>
  );
};

export const SchemeNew = () => {
  const schemeConfig: BlockSchemeProps[] = [
    {
      bckg: "linear-gradient(0deg, rgba(24, 195, 72, 0.4), rgba(24, 195, 72, 0.4)), #2D2A3B",
      img: actor,
      name: "User",
      text: "User who is interacting with lab",
      textOnCloud: "http://127.0.0.1:5000",
    },
    {
      bckg: "linear-gradient(0deg, rgba(223, 181, 30, 0.4), rgba(223, 181, 30, 0.4)), #2D2A3B",
      img: website,
      name: "Lab stand",
      text: "The portal to defend",
      textOnCloud: "Auditbeat is sending data there",
    },
    {
      bckg: "linear-gradient(0deg, rgba(222, 98, 28, 0.4), rgba(222, 98, 28, 0.4)), #2D2A3B",
      img: elastic,
      name: "Elastic",
      text: "ELK stack you configured",
      textOnCloud: "https:\\example.com",
    },
  ];
  return (
    <StyledStyledSchemeWrapper>
      <StyledSchemeTextList>
        <StyledSchemeTextItem>
          <StyledSchemeTextItemBullit color="linear-gradient(0deg, rgba(24, 195, 72, 0.4), rgba(24, 195, 72, 0.4)), #2D2A3B" />
          <StyledSchemeTextItemTxt>
            It is the user of the service we deploy. Ofcourse there are no real
            people using it, but let us think that our lab is being used
          </StyledSchemeTextItemTxt>
        </StyledSchemeTextItem>
        <StyledSchemeTextItem>
          <StyledSchemeTextItemBullit color="linear-gradient(0deg, rgba(223, 181, 30, 0.4), rgba(223, 181, 30, 0.4)), #2D2A3B" />
          <StyledSchemeTextItemTxt>
            The server will have auditbeat configured and will be sending data
            to the server you specified.
          </StyledSchemeTextItemTxt>
        </StyledSchemeTextItem>
        <StyledSchemeTextItem>
          <StyledSchemeTextItemBullit color="linear-gradient(0deg, rgba(222, 98, 28, 0.4), rgba(222, 98, 28, 0.4)), #2D2A3B" />
          <StyledSchemeTextItemTxt>
            ELK you configured should have rules and webhook integration
            working. It should send POST request to the url above when system is
            compromised. Click on the “i” button to learn more.
          </StyledSchemeTextItemTxt>
        </StyledSchemeTextItem>
      </StyledSchemeTextList>
      <StyledScheme>
        {schemeConfig.map((sch, idx) => {
          return (
            <BockScheme
              bckg={sch.bckg}
              img={sch.img}
              key={idx}
              name={sch.name}
              text={sch.text}
              textOnCloud={sch.textOnCloud}
            />
          );
        })}
      </StyledScheme>
    </StyledStyledSchemeWrapper>
  );
};
