import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getTemplateById, labsSlice } from '@/store/reducers/LabsSlice';
import { LoaderDots } from '@/components';
import { LabContent } from './components/LabContent/LabContent';
import { LabContext } from '@/context/labContext';
import { LAB_TYPE } from '@/constants';

export const Lab = memo(function Lab() {
    const { id } = useParams();
    const { LabsSlice } = useAppSelector((state) => state);
    const { lab, isLoadingLab } = LabsSlice;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(labsSlice.actions.reset());
        dispatch(getTemplateById(Number(id)));
    }, [dispatch, id]);

    if (isLoadingLab || !lab) {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '96px',
                }}
            >
                <LoaderDots />
            </div>
        );
    }

    return (
        <LabContext.Provider
            value={{
                labUrl: 'labs',
                labType: LAB_TYPE.DEFAULT,
            }}
        >
            <LabContent key={id} lab={lab} />
        </LabContext.Provider>
    );
});
