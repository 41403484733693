import { FC, useState } from "react"
import { StyledPageContainer } from "../styled";
import { Block, Button, Input } from "@/components";
import { apiClient } from "@/api/apiClient";
import { useNavigate, useParams } from "react-router-dom";

export const WorkshopsGuest: FC = () => {
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const { code: codeParam } = useParams()
    const navigate = useNavigate();
    const sendCode = async () => {
        const data = {
            code: codeParam || code,
            name
        };
        const response = await apiClient.post(
            "/api/v1/workshoplabs/start_lab/",
            data
        );
        navigate(`/workshoplab/${response.data.workshop_lab_id}/`);
    }
    const inlineFormStyles = {
        display: 'flex',
        alignItems: 'end',
        gap: 5,
        padding: '0 25px 10px'
    };
    const formStyles = {
        padding: '0 25px 10px'
    }
    return <StyledPageContainer>
        <Block title="Code">
            <div style={codeParam ? inlineFormStyles : formStyles}>
                {!codeParam && <Input width='400px' value={code} label="code" onChange={setCode}/>}
                <Input width='400px' value={name} label="name" onChange={setName}/>
                <Button style={{
                    flex: '0 0 100px',
                    marginTop: 10
                }} onClick={sendCode}>
                    Run Lab
                </Button>
            </div>
        </Block>
    </StyledPageContainer>
}