import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getVM, vmSlice } from '@/store/reducers/VMSlice';
import { achivementsSlice } from '@/store/reducers/AchievementsSlice';
import { highlightsSlice } from '@/store/reducers/HighlightsSlice';
import { launchCardListSlice } from '@/store/reducers/LaunchCardsSlice';
import { siemSlice } from '@/store/reducers/SiemSlice';
import { healthChecksSlice } from '@/store/reducers/HealthChecksSlice';
import { Outlet } from 'react-router-dom';

import { getAchievements } from '@/store/reducers/AchievementsSlice';
import { getHighlights } from '@/store/reducers/HighlightsSlice';
import { getStats } from '@/store/reducers/LaunchCardsSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { STATUS_LAB } from '@/constants';

import { getSiems } from '@/store/reducers/SiemSlice';
import { getHealthChecks } from '@/store/reducers/HealthChecksSlice';
import { getTimeline } from '@/store/reducers/TimelineSlice';
import { getTasks } from '@/store/reducers/TaskSlice';
import { useStatusPollingRequest } from '@/hooks/useStatusPollingRequest/useStatusPollingRequest';

import { useCurrentLabRequestConfig } from '../Lab/composable/useCurrentLabConfig';

export const LabLaunch = memo(() => {
    const dispatch = useAppDispatch();
    const { uuid } = useParams();
    const { cards, currentCard } = useAppSelector(
        (state) => state.launchCardReducer
    );
    const labRequestConfig = useCurrentLabRequestConfig();
    const { LabsSlice } = useAppSelector((state) => state);
    const { tasks } = useAppSelector((state) => state.TaskSlice);
    const { lab } = LabsSlice;
    useEffect(() => {
        if (uuid) {
            dispatch(getTasks(uuid));
        }
    }, [uuid, dispatch]);
    useEffect(() => {
        dispatch(launchCardListSlice.actions.reset());
        dispatch(highlightsSlice.actions.reset());
        dispatch(healthChecksSlice.actions.resetHealthChecks());
        dispatch(siemSlice.actions.resetSiem());
        dispatch(achivementsSlice.actions.reset());
        dispatch(vmSlice.actions.reset());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSiems());
        if (currentCard?.status === STATUS_LAB.OPERATIONAL) {
            dispatch(getHealthChecks(labRequestConfig));
        }
    }, [currentCard, currentCard?.status, labRequestConfig, dispatch]);

    useEffect(() => {
        if (currentCard?.uuid) {
            dispatch(getHighlights(labRequestConfig));
            dispatch(getStats(labRequestConfig));
            dispatch(getAchievements(labRequestConfig));
            dispatch(getTimeline(labRequestConfig));
            dispatch(getVM(labRequestConfig));
        }
    }, [currentCard?.uuid, labRequestConfig, dispatch]);
    useStatusPollingRequest({ currentCard, cards, lab, tasks });
    return <Outlet />;
});
