import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledAccordion = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid ${baseColors.white(0.1)};
`;

export const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 2px solid ${baseColors.white(0.1)};
`;

export const StyleHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledTitle = styled.span`
    ${typography.title.M};
    text-transform: uppercase;
`;
