import { createContext } from "react";
import { ICardErr } from "../types";

type ErrorCardContextType = {
  cardError: ICardErr;
  setCardError: Function;
};

export const ErrorCardContext = createContext<ErrorCardContextType>(
  {} as ErrorCardContextType
);
