import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const RejectIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM3 8C3 5.23858 5.23858 3 8 3C9.03649 3 9.99932 3.31538 10.7978 3.85542L4.26378 11.3228C3.47762 10.4395 3 9.27553 3 8ZM5.86964 12.5247C6.51592 12.8296 7.23806 13 8 13C10.7614 13 13 10.7614 13 8C13 7.00048 12.7067 6.06947 12.2015 5.28832L5.86964 12.5247Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4 12C4 7.58172 7.58172 4 12 4C13.8569 4 15.5661 4.63268 16.9237 5.69424L6.26285 17.3243C6.22258 17.3682 6.18698 17.4148 6.15601 17.4634C4.81866 16.0334 4 14.1123 4 12ZM7.67924 18.734C8.92539 19.5352 10.4084 20 12 20C16.4183 20 20 16.4183 20 12C20 10.1603 19.379 8.46564 18.3352 7.1142L7.73716 18.6757C7.71848 18.6961 7.69916 18.7155 7.67924 18.734ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
