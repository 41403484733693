import { ReactNode, useEffect, useState } from 'react';
import { OnboardingContext } from './OnboardingContext';
import { useNavigate } from 'react-router-dom';
import { ISteps } from './types';
import { css } from 'styled-components';

type OnboardingProviderProps = {
    children: ReactNode;
};

export const steps: ISteps = {
    1: {
        step: 1,
        title: 'It is the defbox',
        text: 'Welcome to defbox! With this service, you can launch a lab and attempt to defend it against simulated attacks. The page will display a record of all your previous attempts, allowing you to track your progress and identify areas for improvement',
        position: css`
             {
                top: 60px;
                left: calc(50% - 84px);
            }
        `,
        align: 'left',
    },
    2: {
        step: 2,
        title: 'Another page is scoreboard',
        text: 'We also provide a scoreboard page where you can view your performance and compare it to that of other competitors. This page will give you an idea of how you rank among other participants.',
        position: css`
             {
                top: 60px;
                right: calc(50% - 92px);
            }
        `,
        align: 'right',
    },
    3: {
        step: 3,
        title: 'To get started, you need to create a lab',
        text: "To begin, you'll need to create your own lab environment. Fill out all of the required fields on the lab creation form to get started. Get started and see how well you can defend your lab against attacks!",
        position: css`
             {
                top: 60px;
                right: calc(10% + 48px);
            }
        `,
        align: 'right',
    },
    4: {
        step: 4,
        title: 'This is the lab card',
        text: 'This card displays important information about your lab, including the launch date, status, planned destroy date, and lab name. Additionally, the card may also include a timeline of events or a layout of the lab environment',
        position: css`
             {
                top: 198px;
                left: 10%;
            }
        `,
        align: 'left',
    },
    5: {
        step: 5,
        title: 'To get started, you need to create',
        text: 'To do this, fill in all fields of the laboratory creation form',
        position: css`
             {
                top: 198px;
                left: 10%;
            }
        `,
        align: 'left',
    },
    6: {
        step: 6,
        title: "So you set up a lab, what's next?",
        text: '',
        position: css`
             {
                top: 198px;
                left: 10%;
            }
        `,
        align: 'left',
    },
};

export const totalSteps = Object.keys(steps).length;

export const OnboardingProvider = (props: OnboardingProviderProps) => {
    const { children } = props;
    const [isShow, setIsShow] = useState(false);
    const [stepNumber, setStepNumber] = useState<number>(1);
    const navigate = useNavigate();

    const start = (toStep: number | undefined) => {
        setIsShow(true);
        if (toStep) {
            setStepNumber(toStep);
        }
    };
    const end = () => {
        setIsShow(false);
    };

    const next = () => {
        setStepNumber(stepNumber + 1);
    };

    const prev = () => {
        setStepNumber(stepNumber - 1);
    };

    // useEffect(() => {
    //     if (isShow) {
    //         switch (stepNumber) {
    //             case 1:
    //                 navigate('/');
    //                 break;

    //             case 2:
    //                 navigate('/scoreboard/allusers');
    //                 break;

    //             case 3:
    //                 navigate('/');
    //                 break;

    //             case 4:
    //                 navigate('/');
    //                 break;
    //         }
    //     }
    // }, [stepNumber, isShow, navigate]);

    return (
        <OnboardingContext.Provider
            value={{
                isShow,
                start,
                end,
                next,
                step: steps[stepNumber],
                prev,
                numberOfSteps: Object.keys(steps).length,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};
