import { IDropdownMenuItem } from "../../../Dropdown/types";

export const buttonsLabels = {
  info: {
    wide: "Info",
    medium: undefined,
    narrow: "Info",
  },
  destroy: {
    wide: "Destroy",
    medium: undefined,
    narrow: "Destroy",
  },
  attack: {
    wide: "Attack",
    medium: undefined,
    narrow: "Attack",
  },
  restart: {
    wide: "Restart",
    medium: "Restart",
    narrow: "Restart",
  },
};

export const InfoMenu: IDropdownMenuItem[] = [
  {
    id: 4,
    title: "About the card",
  },
  {
    id: 5,
    title: "How to get started",
  },
  {
    id: 6,
    title: "Detect your first attack",
  },
];
