import { IAchievement } from '@/types/achievements';
import { css } from 'styled-components';
import {
    StyledAchivement,
    StyledImg,
    StyledTitle,
    StyledDescription,
    StyledTextContainer,
} from './styled';
import { StyledBlockContainer } from '../../styled';
import ach_active from '@/assets/ach_active.png';
import ach_inactive from '@/assets/ach_inactive.png';

const containerStyle = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
`;

interface IProps {
    achievements: IAchievement[];
}

export const Achivements = ({ achievements }: IProps) => {
    return (
        <StyledBlockContainer styleProps={containerStyle}>
            {achievements.map((a, idx) => (
                <StyledAchivement key={idx}>
                    {/* <StyledImg src={a.received ? ach_active : ach_inactive} /> */}
                    <StyledImg
                        $received={a.received}
                        src={a.picture ?? ach_active}
                    />
                    <StyledTextContainer received={a.received}>
                        <StyledTitle>{a.title}</StyledTitle>
                        <StyledDescription>{a.description}</StyledDescription>
                    </StyledTextContainer>
                </StyledAchivement>
            ))}
        </StyledBlockContainer>
    );
};
