import { FC } from "react";
import { VolumeProps } from "./types";
import { StyledVolume, StyledItem } from "./styled";

export const Volume: FC<VolumeProps> = (props) => {
  const { activeColor, disabledColor, itemsAmount, indexActiveIndex } = props;

  return (
    <StyledVolume>
      {[...Array(itemsAmount)].map((_, idx) => {
        return (
          <StyledItem
            key={idx}
            bgColor={
              indexActiveIndex !== undefined && idx <= indexActiveIndex
                ? activeColor
                : disabledColor
            }
          />
        );
      })}
    </StyledVolume>
  );
};
