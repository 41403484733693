import { useState } from 'react';
import { css } from 'styled-components';
import { typography, baseColors } from '@/constants';
import { useNavigate } from 'react-router-dom';
import {
    StyledMenu,
    StyledMenuItemButton,
    StyledContainerLogo,
    StyledContainer,
    StyledPremium,
    StyledMenuItemPlansFull,
    StyledFooter,
    StyledPrivacyPolicy,
} from './styled';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    SchevronLeftIcon,
    SchevronRightIcon,
    PopUp,
    ProfileSettings,
    Button,
} from '@/components';
import { authSlice } from '@/store';
import { ItemMenu, ItemMenuProfile } from './components';
import { LabsIcon, ScoreboardIcon, Logo, PayIcon } from '../../';

export const MainHeader = () => {
    const { profile } = useAppSelector((state) => state.ProfileSlice);
    const [cut, setCut] = useState(false);
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const renderLogo = () => {
        if (profile?.is_premium) {
            return (
                <StyledContainerLogo
                    to={'/'}
                    style={{
                        marginBottom: '8px',
                    }}
                >
                    <Logo
                        style={{ gap: '8px' }}
                        CSSProp={css`
                            > span {
                                ${typography.title.L}
                            }
                        `}
                        isTextLogo={!cut}
                    />
                    {!cut && <StyledPremium>supporter</StyledPremium>}
                </StyledContainerLogo>
            );
        }
        return (
            <StyledContainerLogo to={'/'}>
                <Logo
                    style={{ gap: '8px' }}
                    color=""
                    CSSProp={css`
                        > span {
                            ${typography.title.L}
                        }
                    `}
                    isTextLogo={!cut}
                />
            </StyledContainerLogo>
        );
    };
    const renderPlans = () => {
        if (!cut && !profile?.is_premium) {
            return (
                <StyledMenuItemPlansFull>
                    <div className="container">
                        <PayIcon variant="M" />
                        <div className="textContainer">
                            <p className="header">
                                You are using the standard plan.
                            </p>
                            <p className="subheader">
                                All labs and SSH access are available to our
                                supporters
                            </p>
                        </div>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/prices');
                            }}
                        >
                            Plans
                        </Button>
                    </div>
                </StyledMenuItemPlansFull>
            );
        }

        return (
            <ItemMenu path="prices" name="Plans" icon={<PayIcon />} cut={cut} />
        );
    };
    return (
        <>
            <PopUp opened={isOpenPopUp}>
                <ProfileSettings onClose={() => setIsOpenPopUp(false)} />
            </PopUp>
            <StyledMenu cut={cut}>
                <StyledContainer>
                    {renderLogo()}
                    <ItemMenu
                        path="labs"
                        name="Labs"
                        icon={<LabsIcon />}
                        cut={cut}
                    />
                    <ItemMenu
                        path="scoreboard"
                        name="Scoreboard"
                        icon={<ScoreboardIcon />}
                        cut={cut}
                    />

                    <ItemMenuProfile
                        name={profile?.user || ''}
                        cut={cut}
                        onEdit={(e) => {
                            e.stopPropagation();
                            setIsOpenPopUp(true);
                        }}
                        onExit={(e) => {
                            e.stopPropagation();
                            dispatch(authSlice.actions.exitAuth());
                            navigate('/auth/signin');
                        }}
                    />
                    {renderPlans()}
                </StyledContainer>
                <StyledFooter>
                    <StyledPrivacyPolicy $isCut={cut}>
                        <p>
                            Read the Defbox
                            {cut ? ' ' : <br />}
                            <a
                                href="https://defbox.ams3.digitaloceanspaces.com/Privacy%20Policy%20Defbox.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </p>
                    </StyledPrivacyPolicy>
                    <StyledMenuItemButton
                        onClick={() => setCut(!cut)}
                        style={{
                            borderTop: `1px solid ${baseColors.white(0.1)}`,
                        }}
                    >
                        <div className="item">
                            {!cut && (
                                <p style={{ width: '100%', textAlign: 'left' }}>
                                    Close
                                </p>
                            )}
                            {cut ? <SchevronRightIcon /> : <SchevronLeftIcon />}
                        </div>
                    </StyledMenuItemButton>
                </StyledFooter>
            </StyledMenu>
        </>
    );
};
