import { Icon } from "../Icon";

interface IconSwordProps {
  figureFill?: string;
  size?: number;
}

export const IconSword = (props: IconSwordProps) => {
  const { figureFill, size } = props;
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M15.6521 2.14047L22.2587 2L22.1182 8.60666L13.9031 16.8218L11.3453 14.2641L19.3416 6.2678L19.3709 4.88787L17.991 4.91724L9.99475 12.9136L7.43694 10.3558L15.6521 2.14047Z"
            fill={figureFill || "#CE224B"}
          />
          <path
            d="M3.83818 10.6411C4.2957 10.1836 5.03748 10.1836 5.495 10.6411L13.7791 18.9252C14.2366 19.3827 14.2366 20.1245 13.7791 20.582C13.3216 21.0395 12.5798 21.0395 12.1223 20.582L3.83818 12.2979C3.38066 11.8404 3.38066 11.0986 3.83818 10.6411Z"
            fill={figureFill || "#CE224B"}
          />
          <path
            d="M7.85884 18.7703L5.64974 16.5612L2.45752 19.7534C1.84749 20.3634 1.84749 21.3525 2.45752 21.9625C3.06754 22.5725 4.05659 22.5725 4.66661 21.9625L7.85884 18.7703Z"
            fill={figureFill || "#CE224B"}
          />
        </>
      }
    />
  );
};
