import styled from "styled-components";
import { baseColors } from "../../../../constants/main";

export const StyledSectionTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 8px;
  gap: 10px;
  border-radius: 4px;
  background: ${baseColors.primary()};
  & span {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
`;

export const StyledTimer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
`;
