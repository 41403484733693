import { useMemo, ReactNode } from 'react';
import { ArrowDownIcon, ArrowUpIcon, ArrowIndtIcon } from '@/components';
import { baseColors } from '@/constants';
import {
    StyledFactoid,
    StyledAsterisk,
    StyledContainer,
    StyledTop,
    StyledValueWrapper,
    StyledValue,
    StyledKey,
    StyledComparedValue,
    StyledDescription,
} from './styled';

interface IProps {
    value?: number | null;
    comparedValue?: number | null;
    isCompared?: boolean;
    positiveResult?: '+' | '-';
    unit: string;
    description: string;
    asterisk?: boolean;
}

export const Factoid = (props: IProps) => {
    const {
        value,
        isCompared,
        comparedValue: inputCompValue,
        positiveResult = '+',
        unit,
        description,
        asterisk,
    } = props;
    const comparedValue =
        inputCompValue && value ? value - inputCompValue : null;

    const Icon = useMemo<ReactNode | null>(() => {
        if (comparedValue && value) {
            if (comparedValue > value) {
                return (
                    <ArrowDownIcon
                        variant="S"
                        fill={
                            positiveResult === '+'
                                ? baseColors.ok()
                                : baseColors.error()
                        }
                    />
                );
            }
            if (comparedValue > value) {
                <ArrowUpIcon
                    variant="S"
                    fill={
                        positiveResult === '-'
                            ? baseColors.ok()
                            : baseColors.error()
                    }
                />;
            }
            return <ArrowIndtIcon variant="S" fill={baseColors.white(0.5)} />;
        }
        return null;
    }, [comparedValue, positiveResult, value]);

    return (
        <StyledFactoid>
            {asterisk && <StyledAsterisk>*</StyledAsterisk>}
            <StyledContainer>
                <StyledTop>
                    <StyledValueWrapper>
                        <StyledValue>
                            {value ? Math.round(value) : '–'}
                        </StyledValue>
                        {isCompared && (
                            <StyledContainer>
                                <StyledComparedValue>
                                    {comparedValue
                                        ? Math.round(comparedValue)
                                        : '–'}
                                </StyledComparedValue>
                                {Icon}
                            </StyledContainer>
                        )}
                    </StyledValueWrapper>
                    <StyledKey>{unit}</StyledKey>
                </StyledTop>
                <StyledDescription>{description}</StyledDescription>
            </StyledContainer>
        </StyledFactoid>
    );
};
