import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ITimeLineElements, ITimeLineInnerElements } from './types'
import { TIMELINE_ELEMENT, timeLineMessage } from './constants'
dayjs.extend(utc)

export const getPixelPosition = (
    endDate: string,
    startDate: string,
    allMinutes: number,
    width: number
) => {
    const percent =
        ((Date.parse(endDate) - Date.parse(startDate)) /
            1000 /
            60 /
            allMinutes) *
        100
    return (percent / 100) * width
}

export const getPosition = (
    endDate: string,
    startDate: string,
    allMinutes: number
) => {
    let percent =
        ((Date.parse(endDate) - Date.parse(startDate)) /
            1000 /
            60 /
            allMinutes) *
        100
    if (percent > 100) {
        percent = 100
    }
    return percent
}

export const getDateFormat = (date: string, format?: string) => {
    return dayjs
        .utc(date)
        .format(format || 'HH:mm:ss')
        .toString()
}

type CreateTimePointType = (args: {
    inputElem: ITimeLineElements
    start: string
    allMinutes: number
    timeLineWidth: number
}) => ITimeLineInnerElements

export const createTimePoint: CreateTimePointType = ({
    inputElem,
    start,
    allMinutes,
    timeLineWidth,
}) => {
    return {
        id: inputElem.id,
        date: inputElem.date,
        type: inputElem.type,
        message: inputElem.message,
        width: 20,
        leftPercent: Math.round(
            getPosition(
                dayjs
                    .utc(inputElem.date)
                    .format('MMMM DD YYYY HH:mm:ss')
                    .toString(),
                start,
                allMinutes
            )
        ),
        leftPixels: Math.round(
            getPixelPosition(
                dayjs
                    .utc(inputElem.date)
                    .format('MMMM DD YYYY HH:mm:ss')
                    .toString(),
                start,
                allMinutes,
                timeLineWidth
            )
        ),
        timepoints: [],
        timepointsReduced: [],
        wordBook: [],
    }
}

let detectedItemDate: string[] = []

export const getReduceTimepointsWithAttack = (
    points: ITimeLineInnerElements[]
) => {
    const firstDetected: ITimeLineInnerElements = {
        id: Date.now(),
        type: TIMELINE_ELEMENT.DETECTED,
        message: timeLineMessage[TIMELINE_ELEMENT.DETECTED],
        date: '',
        width: 20,
        timepoints: [],
        timepointsReduced: [],
        leftPercent: 0,
        leftPixels: 0,
        wordBook: [],
    }
    const red = points.reduce(
        (
            acc: ITimeLineInnerElements[],
            curr: ITimeLineInnerElements,
            index: number,
            arr
        ) => {
            if (curr.type === TIMELINE_ELEMENT.DETECTED) {
                if (detectedItemDate.length <= 1) {
                    detectedItemDate.push(curr.date)
                    if (index === arr.length - 1) {
                        acc = [
                            ...acc,
                            structuredClone({
                                ...firstDetected,
                                date: detectedItemDate[0],
                            }),
                        ]
                        detectedItemDate = []
                        return acc
                    }
                } else {
                    detectedItemDate[detectedItemDate.length - 1] = curr.date
                    if (index === arr.length - 1) {
                        acc = [
                            ...acc,
                            structuredClone({
                                ...firstDetected,
                                date: `${detectedItemDate[0]} to ${
                                    detectedItemDate[
                                        detectedItemDate.length - 1
                                    ]
                                }`,
                            }),
                        ]
                        detectedItemDate = []
                        return acc
                    }
                }
            }
            if (curr.type === TIMELINE_ELEMENT.ATTACK) {
                if (detectedItemDate.length === 1) {
                    acc = [
                        ...acc,
                        structuredClone({
                            ...firstDetected,
                            date: detectedItemDate[0],
                        }),
                        curr,
                    ]
                    detectedItemDate = []
                    return acc
                }
                if (detectedItemDate.length > 1) {
                    acc = [
                        ...acc,
                        structuredClone({
                            ...firstDetected,
                            date: `${detectedItemDate[0]} to ${
                                detectedItemDate[detectedItemDate.length - 1]
                            }`,
                        }),
                        curr,
                    ]
                    detectedItemDate = []
                    return acc
                }
                if (detectedItemDate.length === 0) {
                    acc = [...acc, curr]
                    return acc
                }
            }
            return acc
        },
        []
    )
    return red
}

interface IGenerateExampleConfig {
    dateStart: Date
    dateEnd: Date
    amountDetect: number
    attacks: number[]
}

type GenerateExampleType = (
    config: IGenerateExampleConfig
) => ITimeLineElements[]

interface ITmpTimeLineElements extends ITimeLineElements {
    timestamp: number
}

export const generateExampleState: GenerateExampleType = ({
    dateEnd,
    dateStart,
    amountDetect,
    attacks,
}) => {
    const setDates = new Set()
    type GetRandomDateType = (max: number, min: number) => number
    const getRandomDate: GetRandomDateType = (max, min) => {
        const rundom = Math.floor(min + Math.random() * (max + 1 - min))
        if (setDates.has(rundom)) {
            getRandomDate(max, min)
        }
        setDates.add(rundom)
        return rundom
    }
    let state: ITmpTimeLineElements[] = []

    for (let i = 0; i < amountDetect; i++) {
        const timestamp = getRandomDate(dateEnd.getTime(), dateStart.getTime())
        state.push({
            id: i,
            type: TIMELINE_ELEMENT.DETECTED,
            message: 'jhghg',
            timestamp: timestamp,
            // date: new Date(timestamp).toString(),
            date: dayjs(timestamp).toString(),
            // .format('MMMM DD YYYY HH:mm:ss')
            // .toString(),
            width: 0,
        })
    }

    state = state.sort((a, b) => a.timestamp - b.timestamp)

    let result: ITimeLineElements[] = []
    result = state.map((t, idx) => ({
        id: idx,
        type: attacks.includes(idx)
            ? TIMELINE_ELEMENT.ATTACK
            : TIMELINE_ELEMENT.DETECTED,
        message: t.message,
        date: t.date,
        width: t.width,
    }))
    result[0].type = TIMELINE_ELEMENT.CREATED
    result[state.length - 1].type = TIMELINE_ELEMENT.DESTROY
    return result
}

export const getAllMinutes = (start: string, end: string) => {
    return Math.floor((Date.parse(end) - Date.parse(start)) / 1000 / 60)
}
