import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledTable = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledRow = styled.div`
    display: grid;
    grid-template-columns: 134px 134px 1fr;
    gap: 12px;
`;

export const StyledCell = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledHeader = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledValue = styled.p`
    ${typography.text.M};
    color: ${baseColors.white()};
`;

export const StyledNoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 0;
`;
