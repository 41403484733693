import { FC, useEffect } from "react"
import { StyledPageContainer } from "../styled";
import { useAppDispatch } from "@/hooks/redux";
import { useAppSelector } from '../../hooks/redux';
import { getWorkshopById } from "@/store/reducers/WorkshopsSlice";
import { useParams } from "react-router-dom";
import { Block, CopyItem, TextItem } from "@/components";
import { getDateUTC } from '../../utils/date';
import { StyledList } from "./styled";
import { getTemplateById } from "@/store/reducers/LabsSlice";
import { LaunchedLabs } from "./components/LaunchedLabs";

export const Workshop: FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { WorkshopsSlice, LabsSlice } = useAppSelector(state => state);
    useEffect(() => {
        dispatch(getWorkshopById(id!));
    }, [dispatch, id]);
    useEffect(() => {
        if(WorkshopsSlice.workshop?.lab_template) {
            dispatch(getTemplateById(WorkshopsSlice.workshop?.lab_template));
        }
    }, [WorkshopsSlice.workshop]);
    
    const link = `${window.location.origin}/workshops/invite/${WorkshopsSlice.workshop?.code}`;
    return <StyledPageContainer>
        <Block title="Info">
            <StyledList>
                <TextItem keyText="Lab Template" valueText={LabsSlice.lab?.name}/>
                <TextItem keyText="Student Number" valueText={WorkshopsSlice.workshop?.students_count.toString() || ""}/>
                <TextItem keyText="Start Date" valueText={getDateUTC(WorkshopsSlice.workshop?.start_date!, 'DD.MM.YYYY, HH:mm')}/>
                <TextItem keyText="Finish Date" valueText={getDateUTC(WorkshopsSlice.workshop?.finish_date!, 'DD.MM.YYYY, HH:mm')}/>
                <CopyItem keyText="Invite Code" valueText={WorkshopsSlice.workshop?.code || ""}></CopyItem>
                <CopyItem
                    keyText="Invite Link"
                    valueText={link || ""}
                    valueSize="M"
                ></CopyItem>
            </StyledList>
        </Block>
        <Block title="Launched Labs">
            <LaunchedLabs />
        </Block>
        </StyledPageContainer>
}