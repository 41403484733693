import { StyledItem, StyledHeadline, StyledText } from "../styled";
import { IconPlay, IconStop, IconInfo } from "../../../../../Icons/index";
export const MoreHint = () => {
  return (
    <StyledItem width={270} style={{ gap: "24px" }}>
      <div style={{ display: "flex", gap: "8px" }}>
        <StyledItem width={270} style={{ textAlign: "right" }}>
          <StyledHeadline>Start attack</StyledHeadline>
          <StyledText>
            To start an attack, click on this button. It will trigger an attack
            that you must catch
          </StyledText>
        </StyledItem>
        <IconPlay size={20} bckgFill="#817F89" />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <StyledItem width={270} style={{ textAlign: "right" }}>
          <StyledHeadline>Destroy lab</StyledHeadline>
        </StyledItem>
        <IconStop size={20} />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <StyledItem width={270} style={{ textAlign: "right" }}>
          <StyledHeadline>Information</StyledHeadline>
        </StyledItem>
        <IconInfo size={20} bckgFill="#817F89" />
      </div>
    </StyledItem>
  );
};
