import {
  StyledWrapper,
  StyledTop,
  StyledBottom,
  StyledItem,
  StyledHeadline,
  StyledText,
  StyledStatusWrapper,
  StyledStatusCircle,
  StyledTopLine,
  StyledBottomLine,
} from "../styled";

export const PlannedDestroyHint = () => {
  return (
    <StyledItem width={270}>
      <StyledHeadline>Planned destroy date</StyledHeadline>
      <StyledText>
        the lab is availible only before that date. We will start destroying lab
        at the time specified
      </StyledText>
    </StyledItem>
  );
};
