import { storyStatusText } from '@/constants/labs';
import { ILifeCycleTexts } from './types';
import { STORY_STATUS } from '@/constants';

export enum TEXT_TYPE {
    SIMPLE = 'SIMPLE',
    MARKED = 'MARKED',
}

export const lifeCycleTexts: ILifeCycleTexts[] = [
    {
        title: storyStatusText.DEPLOY,
        status: STORY_STATUS.DEPLOY,
        text: [
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'We are creating virtual machines and installing software to these machines.',
            },
        ],
    },
    {
        title: storyStatusText.PROTECT,
        status: STORY_STATUS.PROTECT,
        text: [
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'Take a look into elastic (creds and link in the info section ), ',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'see the logs',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' in ELK,',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'find flags',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' .When you feel familiar with the lab - ',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'click the attack button',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' to start the attack',
            },
        ],
    },
    {
        title: storyStatusText.HACK,
        status: STORY_STATUS.HACK,
        text: [
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'The attack is happening right now, you can see what is happening in the timeline section.',
            },
        ],
    },
    {
        title: storyStatusText.INVESTIGATE,
        status: STORY_STATUS.INVESTIGATE,
        text: [
            {
                type: TEXT_TYPE.MARKED,
                text: 'Find attack ',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' in the logs.',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'Create a rule',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'in elastic.',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' Click on the redeploy button to ',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: ' recreate the lab ',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'to see if your rule is working.',
            },
        ],
    },
    {
        title: storyStatusText.REDEPLOY,
        status: STORY_STATUS.REDEPLOY,
        text: [
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'Try to redeploy the lab, to try to defend it from the same ( or another ) attack. ',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'Elastic will',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'be the same.',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' To redeploy the elastic destroy the lab and start it again.',
            },
        ],
    },
    {
        title: storyStatusText.DESTROY,
        status: STORY_STATUS.DESTROY,
        text: [
            {
                type: TEXT_TYPE.SIMPLE,
                text: 'The lab is destroyed. ',
            },
            {
                type: TEXT_TYPE.MARKED,
                text: 'Click "Start a lab"',
            },
            {
                type: TEXT_TYPE.SIMPLE,
                text: ' to create a new lab.',
            },
        ],
    },
];
