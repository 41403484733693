import { useState, ReactNode, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { addHighlight } from '@/store/reducers/HighlightsSlice';
import {
    Select,
    Input,
    Button,
    VarIcon,
    DocIcon,
    WebIcon,
    UserIcon,
    Alert,
} from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { HIGHLIGHT_TYPE, LAB_TYPE } from '@/constants';
import { ToastContext } from '@/components/Toast/ToastContext';

import { StyledForm } from '../../styled';

interface IProps {
    currentType?: HIGHLIGHT_TYPE;
    labType?: LAB_TYPE;
    setCurrentType?: (ct: HIGHLIGHT_TYPE | undefined) => void;
}

export const AddHightlightForm = ({
    currentType,
    setCurrentType,
    labType = LAB_TYPE.DEFAULT,
}: IProps) => {
    const { highlights, errorAddHighlight, addHighlightFetching } =
        useAppSelector((state) => state.HighlightsSlice);
    const dispatch = useAppDispatch();
    const { uuid } = useParams();

    const { setIsShow, setTitle } = useContext(ToastContext);

    const [hType, setHType] = useState<HIGHLIGHT_TYPE | undefined>(currentType);
    const [value, setValue] = useState<string>('');

    const isDisabled = !Boolean(hType) || value === '' || addHighlightFetching;

    const clickHandler = async () => {
        if (isDisabled || !hType || !uuid) return;
        try {
            const { meta } = await dispatch(
                addHighlight({
                    uuidWithBase: { uuid, basePath: labType },
                    data: { type: hType, value: value },
                })
            );
            if (meta.requestStatus === 'fulfilled') {
                setTitle('Flag has been added');
                setIsShow(true);
            }
        } catch (e) {}
    };

    const icons: { [key: HIGHLIGHT_TYPE | string]: ReactNode } = {
        [HIGHLIGHT_TYPE.ENVIRONMENTAL_VAR]: <VarIcon variant="S" />,
        [HIGHLIGHT_TYPE.FILE]: <DocIcon variant="S" />,
        [HIGHLIGHT_TYPE.URL]: <WebIcon variant="S" />,
        [HIGHLIGHT_TYPE.USER_NAME]: <UserIcon variant="S" />,
    };

    let arr: HIGHLIGHT_TYPE[] = [];
    const HLOptions: IDropdownMenuItem[] = highlights
        ? highlights.not_found.reduce((acc, curr) => {
              if (!arr.includes(curr.type)) {
                  acc = [
                      ...acc,
                      {
                          id: curr.type,
                          title: curr.type,
                          icon: icons[curr.type],
                      },
                  ];
                  arr.push(curr.type);
              }
              return acc;
          }, [] as IDropdownMenuItem[])
        : [];
    useEffect(() => {
        if (!hType) {
            setHType(currentType);
        }
    }, [hType, currentType]);

    return (
        <StyledForm>
            {errorAddHighlight.code && (
                <Alert
                    variant={VARIANT.ERROR}
                    code={errorAddHighlight.code}
                    message={errorAddHighlight.message}
                />
            )}
            <Select
                label="Type"
                options={HLOptions}
                defaultValue={currentType}
                onChange={(i) => setHType(i.id as unknown as HIGHLIGHT_TYPE)}
            />
            <Input label="Value" onChange={(str) => setValue(str)} />
            <Button onClick={clickHandler} disabled={isDisabled}>
                Add flag
            </Button>
        </StyledForm>
    );
};
