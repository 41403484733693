import { AppDispatch } from '../store';
import { isAxiosError } from 'axios';
import { authSlice } from './AuthSlice';
import { gameSlice } from './GameSlice';
import { modalSlice } from './ModalSlice';
import { launchCardListSlice } from './LaunchCardsSlice';
import { errorSlice } from './ErrorSlice';
import { scoreboardSlice } from './ScoreboardSlice';
import { STATUS_CARD } from '../../components/Card/constants';
import { ERRORS } from '../../types/error';
import { IUser } from '../../types/user';
import { IScaleItem } from '../../components/Slider/types';
import { difficultyItems } from '../../components/Forms/CreateLabWizard/steps/ThreeStep/constants';
import { apiClient } from '../../api/apiClient';

export const exitAuth = () => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.exitAuth());
};

// export const refreshAuth =
//     (refresh: string) => async (dispatch: AppDispatch) => {
//         try {
//             apiClient
//                 .post('api/token/refresh/', {
//                     refresh: refresh,
//                 })
//                 .then((resp) => {
//                     dispatch(
//                         authSlice.actions.authFetchingRefresh(resp.data.access)
//                     );
//                 });
//         } catch (err) {
//             dispatch(authSlice.actions.exitAuth());
//         }
//     };

// Modal

export const openModal =
    (
        contentModal:
            | 'create_lab'
            | 'create_lab_wizard'
            | 'auth'
            | 'reg'
            | 'attack_button_pressed'
            | 'join_team'
            | 'deploy_elk'
            | 'create_workshop'
            | undefined
    ) =>
    (dispatch: AppDispatch) => {
        dispatch(modalSlice.actions.open());
        dispatch(modalSlice.actions.addContent(contentModal));
        switch (contentModal) {
            case 'auth':
                dispatch(
                    errorSlice.actions.errorFetching({
                        space: ERRORS.AUTH,
                        error: {
                            code: undefined,
                            message: undefined,
                        },
                    })
                );
                break;
            case 'create_lab':
                dispatch(
                    errorSlice.actions.errorFetching({
                        space: ERRORS.CREATE_LAUNCH,
                        error: {
                            code: undefined,
                            message: undefined,
                        },
                    })
                );
                break;
        }
    };

export const closeModal = () => (dispatch: AppDispatch) => {
    dispatch(modalSlice.actions.close());
    dispatch(modalSlice.actions.addContent(undefined));
};

// Cards

export const getDataInTheThirdStep = async (setCurrentData: Function) => {
    try {
        apiClient.get('api/v1/profiles/').then((response) => {
            const data: IUser = response.data[0];
            setCurrentData({
                elastic_host: data.elastic_host ? data.elastic_host : '',
                elastic_user: data.elastic_user ? data.elastic_user : '',
                elastic_password: data.elastic_password
                    ? data.elastic_password
                    : '',
                difficulty: '0',
            });
        });
    } catch (err) {
        console.log('err get data step');
    }
};

interface IResponseDifficulties {
    description: string;
    difficulty: number;
}

type ResponseDifficultiesData = IResponseDifficulties[];

export const getDifficulties = async (setDifficalties: Function) => {
    try {
        apiClient.get('api/v1/labtemplates').then((response) => {
            const data: ResponseDifficultiesData = response.data[0].attackers;
            const difficulties: IScaleItem[] = data.map((elem, idx) => {
                return {
                    id: elem.difficulty,
                    value: elem.difficulty,
                    description: elem.description,
                    color: difficultyItems[idx].color,
                };
            });
            setDifficalties(difficulties);
        });
    } catch (err) {
        console.log(err);
    }
};

export const deployElasticAndCreateLaunch =
    (labId: string | number, difficulty: string) =>
    async (dispatch: AppDispatch) => {
        try {
            apiClient
                .post(
                    `api/v1/labtemplates/${String(labId)}/run_lab_with_siem/`,
                    {
                        data: JSON.stringify({
                            difficulty: String(difficulty),
                        }),
                    }
                )
                .then(() => {
                    dispatch(closeModal());
                    dispatch(getLaunchCards());
                });
        } catch (err) {
            if (isAxiosError(err)) {
                dispatch(
                    errorSlice.actions.errorFetching({
                        space: ERRORS.CREATE_LAUNCH,
                        error: {
                            code: err.request.status,
                            message: err.message,
                        },
                    })
                );
            }
        }
    };

export const createLaunch =
    (host: string, user: string, pass: string, difficulty: string) =>
    async (dispatch: AppDispatch) => {
        dispatch(launchCardListSlice.actions.loaderFetching());
        dispatch(gameSlice.actions.errorFetcing(null));
        try {
            apiClient
                .post('api/v1/profiles/', {
                    elastic_host: host,
                    elastic_user: user,
                    elastic_password: pass,
                })
                .then(() => {
                    apiClient
                        .post('api/v1/profiles/create_lab/', {
                            difficulty: String(difficulty),
                        })
                        .then(() => {
                            dispatch(closeModal());
                            dispatch(getLaunchCards());
                        });
                });
        } catch (err) {
            if (isAxiosError(err)) {
                dispatch(
                    errorSlice.actions.errorFetching({
                        space: ERRORS.CREATE_LAUNCH,
                        error: {
                            code: err.request.status,
                            message: err.message,
                        },
                    })
                );
            }
        }
    };

export const getLaunchCards = () => async (dispatch: AppDispatch) => {
    dispatch(
        errorSlice.actions.errorFetching({
            space: ERRORS.GET_LAUNCH_PAGE,
            error: {
                code: undefined,
                message: undefined,
            },
        })
    );
    dispatch(launchCardListSlice.actions.loaderFetching());
    try {
        const response = apiClient.get('/api/v1/labs/');
        if ((await response).status === 200) {
            dispatch(
                launchCardListSlice.actions.cardFetching((await response).data)
            );
        }
    } catch (err) {
        if (isAxiosError(err)) {
            dispatch(
                errorSlice.actions.errorFetching({
                    space: ERRORS.GET_LAUNCH_PAGE,
                    error: {
                        code: err.request.status,
                        message: err.message,
                    },
                })
            );
            dispatch(
                launchCardListSlice.actions.errorFetching(err.request.status)
            );
        }
    }
};

export const runAttack =
    (uuid: string, errorFunc: Function) => async (dispatch: AppDispatch) => {
        errorFunc({ code: undefined, message: undefined });
        try {
            apiClient.get(`/api/v1/labs/${uuid}/run_attack/`).then(() => {
                dispatch(getLaunchCards());
            });
        } catch (err) {
            if (isAxiosError(err)) {
                errorFunc({ code: err.request.status, message: err.message });
            }
        }
    };

export const destroyLaunch =
    (uuid: string, errorFunc: Function) => async (dispatch: AppDispatch) => {
        errorFunc({ code: undefined, message: undefined });
        try {
            apiClient.get(`api/v1/labs/${uuid}/destroy_lab/`).then(() => {
                dispatch(getLaunchCards());
            });
        } catch (err) {
            if (isAxiosError(err)) {
                errorFunc({ code: err.request.status, message: err.message });
            }
        }
    };

export const checkStatusLaunchCard =
    (status: STATUS_CARD) => async (dispatch: AppDispatch) => {
        try {
            apiClient.get('api/v1/labs/').then((resp) => {
                if (resp.data[0].status !== status) {
                    dispatch(
                        launchCardListSlice.actions.cardFetching(resp.data)
                    );
                }
            });
        } catch (err) {
            if (isAxiosError(err)) {
                dispatch(
                    launchCardListSlice.actions.errorFetching(
                        err.request.status
                    )
                );
            }
        }
    };

//scoreboard

export const getScoreboard =
    (teamId: string) => async (dispatch: AppDispatch) => {
        dispatch(
            scoreboardSlice.actions.scorBErrorFetching({
                code: undefined,
                message: undefined,
            })
        );

        dispatch(scoreboardSlice.actions.loaderFetching());
        try {
            apiClient.get(`api/v1/scoreboard/?team=${teamId}`).then((resp) => {
                dispatch(scoreboardSlice.actions.sListFetching(resp.data));
            });
        } catch (err) {
            if (isAxiosError(err)) {
                dispatch(
                    scoreboardSlice.actions.scorBErrorFetching({
                        code: err.request.status,
                        message: err.message,
                    })
                );
            }
        }
    };

export const checkTeam = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(
            scoreboardSlice.actions.teamErrorFetching({
                code: undefined,
                message: undefined,
            })
        );
        apiClient
            .get(`/api/v1/profiles/`)
            .then((response) => response.data[0])
            .then((data: IUser) => {
                if (data.team) {
                    dispatch(scoreboardSlice.actions.teamFetching(data.team));
                } else {
                    dispatch(scoreboardSlice.actions.teamFetching(''));
                }
            });
    } catch (err) {
        if (isAxiosError(err)) {
            dispatch(
                scoreboardSlice.actions.teamErrorFetching({
                    code: err.request.status,
                    message: err.message,
                })
            );
        }
    }
};

export const createTeam = () => async (dispatch: AppDispatch) => {
    dispatch(
        scoreboardSlice.actions.teamErrorFetching({
            code: undefined,
            message: undefined,
        })
    );
    dispatch(scoreboardSlice.actions.loaderFetching());
    try {
        apiClient.get('api/v1/profiles/create_team/').catch(() => {});

        await apiClient
            .get('/api/v1/profiles/')
            .then((response) => response.data[0])
            .then((data: IUser) => {
                if (data.team !== null && data.team !== undefined) {
                    dispatch(getScoreboard(data.team));
                    dispatch(scoreboardSlice.actions.teamFetching(data.team));
                } else {
                    dispatch(scoreboardSlice.actions.teamFetching(''));
                    dispatch(getScoreboard(''));
                }
            });
    } catch (err) {
        if (isAxiosError(err))
            dispatch(
                scoreboardSlice.actions.teamErrorFetching({
                    code: err.request.status,
                    message: err.message,
                })
            );
    }
};

export const quitTeam = () => async (dispatch: AppDispatch) => {
    dispatch(scoreboardSlice.actions.loaderFetching());
    try {
        apiClient
            .get('api/v1/profiles/quit_team/')
            .then(() => dispatch(scoreboardSlice.actions.teamFetching('')));
    } catch (err) {
        if (isAxiosError(err))
            dispatch(
                scoreboardSlice.actions.teamErrorFetching({
                    code: err.request.status,
                    message: err.message,
                })
            );
    }
};

export const joinTeam = (teamId: string) => async (dispatch: AppDispatch) => {
    dispatch(scoreboardSlice.actions.joinTeamLoaderFetching(true));
    dispatch(
        scoreboardSlice.actions.teamErrorFetching({
            code: undefined,
            message: undefined,
        })
    );
    try {
        apiClient
            .post('api/v1/profiles/join_team/', {
                team: teamId,
            })
            .then(() => {
                dispatch(scoreboardSlice.actions.joinTeamLoaderFetching(false));
                dispatch(createTeam());
                dispatch(closeModal());
            });
    } catch (err) {
        if (isAxiosError(err)) {
            dispatch(scoreboardSlice.actions.joinTeamLoaderFetching(false));
            dispatch(
                scoreboardSlice.actions.teamErrorFetching({
                    code: err.request.status,
                    message: err.message,
                })
            );
        }
    }
};
