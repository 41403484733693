import { memo, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { SideCell } from './components/SideCell/SideCell';
import { CloseIcon } from '@/components';
import { ISideCell } from './types';
import { SideBarContext } from './context';
import { StyledOverlay, StyledCloseArea } from './styled';

export const SideBar = memo(() => {
    const { cells, openedCellId, setOpenCellId } = useContext(SideBarContext);
    const clickHandler = (id: ISideCell['id']) => {
        if (id === openedCellId) {
            setOpenCellId(undefined);
            return;
        }
        setOpenCellId(id);
    };
    const orderId: ISideCell['id'][] = useMemo(() => {
        const cellIds = cells.map((c) => c.id);
        if (!!openedCellId) {
            const cellIndex = cellIds.findIndex((id) => id === openedCellId);

            if (cellIndex > 0) {
                if (cellIndex > 0) {
                    const deletedId = cellIds.splice(cellIndex, 1);
                    return [...deletedId, ...cellIds];
                }
            }
        }
        return cellIds;
    }, [cells, openedCellId]);

    const isOpened = !!openedCellId;

    const renderModal = () => {
        return (
            <StyledOverlay isOpened={isOpened}>
                {isOpened && (
                    <StyledCloseArea
                        onClick={() => {
                            setOpenCellId(undefined);
                        }}
                    >
                        <CloseIcon />
                    </StyledCloseArea>
                )}
                {cells.map((cell) => {
                    const isCellOpened = cell.id === openedCellId;
                    const order = orderId.findIndex((oId) => oId === cell.id);

                    return (
                        <SideCell
                            {...cell}
                            order={order}
                            isOpenedBar={isOpened}
                            isOpened={isCellOpened}
                            onClick={clickHandler}
                            key={cell.id}
                        />
                    );
                })}
            </StyledOverlay>
        );
    };
    if (isOpened) {
        return createPortal(renderModal(), document.body);
    }
    return renderModal();
});
