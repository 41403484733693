import {
    StyledWrapper,
    StyledTop,
    StyledBottom,
    StyledItem,
    StyledHeadline,
    StyledText,
    StyledStatusWrapper,
    StyledStatusCircle,
    StyledTopLine,
    StyledBottomLine,
} from '../styled';

import {
    statusCardMessage,
    statusCardText,
    statusCardColor,
} from '../../../../constants';

export const LabNameHint = () => {
    return (
        <StyledItem width={270}>
            <StyledHeadline>Lab name unique lab identifier</StyledHeadline>
        </StyledItem>
    );
};
