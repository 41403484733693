import { FC, ReactNode, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { StyledBckg, StyledPopUp } from './styled';

const modalRootElement = document.querySelector('#modal');

interface PopUpProps {
    children?: ReactNode;
    opened?: boolean;
}

export const PopUp: FC<PopUpProps> = ({ children, opened }) => {
    useEffect(() => {
        if (opened) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
    }, [opened]);

    return createPortal(
        <AnimatePresence>
            {opened && (
                <StyledBckg
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                >
                    <StyledPopUp
                        as={motion.div}
                        initial={{ opacity: 0, y: '-64px' }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ delay: 0.1, duration: 0.6 }}
                    >
                        {children}
                    </StyledPopUp>
                </StyledBckg>
            )}
        </AnimatePresence>,
        modalRootElement!
    );
};
