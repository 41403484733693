import { useState, useMemo } from 'react';
import { TextItem, EyeIcon, CloseEyeIcon, IconButton } from '@/components';
import { Tooltip } from '@/components';
import { ToastContext } from '@/components/Toast/ToastContext';
import { useContext } from 'react';

interface IProps {
    password?: string;
}

export const PasswordItem = ({ password }: IProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const { setIsShow, setTitle } = useContext(ToastContext);

    const visibleText = useMemo<string | undefined>(() => {
        if (password) {
            navigator.clipboard.writeText(password);
            if (isVisible) {
                return password;
            }
            return [...Array(password.length)].fill('#').join('');
        }
        return undefined;
    }, [password, isVisible]);
    return (
        <TextItem
            cut
            keyText="Password"
            valueText={visibleText}
            icon={
                <IconButton
                    onClick={() => {
                        setIsVisible(!isVisible);
                        setTitle('Copied to clipboard');
                        setIsShow(true);
                    }}
                    disabled={!password}
                    icon={
                        <Tooltip
                            text={isVisible ? 'Hide' : 'Show'}
                            enabled={!!password}
                        >
                            {isVisible ? (
                                <EyeIcon variant="S" />
                            ) : (
                                <CloseEyeIcon variant="S" />
                            )}
                        </Tooltip>
                    }
                />
            }
        />
    );
};
