import { memo, FC } from 'react';
import { ScoreDetail } from '@/components';
import { IScoreboardItemDetail } from '@/types';
import { useAppSelector } from '@/hooks/redux';

export const ScoreboardDetail = memo(function () {
    const { scoreDetail, isLoadingScoreDetail, isLoadingScoreList } =
        useAppSelector((state) => state.scoreboardSlice);

    interface IProps {
        items?: IScoreboardItemDetail;
        isLoader?: boolean;
    }
    const RenderScoreboardDetail: FC<IProps> = memo(function ({
        items,
        isLoader,
    }) {
        return (
            <>
                {!!items ? (
                    <ScoreDetail {...items} isLoader={isLoader} />
                ) : (
                    <div />
                )}
            </>
        );
    });
    return (
        <RenderScoreboardDetail
            items={scoreDetail}
            isLoader={isLoadingScoreDetail || isLoadingScoreList}
        />
    );
});
