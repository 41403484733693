import { CARD_FIELDS } from './constants'

export const widthFieldGenerator = (field: CARD_FIELDS) => {
    switch (field) {
        case CARD_FIELDS.STATUS:
            return 240
        case CARD_FIELDS.LAB_NAME:
            return 100
        case CARD_FIELDS.PLANED_DESTROY_DATA:
            return 128
        case CARD_FIELDS.TIME_TO_DESTROY:
            return 144
        case CARD_FIELDS.POINTS:
            return 64
        case CARD_FIELDS.DIFFICULTY:
            return 64
    }
}
