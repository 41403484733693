/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { TooltipStaticProps } from "./types";
import {
  StyledTooltipStatic,
  StyledTooltipStaticContainer,
  StyledTextContainer,
  StyledTitle,
  StyledText,
  StyledButtons,
} from "./styled";
import { IconButton } from "../IconButton/IconButton";
import { IconClose } from "../Icons/index";
import { Button } from "../Button/Button";
import { baseColors } from "../../constants/main";

export const TooltipStatic = (props: TooltipStaticProps) => {
  const {
    children,
    icon,
    title,
    text,
    buttons,
    isShow: inputIsShow,
    onClose,
  } = props;
  const [isShow, setIsShow] = useState<boolean>(inputIsShow);
  const tooltipStaticRef = useRef<HTMLDivElement>(null);
  const tooltip_static = document.querySelector("#tooltip_static");
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    setIsShow(inputIsShow);
  }, [setIsShow, inputIsShow]);

  useEffect(() => {
    if (tooltipStaticRef.current?.children[0]) {
      const wait = setTimeout(() => {
        setTop(
          tooltipStaticRef.current!.children[0].getBoundingClientRect().y +
            tooltipStaticRef.current!.children[0].clientHeight +
            12
        );
        setLeft(
          tooltipStaticRef.current!.children[0].getBoundingClientRect().x -
            400 +
            tooltipStaticRef.current!.children[0].clientWidth
        );
        clearTimeout(wait);
      }, 0);
    }
  }, [
    setTop,
    setLeft,
    tooltipStaticRef,
    tooltipStaticRef.current?.children[0],
    tooltipStaticRef.current?.children[0].getBoundingClientRect().y,
    tooltipStaticRef.current?.children[0].getBoundingClientRect().x,
    tooltipStaticRef.current?.children[0].clientWidth,
    tooltipStaticRef.current?.children[0].clientHeight,
  ]);

  const renderTooltipStatic: ReactNode = createPortal(
    <StyledTooltipStatic left={left} top={top}>
      {icon}
      <StyledTooltipStaticContainer>
        <StyledTextContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledText>{text}</StyledText>
        </StyledTextContainer>

        {buttons && (
          <StyledButtons>
            {buttons.map((btn) => (
              <Button key={btn.id} view={btn.view} onClick={btn.action}>
                {btn.title}
              </Button>
            ))}
          </StyledButtons>
        )}
      </StyledTooltipStaticContainer>
      <IconButton
        icon={<IconClose figureFill={baseColors.bckg()} />}
        onClick={onClose}
      />
    </StyledTooltipStatic>,
    tooltip_static!
  );

  return (
    <div ref={tooltipStaticRef} className="TooltipStatic">
      {children}
      {isShow && renderTooltipStatic}
    </div>
  );
};
