import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
    IError,
    IAuthState,
    FetchAuthFromInviteArgs,
    FetchAuthResponse,
    FetchAuthArgs,
    ITokensData,
} from '@/types';
import { modalSlice } from './ModalSlice';
import { apiClient } from '../../api/apiClient';

export const fetchAuth = createAsyncThunk<
    FetchAuthResponse,
    FetchAuthArgs,
    { rejectValue: IError }
>('auth/fetchAuth', async function (args, { rejectWithValue, dispatch }) {
    const { name, pass, navigate } = args;
    try {
        const response = await apiClient.post('api/token/', {
            username: name,
            password: pass,
        });
        const data = await response.data;
        if (navigate) {
            navigate('/');
        }
        dispatch(modalSlice.actions.close());
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const refreshAuth = createAsyncThunk<
    string,
    { refresh: string },
    { rejectValue: IError }
>('auth/refreshAuth', async function (args, { rejectWithValue }) {
    const { refresh } = args;
    try {
        const response = await apiClient.post('/api/token/refresh/', {
            refresh: refresh,
        });
        const data = await response.data.access;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const fetchAuthFromInvite = createAsyncThunk<
    FetchAuthResponse,
    FetchAuthFromInviteArgs,
    { rejectValue: IError }
>('auth/fetchAuthFromInvite', async function (args, { rejectWithValue }) {
    const { name, pass, goToMain } = args;
    try {
        const response = await apiClient.post(
            '/api/token/',
            {
                username: name,
                password: pass,
            },
            {
                headers: {
                    Authorization: 'Basic xxxxxxxxxxxxxxxxxxx',
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = await response.data;
        goToMain();
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

const initialState: IAuthState = {
    auth: false,
    refresh: undefined,
    access: undefined,
    access_lifetime: 0,
    refresh_lifetime: 0,
    start_time: 0,
    isLoading: false,
    error: {
        code: undefined,
        message: undefined,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authFetching(state) {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        authFetchingAccess(state, action: PayloadAction<ITokensData>) {
            state.auth = true;
            state.isLoading = false;
            state.refresh = action.payload.refresh;
            state.access = action.payload.access;
            state.access_lifetime = action.payload.access_lifetime;
            state.refresh_lifetime = action.payload.refresh_lifetime;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        authFetchingError(state, action: PayloadAction<IError>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        refreshStartTime(state, action: PayloadAction<number>) {
            state.start_time = action.payload;
            if (typeof localStorage.getItem('token') === 'string') {
                const tokensData: ITokensData = JSON.parse(
                    localStorage.getItem('token') as string
                );
                localStorage.setItem(
                    'token',
                    JSON.stringify({
                        ...tokensData,
                        start_time: action.payload,
                    } as ITokensData)
                );
            }
        },

        refreshAuthSaccess(state, action: PayloadAction<string>) {
            state.access = action.payload;
            if (typeof localStorage.getItem('token') === 'string') {
                const tokensData: ITokensData = JSON.parse(
                    localStorage.getItem('token') as string
                );
                localStorage.setItem(
                    'token',
                    JSON.stringify({
                        ...tokensData,
                        access: action.payload,
                    } as ITokensData)
                );
            }
        },
        exitAuth(state) {
            localStorage.clear();
            state.auth = false;
            state.access = undefined;
            state.refresh = undefined;
            state.isLoading = false;
            state.access_lifetime = 0;
            state.refresh_lifetime = 0;
            state.start_time = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthFromInvite.pending, (state) => {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(fetchAuthFromInvite.fulfilled, (state, action) => {
            state.auth = true;
            state.isLoading = false;
            state.refresh = action.payload.refresh;
            state.access = action.payload.access;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(fetchAuthFromInvite.rejected, (state, action) => {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });

        builder.addCase(fetchAuth.pending, (state) => {
            state.isLoading = true;
            state.start_time = Date.now();
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(fetchAuth.fulfilled, (state, action) => {
            const startTime = Date.now();
            state.auth = true;
            state.isLoading = false;
            state.refresh = action.payload.refresh;
            state.access = action.payload.access;
            state.access_lifetime = Number(
                Number(action.payload.access_lifetime).toFixed()
            );
            state.refresh_lifetime = Number(
                Number(action.payload.refresh_lifetime).toFixed()
            );
            state.start_time = startTime;
            localStorage.setItem(
                'token',
                JSON.stringify({
                    refresh: action.payload.refresh,
                    access: action.payload.access,
                    access_lifetime: Number(
                        Number(action.payload.access_lifetime).toFixed()
                    ),
                    refresh_lifetime: Number(
                        Number(action.payload.refresh_lifetime).toFixed()
                    ),
                    start_time: startTime,
                })
            );
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(fetchAuth.rejected, (state, action) => {
            localStorage.clear();
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });

        builder.addCase(refreshAuth.pending, (state) => {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(refreshAuth.fulfilled, (state, action) => {
            state.access = action.payload;

            if (typeof localStorage.getItem('token') === 'string') {
                const tokensData: ITokensData = JSON.parse(
                    localStorage.getItem('token') as string
                );
                localStorage.setItem(
                    'token',
                    JSON.stringify({
                        ...tokensData,
                        access: action.payload,
                        start_time: Date.now(),
                    } as ITokensData)
                );
            }
        });
        builder.addCase(refreshAuth.rejected, (state, action) => {
            localStorage.clear();
            state.auth = false;
            state.access = undefined;
            state.refresh = undefined;
            state.access_lifetime = 0;
            state.refresh_lifetime = 0;
            state.start_time = 0;
            state.isLoading = false;
            state.error = action.payload as IError;
        });
    },
});

export default authSlice.reducer;
