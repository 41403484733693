import { FC, useEffect, useState, useContext } from 'react';
import { ErrorCardContext } from '../../context/ErrorCardContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
    STATUS_CARD,
    statusCardColor,
    statusCardMessage,
} from '../../constants';
import { getTimeRemaining, setFormatTimer } from './utils';
import { destroyLaunch } from '../../../../store/reducers/ActionCreators';
import { StyledTimer, StyledSectionTime } from './styled';
import { StyledKeyValue, StyledTextKey } from '../../styled';
import { LoaderDots } from '../../../Loader/LoaderDots/LoaderDots';

interface CardTimerProps {
    endTime: string | null;
    status: STATUS_CARD;
    size?: 'M' | 'S';
    uuid: string;
    scrolled?: boolean;
}
dayjs.extend(utc);

export const CardTimer: FC<CardTimerProps> = ({
    endTime,
    status,
    uuid,
    scrolled,
    size = 'M',
}) => {
    const dispatch = useAppDispatch();
    const { access } = useAppSelector((state) => state.authReducer);

    const formattedDate = dayjs
        .utc(endTime)
        .format('MMMM DD YYYY HH:mm:ss')
        .toString();
    const today = dayjs.utc().format('MMMM DD YYYY HH:mm:ss').toString();
    const [days, setDays] = useState<number>(99);
    const [hours, setHours] = useState<number>(99);
    const [minutes, setMinutes] = useState<number>(99);
    const [seconds, setSeconds] = useState<number>(99);

    useEffect(() => {
        const secondsInterval = setInterval(() => {
            if (status === STATUS_CARD.OPERATIONAL) {
                const t = getTimeRemaining(formattedDate, today);
                setDays(t.days);
                setHours(t.hours);
                setMinutes(t.minutes);
                setSeconds(t.seconds);
            } else {
                clearInterval(secondsInterval);
            }
        }, 1000);
        return () => {
            clearInterval(secondsInterval);
        };
    });

    const { setCardError } = useContext(ErrorCardContext);

    useEffect(() => {
        if (status === STATUS_CARD.OPERATIONAL) {
            if (days + hours + minutes + seconds <= 0) {
                dispatch(destroyLaunch(uuid, setCardError));
            }
        }
    }, [days, hours, minutes, seconds, status, dispatch, setCardError, uuid]);

    const Waiting: FC = () => {
        return (
            <StyledKeyValue>
                {!scrolled && <StyledTextKey>Time to destroy</StyledTextKey>}
                <LoaderDots color={statusCardColor[status]}></LoaderDots>
            </StyledKeyValue>
        );
    };

    const Deactivated: FC = () => (
        <StyledKeyValue>
            <StyledTextKey>Time to destroy</StyledTextKey>
            <StyledKeyValue>"Date destroy"</StyledKeyValue>
        </StyledKeyValue>
    );

    const Timer: FC = () => {
        return (
            <StyledKeyValue>
                {!scrolled && (
                    <StyledTextKey>{statusCardMessage[status]}</StyledTextKey>
                )}
                <StyledTimer>
                    {/* <StyledSectionTime>
            <span>{setFormatTimer(days)}</span>
          </StyledSectionTime> */}
                    <StyledSectionTime>
                        <span>{setFormatTimer(hours)}</span>
                    </StyledSectionTime>
                    <StyledSectionTime>
                        <span>{setFormatTimer(minutes)}</span>
                    </StyledSectionTime>
                    <StyledSectionTime>
                        <span>{setFormatTimer(seconds)}</span>
                    </StyledSectionTime>
                </StyledTimer>
            </StyledKeyValue>
        );
    };

    return (
        <div>
            {status === STATUS_CARD.DEPLOY && <Waiting />}
            {status === STATUS_CARD.SET_UP_VM && <Waiting />}
            {status === STATUS_CARD.OPERATIONAL && <Timer />}
            {status === STATUS_CARD.TEAR_DOWN && <Waiting />}
            {status === STATUS_CARD.DEACTIVATED && <Deactivated />}
        </div>
    );
};
