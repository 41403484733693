import styled from 'styled-components';
import { shadow } from '@/constants';
import {
    baseColors,
    typography,
    statusLabColor,
    STATUS_LAB,
    scrollStyles,
} from '../../constants';

export const StyledPageContainer = styled.div`
    padding: 0 8px;
    display: grid;
    grid-template-columns: 234px 1fr;
    box-sizing: border-box;
    height: 100%;
`;

export const StyledLaunchesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100vh - 57px);
    overflow-y: auto;
    box-sizing: border-box;
    padding-top: 8px;
    padding-right: 4px;
    ${scrollStyles};
`;

export const StyledCard = styled.div`
    text-decoration: none;
    display: flex;
    gap: 8p;
    box-sizing: border-box;
    padding: 8px;
    background: ${baseColors.levelTwo(0)};
    border: none;
    cursor: pointer;
    &:hover {
        ${shadow.levelTwo.levelOne};
        background: ${baseColors.levelThree()};
    }
    &.active {
        background: ${baseColors.levelTwo()};
        border-radius: 4px;
        border: 1px solid ${baseColors.white(0.1)};
    }
    &:hover > div > p:nth-child(1) {
        opacity: 1;
    }
    &.active > div > p:nth-child(1) {
        opacity: 1;
    }
`;

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledStatus = styled.div<{ status: STATUS_LAB }>`
    width: 20px;
    height: 20px;
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 20px;
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        background: ${({ status }) => statusLabColor[status]};
    }
`;

export const StyledCardTitle = styled.p`
    ${typography.text.M};
    opacity: 0.7;
`;
export const StyledCardTextStatus = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`;
