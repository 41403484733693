import { STATUS_LAB } from '@/constants';
import { useContext, useEffect } from 'react';
import { useAppDispatch } from '../redux';
import { checkStatus } from '@/store/reducers/LaunchCardsSlice';
import { getTasks } from '@/store/reducers/TaskSlice';
import { checkAchievements } from '@/store/reducers/AchievementsSlice';
import { getHealthChecks } from '@/store/reducers/HealthChecksSlice';
import { getVM } from '@/store/reducers/VMSlice';
import { ILab, ILabTeamplate, ITask } from '@/types';
import { checkTimeline } from '@/store/reducers/TimelineSlice';
import { LabContext } from '@/context/labContext';

export const useStatusPollingRequest = ({
    currentCard,
    lab,
    cards,
    tasks,
}: {
    currentCard?: ILab;
    cards: ILab[];
    lab?: ILabTeamplate;
    tasks?: ITask[];
}) => {
    const dispatch = useAppDispatch();
    const { labUrl } = useContext(LabContext);
    useEffect(() => {
        const healthCheckStatus = [
            STATUS_LAB.SET_UP_VM,
            STATUS_LAB.OPERATIONAL,
            STATUS_LAB.TEAR_DOWN,
        ];
        const statusesForChecking = [
            STATUS_LAB.DEPLOY,
            STATUS_LAB.SET_UP_VM,
            STATUS_LAB.TEAR_DOWN,
        ];

        const taskInterval = setInterval(() => {
            const status = cards[0].status;
            const labRequestConfig = {
                uuid: cards[0].uuid,
                basePath: labUrl,
            };
            const isCheckTaskStatus = () => {
                if (status === STATUS_LAB.OPERATIONAL)
                    if (!!tasks && tasks.length)
                        for (let i = 0; i < tasks.length; i++)
                            if (tasks[i].in_progress === true) return true;
                return false;
            };
            if (isCheckTaskStatus()) {
                dispatch(getTasks(labRequestConfig.uuid));
            } else {
                clearInterval(taskInterval);
            }
        }, 5000);

        const interval = setInterval(() => {
            if (cards.length && lab) {
                const status = cards[0].status;
                const labRequestConfig = {
                    uuid: cards[0].uuid,
                    basePath: labUrl,
                };
                // const storyStatus = cards[0].story_status;
                if (status !== STATUS_LAB.DEACTIVATED) {
                    dispatch(checkStatus(lab?.name));
                }

                if (status === STATUS_LAB.OPERATIONAL) {
                    dispatch(checkAchievements(labRequestConfig));
                    if (currentCard && currentCard.attack_button_pressed) {
                        dispatch(checkTimeline(labRequestConfig));
                    }
                }
                if (healthCheckStatus.includes(status)) {
                    dispatch(getHealthChecks(labRequestConfig));
                }
                if (
                    currentCard?.uuid &&
                    [...statusesForChecking, ...healthCheckStatus].includes(
                        currentCard.status
                    )
                ) {
                    dispatch(getVM(labRequestConfig));
                }
                if (status === STATUS_LAB.DEACTIVATED) {
                    clearInterval(interval);
                }
            }
        }, 10000);

        return () => {
            clearInterval(interval);
            clearInterval(taskInterval);
        };
    }, [cards, dispatch, lab, currentCard, labUrl, tasks]);
};
