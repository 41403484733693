import styled from 'styled-components'
import { typography } from '../../../../constants/main'

export const StyledWrapper = styled.div`
    padding-bottom: 160px;
`

export const StyledTop = styled.div`
    padding: 0 48px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    box-sizing: border-box;
    z-index: 1;
`

export const StyledBottom = styled.div`
    padding: 0 48px;
    padding-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    box-sizing: border-box;
`

export const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const StyledTopLine = styled.div`
    width: 1px;
    height: calc(100% + 268px);
    background: rgba(255, 255, 255, 0.12);
    position: absolute;
    left: 32px;
    top: 8px;
    &::after {
        content: '';
        display: block;
        width: 8px;
        position: absolute;
        height: 1px;
        background: rgba(255, 255, 255, 0.12);
        top: 0;
        left: 0;
    }
    &::before {
        content: '';
        display: block;
        width: 8px;
        position: absolute;
        height: 1px;
        background: rgba(255, 255, 255, 0.12);
        top: 100%;
        left: 0;
    }
`

export const StyledBottomLine = styled.div`
    width: 1px;
    height: 271px;
    background: rgba(255, 255, 255, 0.12);
    position: absolute;
    left: 32px;
    top: -210px;
    &::after {
        content: '';
        display: block;
        width: 8px;
        position: absolute;
        height: 1px;
        background: rgba(255, 255, 255, 0.12);
        top: 0;
        left: 0;
    }
    &::before {
        content: '';
        display: block;
        width: 8px;
        position: absolute;
        height: 1px;
        background: rgba(255, 255, 255, 0.12);
        top: 100%;
        left: 0;
    }
`

export const StyledText = styled.p`
    max-width: 460px;
    ${typography.text.M}
`

export const StyledHeadline = styled.span`
    ${typography.text.M};
    font-weight: 600;
    max-width: 460px;
`

export const StyledImgWrapper = styled.div`
    border-radius: 8px;
    overflow: hidden;
`
