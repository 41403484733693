import { FC, useMemo, useState, useContext, ReactNode, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ErrorCardContext } from './context/ErrorCardContext';
import { ToastContext } from '../Toast/ToastContext';
import { WindowWidthContext } from '../../context/generalContext';
import { LaunchCardProps } from './types';
import { getCurrentDifficultyConfig } from '../../utils/launch';
import { CardTimer } from './components/CardTimer/CardTimer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    STATUS_CARD,
    statusCardColor,
    CARD_FIELDS,
    WIDTH_CARD,
    statusCardMessage,
} from './constants';
import { IconSword } from '../Icons/index';
import { useLaunchCard } from './hooks/useLaunchCard';
import { LoaderDots } from '../Loader/LoaderDots/LoaderDots';
import { TimeLine } from './components/TimeLineOne/TimeLine';
import {
    StyledCard,
    StyledTextKey,
    StyledTextValue,
    StyledKeyValue,
    StyledMain,
    StyledStatus,
    StyledCircle,
    StyledSeparator,
    StyledMainWrapper,
    StyledAttackButtonPressed,
    StyledFooterContainer,
    StyledFooterDivider,
    StyledFooterContentContainer,
    StyledFooterTitle,
    StyledCounterContainer,
    StyledCounter,
} from './styled';
import { MoreInfo } from './components/MoreInfo/MoreInfo';
import { Alert } from '../Alert/Alert';
import { VARIANT } from '../Alert/types';
import { baseColors } from '../../constants/main';
import { SchemeNew } from './components/SchemeNew/SchemeNew';
import { ActionBar } from './components/ActionBar/ActionBar';
import {
    animationCardBlock,
    IShowBlockArgs,
} from '../../constants/animation/card';
import { Volume } from '../Volume/Volume';
import { useOnboardingHint } from './hooks/OnboardingHint/useOnboardingHint';
import { CardFooter } from './components/CardFooter/CardFooter';
import { SIEMInformation } from './components/SIEMInformation/SIEMInformation';
import { getCountersForTimeLineHeader } from './utils';
dayjs.extend(utc);

export const NewLaunchCard: FC<LaunchCardProps> = (props) => {
    const {
        status,
        deploy_date,
        planned_destroy_date,
        uuid,
        // access,
        // refresh,
        points,
        scrolled = false,
        lab_url,
        isStaticallyOpen,
        delay,
        index,
        difficulty,
        onboardingConfig,
        attack_button_pressed,
        name,
    } = props;

    const {
        isExpand,
        setIsExpand,
        destroyCard,
        timeLineList,
        // isFooter,
        cardErr,
        setCardErr,
        attackHandler,
        startOnboarding,
        siemData,
    } = useLaunchCard({
        status,
        deploy_date,
        planned_destroy_date,
        uuid,
        // access,
        // refresh,
        delay,
        index,
        name,
        isStaticallyOpen,
    });

    const [isAnimateCounter, setIsAnimateCounter] = useState(false);
    const [timeLineListLength, setTimeLineListLength] = useState(0);

    useEffect(() => {
        if (timeLineList.length > timeLineListLength) {
            setIsAnimateCounter(true);
            const wait = setTimeout(() => {
                setIsAnimateCounter(false);
                setTimeLineListLength(timeLineList.length);
                clearTimeout(wait);
            }, 1000);
        }
    }, [
        timeLineList,
        setIsAnimateCounter,
        setTimeLineListLength,
        timeLineListLength,
    ]);

    const { setIsShow, setTitle } = useContext(ToastContext);
    const windowWidth = useContext(WindowWidthContext);

    const widthActionBar = useMemo(() => {
        if (windowWidth) {
            if (windowWidth >= 1400) {
                return WIDTH_CARD.WIDE;
            }
            if (windowWidth < 1400 && windowWidth >= 1100) {
                return WIDTH_CARD.MEDIUM;
            }
        }
        return WIDTH_CARD.NARROW;
    }, [windowWidth]);

    const [isHover, setIsHover] = useState(false);

    const configAnimationCard: IShowBlockArgs = {
        index,
        delay,
        x: '-40px',
        duration: 0.3,
    };

    const { hint } = useOnboardingHint({
        isShow: !!isStaticallyOpen,
        config: onboardingConfig,
    });

    const renderFooterContent: ReactNode = useMemo(() => {
        return (
            <>
                <StyledFooterDivider
                    style={{ boxSizing: 'border-box', margin: '0 48px' }}
                />
                {!isStaticallyOpen && status === STATUS_CARD.OPERATIONAL && (
                    <StyledFooterContentContainer>
                        <MoreInfo url={lab_url ? lab_url : ''} uuid={uuid} />
                    </StyledFooterContentContainer>
                )}
                {isStaticallyOpen && isStaticallyOpen === 'opened' && (
                    <StyledFooterContentContainer>
                        <MoreInfo url={lab_url ? lab_url : ''} uuid={uuid} />
                    </StyledFooterContentContainer>
                )}

                {(status === STATUS_CARD.SET_UP_VM ||
                    status === STATUS_CARD.DEPLOY) && (
                    <StyledFooterContentContainer
                        style={{
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            paddingBottom: '20px',
                        }}
                    >
                        <SchemeNew />
                    </StyledFooterContentContainer>
                )}

                {status === STATUS_CARD.TEAR_DOWN && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            padding: '12px',
                        }}
                    >
                        <LoaderDots color={baseColors.primary()} />
                    </div>
                )}
            </>
        );
    }, [status, lab_url, isStaticallyOpen, uuid]);

    return (
        <ErrorCardContext.Provider
            value={{ cardError: cardErr, setCardError: setCardErr }}
        >
            <StyledCard
                as={motion.div}
                custom={configAnimationCard}
                variants={animationCardBlock}
                initial="hide"
                animate="show"
                isHover={isHover}
                scrolled={scrolled}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {cardErr.code !== undefined && (
                    <>
                        <Alert
                            variant={VARIANT.ERROR}
                            code={cardErr.code ? cardErr.code : 0}
                            message={cardErr.message ? cardErr.message : ''}
                        />
                    </>
                )}
                <StyledMain>
                    <StyledMainWrapper>
                        <StyledStatus>
                            {hint('STATUS')}
                            <StyledCircle color={statusCardColor[status]} />
                            <StyledKeyValue field={CARD_FIELDS.STATUS}>
                                {!scrolled && (
                                    <StyledTextValue
                                        style={{ fontWeight: 800 }}
                                    >
                                        {`Launch ${dayjs
                                            .utc(deploy_date)
                                            .format('MM DD YYYY HH:mm:ss')
                                            .toString()} (UTC)`}
                                    </StyledTextValue>
                                )}
                                <StyledTextValue>
                                    {statusCardMessage[status]}
                                </StyledTextValue>
                            </StyledKeyValue>
                        </StyledStatus>
                        <StyledKeyValue
                            field={CARD_FIELDS.LAB_NAME}
                            style={{ display: 'block' }}
                        >
                            {hint('LAB_NAME')}
                            {!scrolled && (
                                <StyledTextKey>Lab name</StyledTextKey>
                            )}
                            <StyledTextValue style={{ marginTop: '4px' }}>
                                {name}
                            </StyledTextValue>
                        </StyledKeyValue>
                        <StyledSeparator
                            style={
                                scrolled
                                    ? { height: '20px' }
                                    : { height: '44px' }
                            }
                        />
                        <StyledKeyValue field={CARD_FIELDS.PLANED_DESTROY_DATA}>
                            {hint('PLANED_DESTROY_DATA')}
                            {!scrolled && (
                                <StyledTextKey>
                                    Planned destroy date
                                </StyledTextKey>
                            )}
                            <StyledTextValue>
                                {status === STATUS_CARD.DEPLOY ||
                                status === STATUS_CARD.SET_UP_VM ? (
                                    <LoaderDots
                                        color={statusCardColor[status]}
                                    />
                                ) : (
                                    dayjs
                                        .utc(planned_destroy_date)
                                        .format('MM DD YYYY HH:mm:ss')
                                        .toString()
                                )}
                            </StyledTextValue>
                        </StyledKeyValue>

                        {difficulty !== undefined && (
                            <StyledKeyValue field={CARD_FIELDS.DIFFICULTY}>
                                {hint('DIFFICULTY')}
                                {!scrolled && (
                                    <StyledTextKey>Difficulty</StyledTextKey>
                                )}
                                <Volume
                                    activeColor={
                                        getCurrentDifficultyConfig(
                                            String(difficulty)
                                        ).activeColor
                                    }
                                    disabledColor={
                                        getCurrentDifficultyConfig(
                                            String(difficulty)
                                        ).disabledColor
                                    }
                                    itemsAmount={
                                        getCurrentDifficultyConfig(
                                            String(difficulty)
                                        ).itemsAmount
                                    }
                                    indexActiveIndex={
                                        getCurrentDifficultyConfig(
                                            String(difficulty)
                                        ).indexActiveIndex
                                    }
                                />
                            </StyledKeyValue>
                        )}
                        {status === STATUS_CARD.DEACTIVATED && (
                            <StyledKeyValue field={CARD_FIELDS.POINTS}>
                                <StyledTextKey>Points</StyledTextKey>
                                <StyledTextValue>{points}</StyledTextValue>
                            </StyledKeyValue>
                        )}
                        {status !== STATUS_CARD.DEACTIVATED && (
                            <div style={{ position: 'relative' }}>
                                {hint('TIME_TO_DESTROY')}
                                <CardTimer
                                    endTime={planned_destroy_date}
                                    status={status}
                                    uuid={uuid}
                                    scrolled={scrolled}
                                />
                            </div>
                        )}
                    </StyledMainWrapper>
                    <ActionBar
                        attack_button_pressed={attack_button_pressed}
                        onboardingConfig={onboardingConfig}
                        isStatically={!!isStaticallyOpen}
                        widthBar={widthActionBar}
                        status={status}
                        isHover={isHover}
                        scrolled={scrolled}
                        callBack={{
                            info: (numberOfStep) =>
                                !isStaticallyOpen
                                    ? startOnboarding(numberOfStep)
                                    : null,
                            attack: () => {
                                if (!isStaticallyOpen) {
                                    attackHandler();
                                    setIsShow(true);
                                    setTitle('Attack on the server');
                                }
                            },
                            destroy: () =>
                                !isStaticallyOpen ? destroyCard() : null,
                            restart: () => null,
                        }}
                    />
                </StyledMain>
                <StyledAttackButtonPressed>
                    {status === STATUS_CARD.OPERATIONAL &&
                        attack_button_pressed && (
                            <>
                                <IconSword size={20} />
                                <span>{`Attack ${dayjs
                                    .utc(attack_button_pressed)
                                    .format('MM DD YYYY HH:mm:ss')
                                    .toString()} UTC`}</span>
                            </>
                        )}
                </StyledAttackButtonPressed>

                <StyledFooterContainer isHover={isHover}>
                    {status !== STATUS_CARD.DEACTIVATED &&
                        status !== STATUS_CARD.TEAR_DOWN && (
                            <>
                                <CardFooter
                                    isStaticallyOpen={isStaticallyOpen}
                                    header="Information"
                                >
                                    {typeof siemData !== 'undefined' && (
                                        <SIEMInformation
                                            interfaceURL={
                                                siemData.interface_url
                                            }
                                            password={siemData.password}
                                            userName={siemData.username}
                                            title="SIEM"
                                        />
                                    )}
                                    {isStaticallyOpen === 'opened' && (
                                        <SIEMInformation
                                            interfaceURL={'example-url.com'}
                                            password={'example-passworw'}
                                            userName={'username'}
                                            title="SIEM"
                                        />
                                    )}
                                    {renderFooterContent}
                                </CardFooter>
                            </>
                        )}

                    {status === STATUS_CARD.OPERATIONAL && (
                        <StyledFooterDivider style={{ width: '100%' }} />
                    )}

                    {status === STATUS_CARD.DEACTIVATED ||
                    status === STATUS_CARD.OPERATIONAL ? (
                        <CardFooter
                            isStaticallyOpen={isStaticallyOpen}
                            header={
                                timeLineList && timeLineList.length ? (
                                    <>
                                        <StyledFooterTitle>
                                            Timeline
                                        </StyledFooterTitle>
                                        <StyledCounterContainer>
                                            <span>Detects</span>
                                            <StyledCounter
                                                as={motion.div}
                                                initial={
                                                    isAnimateCounter && {
                                                        boxShadow: `0px 0px 15px 0px ${baseColors.warning(
                                                            0
                                                        )}`,
                                                    }
                                                }
                                                animate={
                                                    isAnimateCounter
                                                        ? {
                                                              boxShadow: `0px 0px 15px 0px ${baseColors.warning(
                                                                  0.6
                                                              )}`,
                                                          }
                                                        : {
                                                              boxShadow: `0px 0px 15px 0px ${baseColors.warning(
                                                                  0
                                                              )}`,
                                                          }
                                                }
                                                color={
                                                    getCountersForTimeLineHeader(
                                                        timeLineList
                                                    ).detect.color
                                                }
                                            >
                                                <span>
                                                    {
                                                        getCountersForTimeLineHeader(
                                                            timeLineList
                                                        ).detect.amount
                                                    }
                                                </span>
                                            </StyledCounter>
                                        </StyledCounterContainer>
                                        <StyledCounterContainer>
                                            <span>Attacks</span>
                                            <StyledCounter
                                                as={motion.div}
                                                initial={
                                                    isAnimateCounter && {
                                                        boxShadow: `0px 0px 15px 0px ${baseColors.error(
                                                            0
                                                        )}`,
                                                    }
                                                }
                                                animate={
                                                    isAnimateCounter
                                                        ? {
                                                              boxShadow: `0px 0px 15px 0px ${baseColors.error(
                                                                  0.6
                                                              )}`,
                                                          }
                                                        : {
                                                              boxShadow: `0px 0px 15px 0px ${baseColors.error(
                                                                  0
                                                              )}`,
                                                          }
                                                }
                                                color={
                                                    getCountersForTimeLineHeader(
                                                        timeLineList
                                                    ).attack.color
                                                }
                                            >
                                                <span>
                                                    {
                                                        getCountersForTimeLineHeader(
                                                            timeLineList
                                                        ).attack.amount
                                                    }
                                                </span>
                                            </StyledCounter>
                                        </StyledCounterContainer>
                                    </>
                                ) : (
                                    'Timeline'
                                )
                            }
                            onClickSchevron={() => setIsExpand(!isExpand)}
                        >
                            <TimeLine
                                elements={timeLineList}
                                isHover={isHover}
                                status={status}
                            />
                        </CardFooter>
                    ) : null}
                </StyledFooterContainer>
            </StyledCard>
        </ErrorCardContext.Provider>
    );
};
