import { Icon } from "../Icon";

interface IconStopProps {
  figureFill?: string;
  bckgFill?: string;
  size?: number;
}

export const IconStop = ({ size, bckgFill, figureFill }: IconStopProps) => {
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            fill={bckgFill || "#817F89"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 9H10C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9ZM10 6C7.79086 6 6 7.79086 6 10V14C6 16.2091 7.79086 18 10 18H14C16.2091 18 18 16.2091 18 14V10C18 7.79086 16.2091 6 14 6H10Z"
            fill={figureFill || "white"}
          />
        </>
      }
    />
  );
};
