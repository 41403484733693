import styled, { CSSProp } from 'styled-components';
import { baseColors } from '../constants/main';

export const StyledPage = styled.div`
    position: relative;
    padding: 0 10%;
    max-width: 1920px;
    min-width: 940px;
    box-sizing: border-box;
`;

export const StyledPageContainer = styled.div<{ cssProp?: CSSProp }>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: ${baseColors.levelOne()};
    border-left: 1px solid ${baseColors.white(0.1)};
    width: 100%;
    min-height: 100vh;
    position: relative;
    ${({ cssProp }) => cssProp};
`;

export const StyledContainer = styled.div`
    position: relative;
    padding-right: 52px;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
`;
