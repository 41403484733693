import { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { StyledTimePoint, StyledCounter, StyledTimePointDot } from '../../styled';
import {
  StyledMore,
  StyledMoreItem,
  StyledMoreItemTextContainer,
  StyledMoreItemTitle,
  StyledMoreItemMessage,
  StyledMoreItemDotContainer,
  StyledMoreTooltipContent,
  StyledTooltipTitle,
  StyledTooltipText
} from './styled'
import { Footnote } from '../Footnote/Footnote';
import { ITimePointProps } from './types';
import { TIMELINE_ELEMENT } from '../../constants';
import { Tooltip } from '../../../../../Tooltip/Tooltip';
import { PLACEMENTS } from '../../../../../../hooks/usePopper/constants'
import { usePopper } from '../../../../../../hooks/usePopper/usePopper'
import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';
import { useCombinedRefs } from '../../../../../../hooks/useCombinedRef';
import { IconStop, IconPlay } from '../../../../../Icons/index'
import { baseColors } from '../../../../../../constants/main';
import { getDateFormat } from '../../utils';

export const TimePoint = ({ point, heightsPoints, idx, lastIdx}: ITimePointProps) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const { referenceRef, popperRef } = usePopper({
      enabled: isShowMore,
      placement: PLACEMENTS.autoRight,
      flip: true,
      offset: 4,
      arrowPadding: 0,
      matchWidth: false,
    });

    const dropdownRef = useCombinedRefs<HTMLDivElement | null>(referenceRef);

    useOutsideClick(dropdownRef, (e) => {
      if(isShowMore) setIsShowMore(false)
    });

    const renderTimePointDot = ({view}:{view: TIMELINE_ELEMENT}) => {
      switch(view){
        case TIMELINE_ELEMENT.CREATED:
          return <IconPlay size={20} bckgFill={baseColors.board()} figureFill={baseColors.primary()}/>;
        case TIMELINE_ELEMENT.DESTROY:
          return <IconStop size={20} bckgFill={baseColors.board()} figureFill={baseColors.primary()}/>;
        default:
          return (
            <StyledTimePointDot 
              view={view}
              style={{opacity: isActive ? 1 : 0.5}}
            />
          )
      }
        
    }

    const renderMore = () => {
     if(isShowMore){
      return createPortal(
        <StyledMore ref={popperRef}>
          {
            point.timepoints.length 
              ? point.timepoints.map((tp, index) => {
                    return (
                      <StyledMoreItem key={index}>
                        <StyledMoreItemDotContainer>
                          <StyledTimePointDot 
                            view={tp.type}
                            style={{ position: "static" }}
                          />
                        </StyledMoreItemDotContainer>
                        <StyledMoreItemTextContainer>
                          <StyledMoreItemTitle>
                            {tp.message}
                          </StyledMoreItemTitle>
                          <StyledMoreItemMessage>
                            {getDateFormat(tp.date)}
                          </StyledMoreItemMessage>
                        </StyledMoreItemTextContainer>
                      </StyledMoreItem>
                    )
                  })
              : (
                <StyledMoreItem>
                  <StyledMoreItemDotContainer>
                    <StyledTimePointDot 
                      view={point.type}
                      style={{ position: "static" }}
                    />
                  </StyledMoreItemDotContainer>
                  <StyledMoreItemTextContainer>
                    <StyledMoreItemTitle>
                      {point.message}
                    </StyledMoreItemTitle>
                    <StyledMoreItemMessage>
                      {getDateFormat(point.date)}
                    </StyledMoreItemMessage>
                  </StyledMoreItemTextContainer>
                </StyledMoreItem>
              )
          }
        </StyledMore>,
        window.document.body
      )
     }
     return null
    }

    const tooltipTetx: {title: string, text: string} = useMemo(() => {
      switch (point.type){
        case TIMELINE_ELEMENT.ATTACK: 
          return {
            title: 'Attack',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.CREATED:
          return {
            title: 'Lab created',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.DESTROY:
          return {
            title: 'Lab destroy',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.DETECTED:
          return {
            title: 'You detect',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.GROUP_ATTACK:
          return {
            title: 'Multiply attacks',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.GROUP_DETECTED:
          return {
            title: 'Multiply detects',
            text: 'Show more'
          }
        case TIMELINE_ELEMENT.GROUP_DETECTED_WITH_ATTACK:
          return {
            title: 'Multiply detects and attacks',
            text: 'Show more'
          }
        default: 
          return {
            title: '', text: ''
          }
      }
    }, [point]);

    const renderTooltipContent = () => (
      <StyledMoreTooltipContent>
        <StyledTooltipTitle>
          {tooltipTetx.title}
        </StyledTooltipTitle>
        <StyledTooltipText>
          {tooltipTetx.text}
        </StyledTooltipText>
      </StyledMoreTooltipContent>
      
    )

    return (
      <>
        {renderMore()}
        <Tooltip
          style={{
            position: 'absolute',
            left: `calc(${point.leftPercent}% - 10px)`}}
            placement={PLACEMENTS.right}
            text='Show more'
            enabled={!isShowMore}
            content={renderTooltipContent()}
          >
          <StyledTimePoint
            left={point.leftPercent}
            ref={dropdownRef}
            onClick={() => {
              setIsShowMore(!isShowMore)
            }}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            {
              point.type === TIMELINE_ELEMENT.GROUP_DETECTED ||
              point.type === TIMELINE_ELEMENT.GROUP_DETECTED_WITH_ATTACK ||
              point.type === TIMELINE_ELEMENT.GROUP_ATTACK ? (
                <StyledCounter>
                  <p>{point.timepoints.length}</p>
                </StyledCounter>
              ) : null
            }
            {
              renderTimePointDot({view: point.type})
            }
              {
                point.timepointsReduced.length 
                ? (
                    <Footnote
                      height={heightsPoints[idx] * 20}
                      points={point.timepointsReduced}
                      style={{opacity: isActive ? 1 : 0.5}}
                    />
                  )
                : (
                    <Footnote
                      height={heightsPoints[idx] * 20}
                      points={[point]}
                      style={{opacity: isActive ? 1 : 0.5}}
                      format={idx === 0 || idx === lastIdx ? 'MM.DD.YYYY, HH:mm:ss' : 'HH:mm:ss'}
                    />
                  )
              }
          </StyledTimePoint>
        </Tooltip>
      </>
    )
}