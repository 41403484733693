import styled from 'styled-components';
import { baseColors, typography } from '../../constants/main';
import { TOAST_TYPE } from './ToastContext';

export const StyledToast = styled.div<{ $type?: TOAST_TYPE; }>`
    position: fixed;
    bottom: 96px;
    right: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 20px;
    gap: 12px;
    background: ${props => props.$type === TOAST_TYPE.SUCCESS ? baseColors.primary() : baseColors.error()};
    border-radius: 8px;
    box-shadow: 0px 4px 25px rgba(18, 13, 33, 0.62);
    z-index: 1001;
`;

export const StyledTitle = styled.span`
    ${typography.text.L};
    font-weight: 600;
`;
