import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ITask, IError } from '@/types';
import { apiClient } from '@/api/apiClient';

interface IInitialState {
    tasks?: ITask[];
    fetching: boolean;
    error: IError;
    checkTaskState: {
        fetching: boolean;
        error: IError;
    };
}

const initialState: IInitialState = {
    tasks: undefined,
    fetching: false,
    error: {
        code: undefined,
        message: undefined,
    },
    checkTaskState: {
        fetching: false,
        error: {
            code: undefined,
            message: undefined,
        },
    },
};

export const getTasks = createAsyncThunk<
    ITask[],
    string,
    { rejectValue: IError }
>('taskSlice/getTasks', async function (uuid, { rejectWithValue }) {
    try {
        const response = await apiClient.get(`/api/v1/labs/${uuid}/tasks/`);
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const checkTasks = createAsyncThunk<
    void,
    { uuid: string; cardId: number },
    { rejectValue: IError }
>(
    'taskSlice/checkTasks',
    async function ({ uuid, cardId }, { rejectWithValue, dispatch }) {
        try {
            await apiClient.get(
                `/api/v1/labs/${uuid}/tasks/${cardId}/check_answer/`
            );
            dispatch(getTasks(uuid));
        } catch (err) {
            console.log('checkTasks: ', err);
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const taskSlice = createSlice({
    name: 'taskSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getTasks.pending, (state) => {
            state.fetching = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTasks.fulfilled, (state, action) => {
            state.tasks = action.payload;
            state.fetching = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTasks.rejected, (state, action) => {
            state.fetching = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });
        //
        builder.addCase(checkTasks.pending, (state) => {
            state.checkTaskState.fetching = true;
            state.checkTaskState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(checkTasks.fulfilled, (state, action) => {
            state.checkTaskState.fetching = false;
            state.checkTaskState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(checkTasks.rejected, (state, action) => {
            state.checkTaskState.fetching = false;
            state.checkTaskState.error.code = action.payload?.code;
            state.checkTaskState.error.message = action.payload?.message;
        });
    },
});

export default taskSlice.reducer;
