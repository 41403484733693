import { ITask } from '@/types';
import { CheckHistory } from '../CheckHistory/CheckHistory';
import { useAppDispatch } from '@/hooks/redux';
import { useParams } from 'react-router-dom';
import { checkTasks } from '@/store/reducers/TaskSlice';
import {
    Block,
    OkIcon,
    FailedIcon,
    ClockIcon,
    EmptyIcon,
    Button,
    TerminalIcon,
} from '@/components';
import { SHAPE } from '@/components/Button/types';
import { baseColors } from '@/constants';
import {
    StyledHeader,
    StyledTitleContainer,
    StyledTitle,
    StyledSubtitle,
    StyledBlockContainer,
    StyledSeparator,
    StyledText,
    StyledTextContainer,
    StyledButtons,
    StyledLabel,
} from './styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps extends ITask {
    disabled?: boolean;
    openTerminal?: () => void;
}

export const TaskCard = (props: IProps) => {
    const {
        id,
        name,
        text,
        points,
        in_progress,
        last_checks,
        is_completed,
        disabled,
        openTerminal,
    } = props;
    const dispatch = useAppDispatch();
    const { uuid } = useParams();

    const renderIcon = () => {
        if (last_checks.length) {
            if (in_progress) {
                return <ClockIcon fill={baseColors.primary()} />;
            }
            if (is_completed) {
                return <OkIcon fill={baseColors.ok()} />;
            }
            return <FailedIcon fill={baseColors.error()} />;
        }
        return <EmptyIcon fill={baseColors.white(0.25)} />;
    };

    const renderSubtitle = () => {
        if (last_checks.length) {
            if (in_progress) {
                return <StyledSubtitle>In progress</StyledSubtitle>;
            }
            if (is_completed) {
                return <StyledSubtitle>Success</StyledSubtitle>;
            }
            return <StyledSubtitle>Failed</StyledSubtitle>;
        }
        return <StyledSubtitle>Not started </StyledSubtitle>;
    };

    const renderHeader = () => {
        return (
            <StyledHeader>
                {renderIcon()}
                <StyledTitleContainer>
                    <StyledTitle>{name}</StyledTitle>
                    {renderSubtitle()}
                </StyledTitleContainer>
            </StyledHeader>
        );
    };

    const isDisabled = !!disabled || in_progress;

    return (
        <Block title={renderHeader()} headerStyle={{ padding: '16px' }}>
            <StyledBlockContainer>
                <StyledTextContainer>
                    <StyledLabel>Description</StyledLabel>
                    <StyledText>{text}</StyledText>
                </StyledTextContainer>
                <StyledTextContainer>
                    <StyledLabel>Points for completing the task</StyledLabel>
                    <StyledText>{points}</StyledText>
                </StyledTextContainer>
                <StyledSeparator />
                <CheckHistory data={last_checks} />
                <StyledSeparator />
                <StyledButtons>
                    <Button
                        icon={<TerminalIcon />}
                        view={SHAPE.PRIMARY}
                        disabled={isDisabled}
                        onClick={() => {
                            if (openTerminal) openTerminal();
                        }}
                    >
                        solve
                    </Button>
                    <Button
                        view={SHAPE.GHOST}
                        disabled={isDisabled}
                        onClick={() => {
                            if (uuid)
                                dispatch(checkTasks({ uuid, cardId: id }));
                        }}
                    >
                        check
                    </Button>
                </StyledButtons>
            </StyledBlockContainer>
        </Block>
    );
};
