import styled from 'styled-components'
import { typography } from '../../../../constants/main'

export const StyledStyledSchemeWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    gap: 34px;
`

export const StyledSchemeTextList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 323px;
    box-sizing: border-box;
    padding-left: 48px;
`

export const StyledSchemeTextItem = styled.div`
    display: flex;
    gap: 12px;
`
export const StyledSchemeTextItemBullit = styled.div<{ color: string }>`
    width: 6px;
    height: 6px;
    min-width: 6px;
    min-height: 6px;
    border-radius: 20px;
    margin-top: 4px;
    background: ${({ color }) => color};
    border: 1px solid rgba(255, 255, 255, 0.1);
`

export const StyledSchemeTextItemTxt = styled.p`
    ${typography.text.S};
`

export const StyledScheme = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
`

export const StyledBlock = styled.div<{ bckg: string }>`
    display: flex;
    width: 212px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    background: ${({ bckg }) => bckg};
    position: relative;
    box-sizing: border-box;
`

export const StyledBlockIcon = styled.div`
    width: 40px;
    height: 40px;
`

export const StyledBlockTextWrapp = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    box-sizing: border-box;
`

export const StyledText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
`

export const StyledTitle = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
`

export const StyledLine = styled.div<{ top: string }>`
    position: absolute;
    top: ${({ top }) => top};
    right: -26px;
`

export const StyledLineTxtBlock = styled.div`
    position: absolute;
    top: calc(50% - 7px);
    background: linear-gradient(
            0deg,
            rgba(20, 90, 219, 0.4),
            rgba(20, 90, 219, 0.4)
        ),
        #2d2a3b;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 2px 6px 3px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    white-space: nowrap;
`

export const StyledLineWrapp = styled.div`
    position: relative;
    height: 66px;
    width: 33px;
    overflow: hidden;
    box-sizing: border-box;
`

export const StyledLineInnerEl = styled.div`
    position: absolute;
    width: 66px;
    height: 66px;
    top: 0;
    left: -33px;
    border: 1px solid #395088;
    border-radius: 200px;
    box-sizing: border-box;
    z-index: 1;
`
