import {
    StyledWrapper,
    StyledTop,
    StyledBottom,
    StyledItem,
    StyledHeadline,
    StyledText,
    StyledStatusWrapper,
    StyledStatusCircle,
    StyledTopLine,
    StyledBottomLine,
  } from "../styled";
  
  import {
    statusCardMessage,
    statusCardText,
    statusCardColor,
  } from "../../../../constants";
  
  export const TimelineHint = () => {
    return (
        <StyledItem width={270}>
        <StyledHeadline>Timeline</StyledHeadline>
        <StyledText>
          unique lab identifier. Currently we have only one lab - Tiffany, but we
          will add more in the future
        </StyledText>
      </StyledItem>
    );
  };
  