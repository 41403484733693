import { useContext, useMemo, ReactNode } from "react";
import { createPortal } from "react-dom";
import { OnboardingContext } from "../../OnboardingContext";
import { Hint } from "./Hint";
import { Window } from "./Window";
import { CardHeader } from "../Steps/CardHeader/CardHeader";
import { CardFooter } from "../Steps/CardFooter/CardFooter";
import { HowToMakeFirstDetect } from "../Steps/HowToMakeFirstDetect/HowToMakeFirstDetect";

export const Onboarding = () => {
  const { isShow, end, next, step, prev, numberOfSteps } = useContext(OnboardingContext);
  const rootOnboarding = document.querySelector("#onboarding");

  const renderComponent: ReactNode = useMemo(() => {
    if (step.step === 4) {
      return (
        <Window
          step={step}
          next={next}
          end={end}
          prev={prev}
          children={<CardHeader />}
          numberOfSteps={numberOfSteps}
        />
      );
    }
    if (step.step === 5) {
      return (
        <Window
          step={step}
          next={next}
          end={end}
          prev={prev}
          children={<CardFooter />}
          numberOfSteps={numberOfSteps}
        />
      );
    }
    if (step.step === 6) {
      return (
        <Window
          step={step}
          next={next}
          end={end}
          prev={prev}
          children={<HowToMakeFirstDetect />}
          numberOfSteps={numberOfSteps}
        />
      );
    }
    return <Hint
      step={step}
      next={next}
      end={end}
      prev={prev}
      numberOfSteps={numberOfSteps}
    />;
  }, [end, next, step, prev, numberOfSteps]);

  if (isShow) {
    document.body.style.overflow = "hidden";
    return createPortal(renderComponent, rootOnboarding!);
  } else {
    document.body.style.overflow = "visible";
    return null;
  }
};
