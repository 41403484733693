import { useMemo } from 'react';
import { useAppSelector } from '../../../../../hooks/redux';
import { NewLaunchCard } from '../../../../../components/Card/Card';
import { LaunchCardProps } from '../../../../../components/Card/types';
import { STATUS_CARD } from '../../../../../components/Card/constants';
import {
    StyledWrapper,
    StyledTop,
    StyledBottom,
    StyledItem,
    StyledHeadline,
    StyledText,
    StyledStatusWrapper,
    StyledStatusCircle,
    StyledTopLine,
    StyledBottomLine,
} from './styled';
import {
    statusCardMessage,
    statusCardText,
    statusCardColor,
} from '../../../../../components/Card/constants';
import { onboardingHintConfig } from '../../../../../components/Card/hooks/OnboardingHint/constants';

export const CardHeader = () => {
    // const { access, refresh } = useAppSelector((state) => state.authReducer);

    const cardsConfig: LaunchCardProps = {
        status: STATUS_CARD.OPERATIONAL,
        deploy_date: '10.05.2023, 00:00',
        planned_destroy_date: '10.05.2024, 00:00',
        uuid: 'ajhsdhjcg767',
        points: 23,
        lab_url: 'https:example.xuy',
        // access,
        // refresh,
        isStaticallyOpen: 'closed',
        difficulty: '0',
        index: 0,
        delay: 0,
        name: 'Python RCE',
    };

    return (
        <StyledWrapper>
            <div style={{ boxSizing: 'border-box', padding: '200px 0' }}>
                <NewLaunchCard
                    {...cardsConfig}
                    onboardingConfig={onboardingHintConfig}
                />
            </div>
        </StyledWrapper>
    );
};
