import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { IScoreboardItem } from '@/types/scoreboard';
import { Tooltip, LoaderDots } from '@/components';
import achive from '@/assets/ach_active.png';
import {
    StyledScoreTable,
    StyledRow,
    StyledRowHeader,
    StyledCell,
    StyledCellHeader,
    StyledAchiveCell,
    StyledLoaderContainer,
    StyledAchieveCounter,
} from './styled';

interface IProps {
    items?: IScoreboardItem[];
    clickOnRow?: (id: IScoreboardItem['id']) => void;
    isLoader?: boolean;
}

export const ScoreboardTable = memo(function ScoreboardTable({
    items = [],
    clickOnRow,
    isLoader,
}: IProps) {
    const { scoreId, privateId } = useParams();
    const RenderRow: FC<IScoreboardItem> = (item) => {
        const { id, user, points, achievements } = item;
        const isActive =
            (!!scoreId && Number(scoreId) === id) ||
            (!!privateId && Number(privateId) === id);
        return (
            <StyledRow
                active={isActive}
                onClick={() => {
                    if (clickOnRow) clickOnRow(id);
                }}
            >
                <StyledCell width="128px">
                    <p>{user}</p>
                </StyledCell>
                <StyledCell width="56px" textAlign="right">
                    <p>{points}</p>
                </StyledCell>

                <StyledAchiveCell>
                    {achievements.length ? (
                        achievements.map((a, idx) => {
                            if (idx <= 3) {
                                return (
                                    <Tooltip
                                        text={a.title}
                                        key={a.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            key={a.id}
                                            src={a.picture ?? achive}
                                            style={{ height: '40px' }}
                                            alt="achieve"
                                        />
                                    </Tooltip>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div style={{ height: '40px' }}>
                            <p>–</p>
                        </div>
                    )}
                    {achievements.length > 5 ? (
                        <StyledAchieveCounter>
                            +{achievements.length - 5}
                        </StyledAchieveCounter>
                    ) : null}
                </StyledAchiveCell>
            </StyledRow>
        );
    };
    return (
        <>
            {isLoader ? (
                <StyledLoaderContainer>
                    <LoaderDots />
                </StyledLoaderContainer>
            ) : (
                <StyledScoreTable>
                    <StyledRowHeader>
                        <StyledCellHeader width="128px">
                            <p>User</p>
                        </StyledCellHeader>
                        <StyledCellHeader width="56px" textAlign="right">
                            <p> Points</p>
                        </StyledCellHeader>
                        <StyledCellHeader width="100%">
                            <p>Achivements</p>
                        </StyledCellHeader>
                    </StyledRowHeader>
                    {items.map((i) => (
                        <RenderRow {...i} key={i.id} />
                    ))}
                </StyledScoreTable>
            )}
        </>
    );
});
