import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const LoaderIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M3 8C3 5.23858 5.23858 3 8 3V1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8H13C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8Z"
                            fill={fill}
                        />
                        <path
                            d="M8.29186 7.33891C8.65695 7.50011 8.82224 7.92675 8.66104 8.29184L10.4906 9.09966C11.098 7.72411 10.4752 6.11666 9.09968 5.50931C7.72413 4.90196 6.11668 5.52471 5.50933 6.90026C4.90199 8.27581 5.52474 9.88326 6.90029 10.4906L7.70811 8.66102C7.34302 8.49982 7.17773 8.07318 7.33893 7.70809C7.50013 7.343 7.92677 7.17771 8.29186 7.33891Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                            fill={fill}
                        />
                        <path
                            d="M12.8493 9.12271C14.4384 9.59174 15.3464 11.2602 14.8773 12.8492L16.7955 13.4154C17.5772 10.7669 16.0639 7.98623 13.4155 7.20452C10.767 6.42281 7.98628 7.93611 7.20457 10.5846C6.42285 13.233 7.93616 16.0138 10.5846 16.7955L11.1508 14.8773C9.56171 14.4083 8.65373 12.7398 9.12276 11.1507C9.59179 9.56166 11.2602 8.65368 12.8493 9.12271Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
