import { useParams } from 'react-router-dom';
import { ILab } from '../../../../types';
import { statusLabText } from '../../../../constants';
import { getDateUTC } from '../../../../utils';
import {
    StyledCard,
    StyledCardContainer,
    StyledCardTitle,
    StyledCardTextStatus,
    StyledStatus,
} from '../../styled';

interface IProps {
    lab: ILab;
    onClick: () => void;
}

export const Launch = (props: IProps) => {
    const { uuid } = useParams();
    const { lab, onClick } = props;
    const { deploy_date, uuid: inputUUID, status } = lab;

    return (
        <StyledCard
            className={uuid === inputUUID ? 'active' : ''}
            onClick={onClick}
        >
            <StyledStatus status={status} />
            <StyledCardContainer>
                <StyledCardTitle>{getDateUTC(deploy_date)}</StyledCardTitle>
                <StyledCardTextStatus>
                    {statusLabText[status]}
                </StyledCardTextStatus>
            </StyledCardContainer>
        </StyledCard>
    );
};
