export const timeLineMessage: any = {
    CREATED: 'Stand was created',
    DETECTED: 'Detect',
    ATTACK: 'Attack',
    DESTROY: 'Lab was destroyed',
}

export enum TIMELINE_ELEMENT {
    CREATED = 'CREATED',
    DETECTED = 'DETECT',
    ATTACK = 'ATTACK_INFO',
    GROUP_DETECTED = 'GROUP_DETECTED',
    GROUP_ATTACK = 'GROUP_ATTACK',
    GROUP_DETECTED_WITH_ATTACK = 'GROUP_DETECTED_WITH_ATTACK',
    DESTROY = 'DESTROY',
}
