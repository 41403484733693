import { useMemo } from 'react';
import {
    Routes,
    Route,
    useNavigate,
    useLocation,
    Navigate,
} from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { authSlice } from '@/store/reducers/AuthSlice';
import { OnboardingProvider } from '@/modules/onboarding/OnboardingProvider';
import { Onboarding } from '@/modules/onboarding/components/Onboarding/Onboarding';
import { InvitePage } from '@/pages/InvitePages/InvitePage/InvitePage';
import { AuthPage } from '@/pages/AuthPages/AuthPage';
import { SignInForm } from '@/pages/AuthPages/components/SignInForm/SignInForm';
import { SignUpForm } from '@/pages/AuthPages/components/SignUpForm/SignUpForm';
import { WriteToUs } from '@/pages/AuthPages/components/WriteToUs/WriteToUs';
import { VerifyPage } from '@/pages/AuthPages/VerifyPage';
import { LandingPage } from '@/pages/CPLP/LandingPage';
import { ITokensData } from '@/types';
import {
    Layout,
    Labs,
    Lab,
    Settings,
    LabLaunches,
    LabStatistics,
    LabLaunch,
} from '@/pages';
import { Info } from '@/pages/LabLaunch/pages/Info/Info';
import { Tasks } from '@/pages/LabLaunch/pages/Tasks/Tasks';
import {
    Scoreboard,
    ScoreboardAllUsers,
    ScoreboardDetail,
    ScoreboardPrivate,
} from '@/pages/ScoreboardV';
import { Workshops } from '@/pages/Workshops/Workshops';
import { Workshop } from '@/pages/Workshops/Workshop';
import { WorkshopsGuest } from '@/pages/Workshops/WorkshopsGuest';
import { WorkshopLab } from '@/pages/Lab/WorkshopLab';
import { WorkshopLabLaunch } from '@/pages/LabLaunch/WorkshopLabLaunch';
import { Price } from '@/pages/Price/Price';

export const AppRouter = () => {
    const { auth: rootAuth } = useAppSelector((state) => state.authReducer);
    const dispath = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const auth = useMemo<boolean>(() => {
        if (!rootAuth) {
            if (typeof localStorage.getItem('token') === 'string') {
                const tokens: ITokensData = JSON.parse(
                    localStorage.getItem('token') as string
                );
                dispath(authSlice.actions.authFetchingAccess(tokens));
                return true;
            }

            if (
                ![
                    '/auth/signup',
                    '/auth/signin',
                    '/auth/write_to_us',
                    '/',
                ].includes(location.pathname)
            ) {
                navigate('/auth/signin');
                return false;
            }
            return false;
        }
        return rootAuth;
    }, [rootAuth, dispath, location, navigate]);

    return (
        <OnboardingProvider>
            <Onboarding />
            <Routes>
                {auth ? (
                    <>
                        <Route path="/" element={<Layout />}>
                            <Route
                                index
                                element={<Navigate to={'labs'} replace />}
                            />
                            <Route path="labs" element={<Labs />} />
                            <Route path="labs/:id" element={<Lab />}>
                                <Route
                                    index
                                    element={<Navigate to="launches" />}
                                />
                                <Route
                                    path="launches"
                                    element={<LabLaunches />}
                                >
                                    <Route
                                        path=":uuid/*"
                                        element={<LabLaunch />}
                                    >
                                        <Route path="info" element={<Info />} />
                                        <Route
                                            path="tasks"
                                            element={<Tasks />}
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path="statistics"
                                    element={<LabStatistics />}
                                />
                            </Route>
                            <Route path="workshops">
                                <Route index element={<Workshops />}></Route>
                                <Route
                                    path=":id"
                                    element={<Workshop />}
                                ></Route>
                                <Route
                                    path="invite"
                                    element={<WorkshopsGuest />}
                                ></Route>
                                <Route
                                    path="invite/:code"
                                    element={<WorkshopsGuest />}
                                ></Route>
                            </Route>
                            <Route path="scoreboard" element={<Scoreboard />}>
                                <Route
                                    index
                                    element={<Navigate to="all_users" />}
                                />
                                <Route
                                    path="all_users/*"
                                    element={<ScoreboardAllUsers />}
                                >
                                    <Route
                                        path=":scoreId"
                                        element={<ScoreboardDetail />}
                                    />
                                </Route>
                                <Route
                                    path="private"
                                    element={<ScoreboardPrivate />}
                                >
                                    <Route
                                        path=":privateId"
                                        element={<ScoreboardDetail />}
                                    />
                                </Route>
                            </Route>
                            <Route path="settings" element={<Settings />} />
                            <Route path="prices" element={<Price />} />
                            <Route path="workshoplab" element={<WorkshopLab />}>
                                <Route
                                    path=":uuid"
                                    element={<WorkshopLabLaunch />}
                                />
                            </Route>
                        </Route>
                    </>
                ) : (
                    <>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="auth" element={<AuthPage />}>
                            <Route
                                index
                                element={<Navigate to="signin" replace />}
                            />
                            <Route path="signin" element={<SignInForm />} />
                            <Route path="signup" element={<SignUpForm />} />
                            <Route path="write_to_us" element={<WriteToUs />} />
                        </Route>
                        <Route path="verify/:uuid" element={<VerifyPage />} />
                        <Route path="invite/:id" element={<InvitePage />} />
                        <Route path="*" element={<AuthPage />} />
                        <Route path="workshops">
                            <Route
                                path="invite"
                                element={<WorkshopsGuest />}
                            ></Route>
                            <Route
                                path="invite/:code"
                                element={<WorkshopsGuest />}
                            ></Route>
                        </Route>
                        <Route path="workshoplab" element={<WorkshopLab />}>
                            <Route
                                path=":uuid"
                                element={<WorkshopLabLaunch />}
                            />
                        </Route>
                    </>
                )}
            </Routes>
        </OnboardingProvider>
    );
};
