import {
  StyledWrapper,
  StyledTop,
  StyledBottom,
  StyledItem,
  StyledHeadline,
  StyledText,
  StyledStatusWrapper,
  StyledStatusCircle,
  StyledTopLine,
  StyledBottomLine,
} from "../styled";

export const DifficultyHint = () => {
  return (
    <StyledItem width={270} style={{ textAlign: "right" }}>
      <StyledHeadline>Difficulty</StyledHeadline>
      <StyledText>
        Difficulty level. Set in the settings - <br />
        from 1 to 10
      </StyledText>
    </StyledItem>
  );
};
