export interface IShowBlockArgs {
  index: number;
  delay: number;
  x: string;
  duration: number;
}

export const animationCardBlock = {
  show: ({ index, delay, x, duration }: IShowBlockArgs) => ({
    x: 0,
    opacity: 1,
    transition: { delay: delay * index, duration: duration },
  }),
  hide: ({ index, delay, x, duration }: IShowBlockArgs) => ({
    x: x,
    opacity: 0,
    transition: { delay: delay * index, duration: duration },
  }),
};

interface ITtoggleExpandingArgs {
  isExpad: boolean;
  duration: number;
  height: number;
}

export const animationFooter = {
  animate: ({ isExpad, duration, height }: ITtoggleExpandingArgs) =>
    isExpad
      ? {
          minHeight: ["40px", "160px", `${height + 40}px`],
          transition: { duration: duration },
        }
      : {
          minHeight: [`${height + 40}px`, "40px", "40px"],
          transition: { duration: duration },
        },
  initial: ({ isExpad, duration, height }: ITtoggleExpandingArgs) =>
    isExpad
      ? {
          minHeight: [`${height + 40}px`, "40px", "40px"],
          transition: { duration: duration },
        }
      : {
          minHeight: ["40px", "160px", `${height + 40}px`],
          transition: { duration: duration },
        },
};
