import { memo, useEffect, useContext, useState, useMemo } from 'react';
import { ToastContext } from '@/components/Toast/ToastContext';
import { css } from 'styled-components';
import { useAppSelector } from '@/hooks/redux';
import {
    Block,
    TimeLine,
    LoaderDots,
    IconButton,
    InfoIcon,
    Select,
    SideBar,
} from '@/components';
import { STATUS_LAB } from '@/constants';
import {
    StyledBlockContainer,
    StyledList,
    StyledBodyText,
    StyledSeparator,
    StyledBlockFooter,
    StyledBlockFooterContainer,
    StyledPage,
} from './styled';
import {
    Information,
    HighlightsList,
    LifeCycle,
    HelthChecks,
    Achivements,
    VMs,
    SIEMInfo,
    LaunchInfo,
} from './components';
import { OnboardingContext } from '@/modules/onboarding/OnboardingContext';
import { VMTerminal } from './components/VMTerminal/index';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { SubscribeBlock } from './components/SubscribeBlock/SubscribeBlock';

const styledTwoCols = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
`;

export const LabLaunchContent = memo(function LabLaunchContent() {
    const { setText, setTitle, setIsShow } = useContext(ToastContext);
    const { start } = useContext(OnboardingContext);
    const { currentCard, stats, isLoadingCurrentCard } = useAppSelector(
        (state) => state.launchCardReducer
    );
    const { HighlightsSlice, HealthChecksSlice, ProfileSlice } = useAppSelector(
        (state) => state
    );
    const { highlights, isLoadingHighlights } = HighlightsSlice;
    const { healthChecks, isLoadingHealthChecks } = HealthChecksSlice;
    const { profile, isLoadingProfile } = ProfileSlice;
    const { siem } = useAppSelector((state) => state.SiemSlice);
    const {
        achivements,
        isLoading: isLoadingAchivements,
        newAchivementsText,
    } = useAppSelector((state) => state.AchivementsSlice);
    const { vms, isLoadingVM } = useAppSelector((state) => state.VMSlice);
    const [currentSshVm, setCurrentSshVm] = useState<IDropdownMenuItem>();

    useEffect(() => {
        if (newAchivementsText && setIsShow && setText && setTitle) {
            setIsShow(true);
            setTitle(`Achievement unlocked. ${newAchivementsText.join(', ')}`);
        }
    }, [newAchivementsText, setIsShow, setText, setTitle]);

    const sshVmOptions = useMemo(
        () => vms.map(({ uuid, name }) => ({ id: uuid!, title: name! })),
        [vms]
    );

    const isLoading =
        !currentCard ||
        (isLoadingCurrentCard && currentCard.status === STATUS_LAB.DEACTIVATED);

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '96px',
                    }}
                >
                    <LoaderDots />
                </div>
            ) : (
                <StyledPage style={{ paddingRight: '4px' }}>
                    <Block title="Lifecycle" isLoading={isLoadingCurrentCard}>
                        {currentCard && (
                            <LifeCycle
                                storyStatus={currentCard?.story_status}
                            />
                        )}
                    </Block>
                    <Block title="Information">
                        <StyledBlockContainer
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                paddingBottom: 0,
                            }}
                        >
                            <StyledBodyText>
                                Here is info about your lab: ELK credentials,
                                Virtual machines, their IPs and installation
                                statuses.
                            </StyledBodyText>
                            <StyledBodyText>
                                A few things that you can do (but not limited
                                to):
                            </StyledBodyText>
                            <StyledList style={{ marginLeft: '12px' }}>
                                <StyledBodyText>
                                    1. Take a look into ELK (don't forget to set
                                    up index patterns)
                                </StyledBodyText>
                                <StyledBodyText>
                                    2. Try to interact with these machines
                                </StyledBodyText>
                                <StyledBodyText>
                                    3. Find interaction logs in ELK
                                </StyledBodyText>
                            </StyledList>
                        </StyledBlockContainer>
                        <StyledBlockContainer styleProps={styledTwoCols}>
                            <StyledList style={{ gap: '16px' }}>
                                <LaunchInfo lab={currentCard} />
                                <SIEMInfo siem={siem} />
                            </StyledList>
                            <VMs
                                vms={vms}
                                isLoading={isLoadingVM}
                                status={currentCard.status}
                            />
                        </StyledBlockContainer>
                        <StyledBlockFooter>
                            <StyledSeparator />
                            <StyledBlockFooterContainer>
                                <span>*</span>
                                <IconButton
                                    label="Elastic guide"
                                    iconLeft={<InfoIcon variant="S" />}
                                    onClick={() => start(6)}
                                />
                            </StyledBlockFooterContainer>
                            <StyledBlockFooterContainer>
                                <span>**</span>
                                <IconButton
                                    label="We deployed Elastic and Kibana for you. Go
                                    there, Get familiar with the events,
                                    investigate the attack and write detection
                                    rules"
                                    stylePropContainer={`
                                    text-align: left;
                                    cursor: default
                                    `}
                                ></IconButton>
                            </StyledBlockFooterContainer>
                        </StyledBlockFooter>
                    </Block>
                    <Block title="Highlights" isLoading={isLoadingHighlights}>
                        {highlights &&
                        (highlights.found.length ||
                            highlights.not_found.length) ? (
                            <HighlightsList list={highlights} />
                        ) : null}
                    </Block>

                    {currentCard &&
                        currentCard.status === STATUS_LAB.OPERATIONAL &&
                        !isLoadingProfile && (
                            <Block title="SSH Terminal">
                                <StyledBlockContainer>
                                    {profile?.is_premium ? (
                                        <>
                                            <Select
                                                options={sshVmOptions}
                                                onChange={(item) =>
                                                    setCurrentSshVm(item)
                                                }
                                                label="Select VM"
                                            />
                                            {currentSshVm && (
                                                <VMTerminal
                                                    uuid={
                                                        currentSshVm?.id as string
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <SubscribeBlock>
                                            SSH access to all VMs
                                        </SubscribeBlock>
                                    )}
                                </StyledBlockContainer>
                            </Block>
                        )}
                    <Block
                        title="Healthchecks"
                        isLoading={isLoadingHealthChecks}
                    >
                        {currentCard &&
                            currentCard.status !== STATUS_LAB.OPERATIONAL && (
                                <StyledBlockContainer
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        paddingBottom: 0,
                                    }}
                                >
                                    <StyledBodyText>
                                        We will show you healthchecks once the
                                        lab is live. Defbox is performing these
                                        checks every few minutes. These
                                        healthchecks would fail if attack
                                        succeeds
                                    </StyledBodyText>
                                </StyledBlockContainer>
                            )}
                        {currentCard &&
                            currentCard.status === STATUS_LAB.OPERATIONAL && (
                                <HelthChecks items={healthChecks} />
                            )}
                    </Block>
                    <Block title="Timeline">
                        <TimeLine
                            uuid={currentCard?.uuid}
                            isHover={false}
                            status={currentCard?.status}
                            attak={currentCard?.attack_button_pressed}
                        />
                        <StyledBlockContainer>
                            {/* <StyledSeparator /> */}
                            <StyledBodyText style={{ marginTop: '20px' }}>
                                Attack is divided into MITRE stages - from
                                Reconnaissance to Impact. If you detected attack
                                after first stage we will give you 1500 points.
                                We will give 100 points less for every next
                                stage, eg if you detected attack after impact we
                                will give you 100 points. Points are calculated
                                after lab is finished
                            </StyledBodyText>
                        </StyledBlockContainer>
                    </Block>

                    <Block title="Statistics">
                        <Information stats={stats} />
                        <StyledBlockFooter>
                            <StyledSeparator />
                            <StyledBlockFooterContainer>
                                <span>*</span>
                                <IconButton
                                    label="We take the maximum points you earned before this lab and show you difference"
                                    stylePropContainer={`
                                    text-align: left;
                                    cursor: default
                                    `}
                                ></IconButton>
                            </StyledBlockFooterContainer>
                        </StyledBlockFooter>
                    </Block>

                    <Block
                        title="Achievements"
                        isLoading={isLoadingAchivements}
                    >
                        <Achivements achievements={achivements ?? []} />
                    </Block>
                </StyledPage>
            )}
        </>
    );
});
