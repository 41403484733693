import styled, { css } from 'styled-components'
import { baseColors, baseColorHover, typography } from '../../constants/main'
import { CARD_FIELDS } from './constants'
import { widthFieldGenerator } from './styledGenerators'

const Scrolled = css`
    box-shadow: 0px 4px 4px rgba(18, 13, 33, 0.25);
    position: sticky;
    top: 48px;
    z-index: 2;
    border-radius: 0 0 8px 8px;
    border-top: 0;
`

export const StyledCard = styled.div<{ scrolled?: boolean; isHover: boolean }>`
    background: ${({ isHover }) =>
        isHover ? baseColorHover.card(1) : baseColors.card(1)};
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: relative;
    ${({ scrolled }) => (scrolled ? Scrolled : null)};
`

export const StyledMain = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 32px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding-bottom: 0;
`

export const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    width: calc(100% - 48px);
`

export const StyledActionBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 12px;
`

export const StyledStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    position: relative;
`

export const StyledCircle = styled.div<{ color: string }>`
    margin: 4px 4px 0 4px;
    width: 12px;
    height: 12px;
    background: ${({ color }) => color};
    border-radius: 14px;
`

export const StyledKeyValue = styled.div<{ field?: CARD_FIELDS }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    position: relative;
    max-width: ${({ field }) =>
        field ? widthFieldGenerator(field) + 'px' : '100%'};
    width: ${({ field }) =>
        field ? widthFieldGenerator(field) + 'px' : '100%'};
`

const textEllipsis = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledTextValue = styled.p`
    ${typography.text.M};
    ${textEllipsis};
`

export const StyledTextKey = styled.p`
    ${typography.text.M};
    ${textEllipsis};
    opacity: 0.72;
`

export const StyledFooterContainer = styled.div<{ isHover: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background-color: ${({ isHover }) =>
        isHover ? baseColorHover.board(1) : baseColors.board(1)};
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 0;
`

export const StyledFooterDivider = styled.div`
    width: calc(100% - 96px);
    height: 1px;
    background: #fff;
    opacity: 0.1;
`

export const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    border-radius: 8px;
`

export const StyledFooterHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 8px 48px;
    width: 100%;
    box-sizing: border-box;
`

export const StyledFooterBody = styled.div`
    box-sizing: border-box;
    width: 100%;
`

export const StyledFooterContentContainer = styled.div`
    box-sizing: border-box;
    padding: 20px 0;
`

export const StyledFooterTitleWrapper = styled.div`
    display: flex;
    gap: 8px;
    aligne-items: center;
`

export const StyledFooterTitle = styled.span`
    ${typography.text.M};
    opasity: 0.72;
    font-weight: 700;
`
export const StyledCounterContainer = styled.div`
    padding-left: 12px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    align-items: center;
    > span {
        ${typography.text.S};
    }
`
export const StyledCounter = styled.div<{ color?: string }>`
    background: ${({ color }) => color || baseColors.primary()};
    color: #fff;
    box-sizing: border-box;
    padding: 0 6px;
    border-radius: 4px;
    > span {
        ${typography.text.S};
    }
`

export const StyledSeparator = styled.div`
    min-width: 1px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.06);
`

export const StyledAttackButtonPressed = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 12px 24px;
    padding-top: 4px;
    gap: 4px;
    position: relative;
    > span {
        ${typography.text.M};
        color: ${baseColors.error()};
        font-weight: 600;
    }
`
