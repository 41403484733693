import { ILab } from '@/types/labs';
import { CopyItem } from './components';
import { StyledList, StyledTitle } from './styled';


interface IProps {
    lab?: ILab;
}

export const LaunchInfo = ({ lab }: IProps) => {
    return (
        <StyledList>
            <StyledTitle>Launch</StyledTitle>
            <CopyItem
                cut
                keyText="Interface URL"
                valueText={lab?.lab_url ?? undefined}
                link={lab?.lab_url ?? undefined}
            />
            <CopyItem
                cut
                keyText="POST request *"
                valueText={
                    lab?.uuid
                        ? `${process.env.REACT_APP_API_URL}/api/v1/labs/${lab?.uuid}/detect/`
                        : undefined
                }
            />
        </StyledList>
    );
};
