import { useEffect, useState } from "react";
import { StyledMoreInformations, StyledTitle } from "./styled";
import { KeyValue } from "../UI/KeyValue/KeyValue";
import { IconButton } from "../../../IconButton/IconButton";
import { IconOpenedEye, IconClosedEye, IconLink } from "../../../Icons/index";
import { Tooltip } from "../../../Tooltip/Tooltip";

interface SIEMInformationProps {
  interfaceURL: string | null;
  password: string | null;
  userName: string | null;
  title?: string;
}

export const SIEMInformation = (props: SIEMInformationProps) => {
  const { interfaceURL, password: inputPassword, userName, title } = props;
  const [password, setPassword] = useState<string | null>(inputPassword);
  const [isShowPass, setIsShowPass] = useState(false);

  const textTooltipInPass = isShowPass ? "Hide password" : "Show password";
  const renderIconButtonInPass = () => (
    <>
      {isShowPass ? <IconClosedEye size={20} /> : <IconOpenedEye size={20} />}
    </>
  );

  useEffect(() => {
    if (isShowPass) setPassword(inputPassword);
    if (!isShowPass) {
      if (inputPassword) {
        const passArr = [...Array(inputPassword.length)].fill("*");
        setPassword(passArr.join(""));
      }
    }
  }, [isShowPass, setPassword, inputPassword]);
  return (
    <StyledMoreInformations>
      {
        title && (
          <StyledTitle>{title}</StyledTitle>
        )
      }
      <KeyValue keyText="User name" valueText={userName} />
      <KeyValue
        keyText="Password"
        valueText={password}
        icon={
          password ? (
            <Tooltip text={textTooltipInPass}>
              <IconButton
                icon={renderIconButtonInPass()}
                onClick={() => setIsShowPass(!isShowPass)}
              />
            </Tooltip>
          ) : undefined
        }
      />
      <KeyValue
        keyText="Interface URL"
        valueText={interfaceURL}
        icon={
          interfaceURL ? (
            <a href={interfaceURL} rel="noreferrer" target="_blank">
              <Tooltip text="Link">
                <IconButton icon={<IconLink size={20} />} />
              </Tooltip>
            </a>
          ) : undefined
        }
      />
    </StyledMoreInformations>
  );
};
