import { ISiem } from '@/types/labs';
import { StyledList, StyledTitle } from './styled';
import { PasswordItem, CopyItem } from './components';
import { TextItem } from '@/components';

interface IProps {
    siem?: ISiem;
}

export const SIEMInfo = ({ siem }: IProps) => {
    return (
        <StyledList>
            <StyledTitle>SIEM</StyledTitle>
            <TextItem
                cut
                widthKey={102}
                keyText="User name"
                valueText={siem?.username ?? undefined}
            />
            <PasswordItem password={siem?.password ?? undefined} />
            <CopyItem
                cut
                keyText="ELK **"
                valueText={siem?.interface_url ?? undefined}
                link={siem?.interface_url ?? undefined}
            />
        </StyledList>
    );
};
