import { useAppSelector } from '@/hooks/redux';
import { STATUS_LAB } from '@/constants';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import {
    IconButton,
    PlayIcon,
    StopIcon,
    RefreshIcon,
    Timer,
    AttackIcon,
    DropdownMenu,
} from '@/components';
import { Loader } from './Loader.ts/Loader';
import { HelpButton } from './HelpButton/HelpButton';
import { useTimer } from '../../hooks/useTimer';
import {
    StyledActions,
    StyledLoaderContainer,
    StyledAverageTime,
} from './styled';

interface IProps {
    iCanRun?: boolean;
    status: STATUS_LAB;
    endTime: string;
    recreated: boolean;
    runLab?: () => void;
    destroyLab?: () => void;
    refreshLab?: () => void;
    runAttack: (playbookId: number | string) => void;
}

export const Actions = ({
    runLab,
    destroyLab,
    refreshLab,
    status,
    endTime,
    runAttack,
    recreated,
}: IProps) => {
    const { lab } = useAppSelector((state) => state.LabsSlice);
    const { cards } = useAppSelector((state) => state.launchCardReducer);
    const isDisabled =
        status === STATUS_LAB.SET_UP_VM ||
        status === STATUS_LAB.DEPLOY ||
        status === STATUS_LAB.TEAR_DOWN;

    const isAverageTimer =
        status === STATUS_LAB.SET_UP_VM || status === STATUS_LAB.DEPLOY;

    const averageTimer = useTimer({
        start: isDisabled,
        deployDate: cards[0]?.deploy_date,
    });

    const getAverageTimer = () => {
        if (isAverageTimer) {
            return `${averageTimer} / ${
                recreated
                    ? lab?.average_deployment_time_no_siem?.replace(/^00:/, '')
                    : lab?.average_deployment_time_siem?.replace(/^00:/, '')
            }`;
        }
        return '';
    };

    if (status === STATUS_LAB.DEACTIVATED) {
        return (
            <StyledActions>
                <IconButton
                    size="M"
                    label="Start a lab"
                    iconLeft={<PlayIcon />}
                    onClick={() => {
                        if (runLab) runLab();
                    }}
                />
                <HelpButton />
            </StyledActions>
        );
    }
    if (status === STATUS_LAB.OPERATIONAL || isDisabled) {
        const attackOptions: IDropdownMenuItem[] =
            lab && lab.attackers
                ? lab?.attackers.map((a) => ({
                      id: a.id,
                      title: a.playbook,
                      description: a.description,
                  }))
                : [];

        return (
            <StyledActions>
                {isDisabled && (
                    <StyledLoaderContainer>
                        <Loader status={status} text={getAverageTimer()} />
                    </StyledLoaderContainer>
                )}
                {status === STATUS_LAB.OPERATIONAL && (
                    <Timer endTime={endTime} />
                )}
                <IconButton
                    size="M"
                    label="Destroy"
                    disabled={isDisabled}
                    iconLeft={<StopIcon />}
                    onClick={() => {
                        if (destroyLab) destroyLab();
                    }}
                />
                <IconButton
                    size="M"
                    label="Recreate"
                    disabled={isDisabled}
                    iconLeft={<RefreshIcon />}
                    onClick={() => {
                        if (refreshLab) refreshLab();
                    }}
                />
                <DropdownMenu
                    list={attackOptions}
                    onClick={(id) => runAttack(id)}
                >
                    <IconButton
                        size="M"
                        label="Attack"
                        disabled={isDisabled}
                        iconLeft={<AttackIcon />}
                    />
                </DropdownMenu>
                <HelpButton />
            </StyledActions>
        );
    }
    return <></>;
};
