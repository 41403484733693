import { memo, useEffect } from 'react';
import { getVM, vmSlice } from '@/store/reducers/VMSlice';
import {
    achivementsSlice,
    getAchievements,
} from '@/store/reducers/AchievementsSlice';
import {
    getHighlights,
    highlightsSlice,
} from '@/store/reducers/HighlightsSlice';
import {
    getWorkshopLaunchById,
    getStats,
} from '@/store/reducers/LaunchCardsSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getSiems, siemSlice } from '@/store/reducers/SiemSlice';
import {
    getHealthChecks,
    healthChecksSlice,
} from '@/store/reducers/HealthChecksSlice';
import { LabLaunchContent } from './LabLaunchContent';
import { useParams } from 'react-router-dom';
import { STATUS_LAB } from '@/constants';
import { getTimeline } from '@/store/reducers/TimelineSlice';
import { useWorkshopStatusPollingRequest } from '@/hooks/useStatusPollingRequest/useWorkshopStatusPollingRequest';
import { useCurrentLabRequestConfig } from '../Lab/composable/useCurrentLabConfig';

export const WorkshopLabLaunch = memo(() => {
    const { uuid } = useParams();
    const dispatch = useAppDispatch();
    const { currentCard } = useAppSelector((state) => state.launchCardReducer);
    const { LabsSlice } = useAppSelector((state) => state);
    const { lab } = LabsSlice;

    const labRequestConfig = useCurrentLabRequestConfig();

    useEffect(() => {
        dispatch(highlightsSlice.actions.reset());
        dispatch(healthChecksSlice.actions.resetHealthChecks());
        dispatch(siemSlice.actions.resetSiem());
        dispatch(achivementsSlice.actions.reset());
        dispatch(vmSlice.actions.reset());
        dispatch(getWorkshopLaunchById(uuid!));
        const interval = setInterval(() => {
            getWorkshopLaunchById(uuid!);
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        dispatch(getSiems());
        if (currentCard?.status === STATUS_LAB.OPERATIONAL) {
            dispatch(getHealthChecks(labRequestConfig));
        }
    }, [currentCard, currentCard?.status, dispatch, labRequestConfig]);

    useEffect(() => {
        if (currentCard?.uuid) {
            dispatch(getHighlights(labRequestConfig));
            dispatch(getStats(labRequestConfig));
            dispatch(getAchievements(labRequestConfig));
            dispatch(getTimeline(labRequestConfig));
            dispatch(getVM(labRequestConfig));
        }
    }, [currentCard?.uuid, dispatch, labRequestConfig]);
    useWorkshopStatusPollingRequest({ currentCard, lab });
    return <LabLaunchContent />;
});
