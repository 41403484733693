import styled from 'styled-components';
import { typography, baseColors } from '@/constants';
import { IHealthChecks } from '@/types/healthChecks';

type StatusType = IHealthChecks['result'];

export const StyledItem = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTitle = styled.p`
    ${typography.title.M};
`;

export const StyledDescription = styled.p`
    ${typography.title.M};
    color: ${baseColors.white(0.5)};
`;

export const StyledIconContainer = styled.div<{ status: StatusType }>`
    height: 44px;
    width: 44px;
    border-radius: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ status }) =>
        status === 'OK' ? baseColors.ok(0.12) : baseColors.error(0.12)};
`;
