import { useState, useEffect } from 'react';
import { Input } from '../../../../components/Input/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { StyledFormContainer, StyledFieldsContainer } from '../../styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiClient } from '../../../../api/apiClient';

export const VerifyForm = () => {
    const navigate = useNavigate();
    const goToSignIn = () => navigate('/auth');
    const location = useLocation();
    const [uuid, setUuid] = useState('');
    const [pass, setPass] = useState('');

    useEffect(() => {
        const pathNames = location.pathname
            .split('/')
            .filter((el) => el !== '');
        setUuid(pathNames[pathNames.length - 1]);
    }, [location, setUuid]);

    const clickHandler = () => {
        try {
            apiClient
                .post(
                    '/api/users/verify_email/',
                    {
                        uuid: uuid,
                        password: pass,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then(() => {
                    goToSignIn();
                });
        } catch (err) {
            console.log('err: ', err);
        }
    };
    const isDisabled = pass === '';
    return (
        <StyledFormContainer>
            <StyledFieldsContainer>
                <Input
                    id="pass"
                    type="password"
                    label="Password"
                    onChange={(p) => setPass(p)}
                />
                <Button disabled={isDisabled} onClick={clickHandler}>
                    Log in
                </Button>
            </StyledFieldsContainer>
        </StyledFormContainer>
    );
};
