import { SyntheticEvent } from 'react';
import { StyledMenuItemButton } from '../../styled';
import {
    UserIcon,
    MoreIcon,
    EditIcon,
    ExitIcon,
    IconButton,
} from '@/components';

interface IProps {
    name: string;
    cut?: boolean;
    onEdit?: (e: SyntheticEvent) => void;
    onExit?: (e: SyntheticEvent) => void;
}

export const ItemMenuProfile = ({ name, cut, onEdit, onExit }: IProps) => {
    return (
        <StyledMenuItemButton as={'div'}>
            <div className="item" style={{ fontSize: 14 }}>
                <div className="titleWrapper">
                    <UserIcon />
                    {!cut && <p>{name}</p>}
                </div>

                {!cut && (
                    <>
                        <div className="moreIcon">
                            <MoreIcon variant="S" />
                        </div>
                        <div className="actions">
                            <IconButton
                                size="S"
                                icon={<EditIcon variant="S" />}
                                onClick={(e) => onEdit && onEdit(e)}
                            />
                            <IconButton
                                size="S"
                                icon={<ExitIcon variant="S" />}
                                onClick={(e) => onExit && onExit(e)}
                            />
                        </div>
                    </>
                )}
            </div>
        </StyledMenuItemButton>
    );
};
