import styled from 'styled-components'
import { typography } from '../../../../constants/main'

export const StyledMoreInformations = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px 20px 48px;
    gap: 8px;
`

export const StyledTitle = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`
