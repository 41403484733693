import axios, { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { authSlice } from '@/store/reducers/AuthSlice';
import { RootState, AppDispatch } from '@/store/store';
import { ITokensData } from '@/types';

export type TNoticeType = 'success' | 'error' | 'process' | 'fetching';

export interface INoticeSystemError {
    code: string;
    message: string;
}

export interface INoticeModal {
    type: TNoticeType;
    text: string;
    content?: string;
}

let store: ToolkitStore<RootState>;

export const injectStore = (_store: ToolkitStore<RootState>) => {
    store = _store;
};

interface IAPIClient extends AxiosInstance {
    isRestoringSession: boolean;
}

interface CustomRequestConfig extends AxiosRequestConfig {
    requestName?: string;
}

const tokenToInterceptor = () => {
    const { access, refresh, access_lifetime, refresh_lifetime, start_time } =
        store.getState().authReducer;
    const tokenObj = {
        access: access ?? 'not_token',
        refresh: refresh ?? 'not_token',
        access_lifetime: access_lifetime,
        refresh_lifetime: refresh_lifetime,
        start_time: start_time,
    };

    if (!access || !refresh) {
        const localTokens: ITokensData | null =
            typeof localStorage.getItem('token') === 'string'
                ? JSON.parse(localStorage.getItem('token') as string)
                : null;
        if (!!localTokens) {
            authSlice.actions.authFetchingAccess({
                ...localTokens,
            });

            tokenObj.access = localTokens.access;
            tokenObj.refresh = localTokens.refresh;
            tokenObj.access_lifetime = localTokens.access_lifetime;
            tokenObj.refresh_lifetime = localTokens.refresh_lifetime;
            tokenObj.start_time = localTokens.start_time;
        }
    }
    return tokenObj;
};

const AXIOS = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
}) as IAPIClient;

AXIOS.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        let error = err as AxiosError;
        return Promise.reject(error);
    }
);

AXIOS.interceptors.request.use(async (config) => {
    if (config.url === 'api/token/' || config.url === '/api/token/') {
        config = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: 'Basic xxxxxxxxxxxxxxxxxxx',
                'Content-Type': 'application/json',
            },
        };
        return config;
    }
    const { access, refresh, access_lifetime, start_time } =
        tokenToInterceptor();
    if (config.url === '/api/token/refresh/') {
        config = {
            ...config,
            data: {
                refresh: refresh,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return config;
    }

    const now = Date.now();
    const dispatch = store.dispatch as AppDispatch;
    if (now >= start_time + access_lifetime * 1000) {
        dispatch(authSlice.actions.refreshStartTime(now));
        try {
            const response = await AXIOS.post('/api/token/refresh/');
            const data = await response.data.access;
            dispatch(authSlice.actions.refreshAuthSaccess(data));

            config = {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${data}`,
                    'Content-Type': 'application/json',
                },
            };
            return config;
        } catch (err) {
            dispatch(authSlice.actions.exitAuth());
        }
    } else {
        config = {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${access}`,
                'Content-Type': 'application/json',
            },
        };
        return config;
    }

    return config;
});

export const apiClient = {
    get: (url: string, config?: CustomRequestConfig) => AXIOS.get(url, config),
    post: (url: string, data: unknown, headers?: CustomRequestConfig) => {
        if (headers) {
            return AXIOS.post(url, data, headers);
        }
        return AXIOS.post(url, data);
    },
};
