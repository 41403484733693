import {
  StyledWrapper,
  StyledTop,
  StyledBottom,
  StyledItem,
  StyledHeadline,
  StyledText,
  StyledStatusWrapper,
  StyledStatusCircle,
  StyledTopLine,
  StyledBottomLine,
} from "../styled";

export const TimeToDestroy = () => {
  return (
    <StyledItem width={270} style={{ textAlign: "right" }}>
      <StyledHeadline>Time to destroy</StyledHeadline>
      <StyledText>
        Countdown to the planned
        <br /> destroy date
      </StyledText>
    </StyledItem>
  );
};
