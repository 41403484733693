import styled, { css } from 'styled-components'
import { IOnboardingHint } from '../../types'

type StyledLHintType = Omit<
    IOnboardingHint,
    'children' | 'lineHeight' | 'contentDirection'
>

type StyledLineType = Omit<
    IOnboardingHint,
    'children' | 'contentDirection' | 'size'
>

type StyledContainerChildrenType = Omit<
    IOnboardingHint,
    'children' | 'lineHeight' | 'size'
>

const generateHintDirection = ({ direction, size }: StyledLHintType) => {
    switch (direction) {
        case 'top-left':
        case 'bottom-left':
            return css`
                left: -${Math.round(size / 2)}px;
                top: 0;
                ::after {
                    content: '';
                    display: block;
                    width: 25%;
                    height: 1px;
                    position: absolute;
                    background: rgba(255, 255, 255, 0.12);
                    left: 0;
                    top: 50%;
                }
            `
        case 'bottom':
            return css`
                top: ${size + 4}px;
                left: 50%;
            `
    }
}

export const StyledHint = styled.div<StyledLHintType>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    position: absolute;
    z-index: 1;
    ${generateHintDirection};
`

const generateLineDirection = ({ direction }: StyledLineType) => {
    switch (direction) {
        case 'top-left':
            return css`
                bottom: 50%;
            `
        case 'bottom-left':
            return css`
                top: 50%;
            `
    }
}

export const StyledLine = styled.div<StyledLineType>`
    height: ${({ lineHeight }) => lineHeight}px;
    width: 16px;
    position: absolute;

    ${generateLineDirection};
    ::before {
        content: '';
        display: block;
        width: 1px;
        position: absolute;
        height: 100%;
        background: rgba(255, 255, 255, 0.12);
    }
`

const generateContentDirection = ({
    direction,
    contentDirection,
}: StyledContainerChildrenType) => {
    switch (direction) {
        case 'bottom':
        case 'bottom-right':
        case 'bottom-left':
            return css`
                top: calc(100% - 10px);
                ${contentDirection === 'left'
                    ? 'left: 12px;'
                    : 'right: calc(100% + 12px);'}
            `
        case 'top':
        case 'top-left':
        case 'top-right':
            return css`
                top: -10px;
                ${contentDirection === 'left'
                    ? 'left: 12px;'
                    : 'right: calc(100% + 12px);'}
            `
    }
}

export const StyledContainerChildren = styled.div<StyledContainerChildrenType>`
    ${generateContentDirection}
    position: absolute;
`
