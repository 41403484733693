import styled from "styled-components";
import { typography } from "../../../../constants/main";

export const StyledMoreInformations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px 20px 48px;
  gap: 8px;
`;

export const StyledMIItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  box-sizing: border-box;
`;

export const StyledMIItemKey = styled.span`
  box-sizing: border-box;
  padding-top: 2px;
  opacity: 0.72;
  ${typography.text.M};
  width: 278px;
  min-width: 278px;
`;

export const StyledMIItemValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  box-sizing: border-box;
  width: 100%;
`;

export const StyledMIItemValueText = styled.p`
  box-sizing: border-box;
  padding-top: 2px;
  ${typography.text.M};
`;
