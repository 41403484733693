import { useState, useContext, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../hooks/redux";
import { authSlice } from "../../../../store/reducers/AuthSlice";
import { InvitePageContext } from "../../InvitePage/InvitePageContext";
import { Input } from "../../../../components/Input/Input/Input";
import { Button } from "../../../../components/Button/Button";
import { Alert } from "../../../../components/Alert/Alert";
import { VARIANT } from "../../../../components/Alert/types";
import { LoaderDots } from "../../../../components/Loader/LoaderDots/LoaderDots";
import { baseColors } from "../../../../constants/main";
import { StyledForm, StyledFormButtonContainer } from "../../styled";

export const AuthForm = () => {
  const dispatch = useAppDispatch();
  const { isLoading, error } = useAppSelector((state) => state.authReducer);
  const {
    user: rootUser,
    pass: rootPass,
    logIn,
  } = useContext(InvitePageContext);
  const [user, setUser] = useState<string>(rootUser);
  const [pass, setPass] = useState<string>(rootPass);
  const btnsEnabled = user !== "" && pass !== "";
  const isError = error.code !== undefined || error.message !== undefined;

  useEffect(() => {
    dispatch(
      authSlice.actions.authFetchingError({
        code: undefined,
        message: undefined,
      })
    );
  }, []);

  const clickHandler = () => {
    logIn(user, pass);
  };

  return (
    <>
      {isLoading && (
        <LoaderDots color={baseColors.primary()} pageOverlay={true} />
      )}

      {isError && (
        <Alert
          style={{ marginTop: "32px" }}
          variant={VARIANT.ERROR}
          code={error.code}
          message={error.message}
        />
      )}
      <StyledForm>
        <Input label="User" value={user} onChange={(e) => setUser(e)} />
        <Input
          type="password"
          label="Password"
          value={pass}
          onChange={(e) => setPass(e)}
        />
        <StyledFormButtonContainer>
          <Button disabled={!btnsEnabled} onClick={clickHandler}>
            Login
          </Button>
        </StyledFormButtonContainer>
      </StyledForm>
    </>
  );
};
