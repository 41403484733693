import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledActions = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledLoaderContainer = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 12px;
`;

export const StyledAverageTime = styled.span`
    ${typography.text.S};
`;
