import { baseColors, typography } from '@/constants';
import styled from 'styled-components';


export const StyledSubscribeBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    > p {
        ${typography.text.L}
        color: ${baseColors.white(0.7)};
    }
`