import { EmptyIcon, GeoIcon, InnerMarkedText } from '@/components';
import { STORY_STATUS, storyStatusText, baseColors } from '@/constants';
import {
    StyledLifeCycle,
    StyledContainer,
    // StyledBottomContainer,
    StyledItem,
    StyledArrowContainer,
    StyledHorizontalLine,
    StyledVerticalLine,
    StyledTextContainer,
    StyledTextBlock,
    StyledTitle,
    StyledText,
} from './styled';
import { StyledBlockContainer } from '../../styled';
import ArrowLeft from './assets/LCArrowLeft.svg';
import ArrowRight from './assets/LCArrowRight.svg';
import ArrowTop from './assets/LCArrowTop.svg';
import ArrowBottom from './assets/LCArrowBottom.svg';
import { lifeCycleTexts, TEXT_TYPE } from './constants';

interface IProps {
    storyStatus: STORY_STATUS;
}

export const LifeCycle = ({ storyStatus }: IProps) => {
    const getIcon = (status: STORY_STATUS) => {
        if (storyStatus === status) {
            return <GeoIcon fill={baseColors.ok()} variant="S" />;
        }
        return <EmptyIcon variant="S" />;
    };
    return (
        <StyledBlockContainer>
            <StyledTextContainer>
                {lifeCycleTexts.map((lt) => (
                    <StyledTextBlock
                        key={lt.title}
                        style={
                            storyStatus !== lt.status
                                ? { opacity: 0.25 }
                                : undefined
                        }
                    >
                        <StyledTitle>{lt.title}</StyledTitle>
                        <StyledText>
                            {lt.text.map((t) => {
                                if (t.type === TEXT_TYPE.SIMPLE) {
                                    return t.text;
                                }
                                return <InnerMarkedText text={t.text} />;
                            })}
                        </StyledText>
                    </StyledTextBlock>
                ))}
            </StyledTextContainer>

            <StyledLifeCycle>
                <StyledContainer>
                    <StyledItem active={storyStatus === STORY_STATUS.DEPLOY}>
                        {getIcon(STORY_STATUS.DEPLOY)}
                        <span>{storyStatusText.DEPLOY}</span>
                    </StyledItem>
                    <StyledArrowContainer direction="row" jContent="flex-end">
                        <StyledHorizontalLine direction="right" />
                        <img src={ArrowRight} alt="" />
                    </StyledArrowContainer>
                    <StyledItem active={storyStatus === STORY_STATUS.PROTECT}>
                        {getIcon(STORY_STATUS.PROTECT)}
                        <span>{storyStatusText.PROTECT}</span>
                    </StyledItem>
                    <StyledArrowContainer direction="row" jContent="flex-end">
                        <StyledHorizontalLine direction="right" />
                        <img src={ArrowRight} alt="" />
                    </StyledArrowContainer>
                    <StyledItem active={storyStatus === STORY_STATUS.HACK}>
                        <span>{storyStatusText.HACK}</span>
                        {getIcon(STORY_STATUS.HACK)}
                    </StyledItem>
                </StyledContainer>
                <StyledContainer style={{ padding: '0 9px' }}>
                    <StyledArrowContainer
                        direction="column"
                        jContent="flex-start"
                    >
                        <StyledVerticalLine direction="top" />
                        <img src={ArrowTop} alt="" />
                    </StyledArrowContainer>
                    <StyledArrowContainer
                        direction="column"
                        jContent="flex-end"
                    >
                        <StyledVerticalLine direction="bottom" />
                        <img src={ArrowBottom} alt="" />
                    </StyledArrowContainer>
                </StyledContainer>
                <StyledContainer>
                    <StyledItem active={storyStatus === STORY_STATUS.REDEPLOY}>
                        {getIcon(STORY_STATUS.REDEPLOY)}
                        <span>{storyStatusText.REDEPLOY}</span>
                    </StyledItem>
                    <StyledArrowContainer direction="row" jContent="flex-start">
                        <StyledHorizontalLine direction="left" />
                        <img src={ArrowLeft} alt="" />
                    </StyledArrowContainer>
                    <StyledItem
                        active={storyStatus === STORY_STATUS.INVESTIGATE}
                    >
                        <span>{storyStatusText.INVESTIGATE}</span>
                        {getIcon(STORY_STATUS.INVESTIGATE)}
                    </StyledItem>
                </StyledContainer>
                <StyledContainer
                    style={{ padding: '0 9px', justifyContent: 'flex-end' }}
                >
                    <StyledArrowContainer
                        direction="column"
                        jContent="flex-end"
                    >
                        <StyledVerticalLine direction="bottom" />
                        <img src={ArrowBottom} alt="" />
                    </StyledArrowContainer>
                </StyledContainer>
                <StyledContainer style={{ justifyContent: 'flex-end' }}>
                    <StyledItem active={storyStatus === STORY_STATUS.DESTROY}>
                        <span>{storyStatusText.DESTROY}</span>
                        {getIcon(STORY_STATUS.DESTROY)}
                    </StyledItem>
                </StyledContainer>
            </StyledLifeCycle>
        </StyledBlockContainer>
    );
};
