import styled from 'styled-components';
import { typography } from '@/constants/main';

export const StyledPage = styled.div``;

export const StyledTitleContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 218px;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;
