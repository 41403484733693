import styled from 'styled-components';
import { baseColors, typography, shadow } from '@/constants';

export const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 40px;
`;

export const StyledTextBody = styled.div`
    ${typography.text.L};
    max-width: 768px;
`;

export const StyledHighlights = styled.div<{ isFound: boolean }>`
    box-sizing: border-box;
    padding: 16px 16px 24px 8px;
    display: flex;
    gap: 12px;
    background: ${({ isFound }) =>
        isFound ? baseColors.levelThree(0) : baseColors.levelThree()};
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
    &:hover {
        ${({ isFound }) => !isFound && shadow.levelTwo.levelOne};
    }
`;

export const StyledContainer = styled.div<{ gap: number }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap}px;
`;

export const StyledIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledTitle = styled.p`
    ${typography.title.M};
`;

export const StyledSubtitle = styled.p`
    ${typography.text.S};
    opacity: 0.5;
`;

export const StyledSubtitleKey = styled.span`
    ${typography.title.S};
    opacity: 0.5;
`;

export const StyledPointsContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledPointKey = styled.span`
    ${typography.text.S};
`;

export const StyledPointValue = styled.span`
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
`;

export const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
`;
