import React, { ReactNode } from 'react';

export enum SHAPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GHOST = 'ghost',
}

export interface IButton {
    type?: 'submit' | 'button';
    icon?: ReactNode;
    style?: React.CSSProperties;
    children?: ReactNode;
    view?: SHAPE;
    disabled?: boolean;
    onClick?: (e: any) => void;
}
