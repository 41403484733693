import styled, { css } from "styled-components";
import { typography, baseColors } from "../../constants/main";

export const StyledInvitePage = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100vw;
  height: 100vh;
`;

export const StyledLogoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    201.09deg,
    rgba(20, 90, 219, 0) 21.11%,
    rgba(20, 90, 219, 0.2) 49.99%,
    rgba(20, 90, 219, 0) 78.37%
  );
`;

export const StyledLogoText = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
`;

export const StyledLogoDiscription = styled.p`
  ${typography.text.M};
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  white-space: nowrap;
`;

export const StyledFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 0 30% 0 96px;
  position: relative;
  overflow: hidden;
`;

export const StyledForm = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
`;

export const StyledFormButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 8px;
`;

export const StyledFormHeadline = styled.p`
  ${typography.text.L};
  font-weight: 700;
`;

export const StyledFormHeadlineContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDivider = styled.div`
  position: relative;
  min-width: 97px;
  height: 360px;
  display: flex;
  ::before {
    content: "";
    display: block;
    position: absolute;
    min-width: 1px;
    left: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.12);
  }
`;

export const StyledFactoidsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 8px;
`;

// new invite page

export const StyledInvite = styled.div`
  background-color: ${baseColors.primary()};
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 64px 96px;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  box-sizing: border-box;
  background-color: ${baseColors.bckg()};
  padding: 72px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 56px;
  max-width: 1088px;
  min-width: 890px;
  margin: 0 auto;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`;

export const StyledTicket = styled.div`
  background-color: ${baseColors.primary()};
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

export const StyledPoligonContainer = styled.div<{
  bottom: number;
  left: number;
  rotate: number;
}>`
  position: absolute;
  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;
  transform: rotate(${({ rotate }) => rotate}deg);
`;

export const StyledTicketHeader = styled.div`
  padding: 40px 48px 48px 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;
`;

export const StyledProffitList = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  list-style-type: none;
  margin: 0;
`;

export const StyledProffitItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  > p {
    ${typography.text.M};
  }
`;

export const StyledKeyAndValue = styled.div``;

export const StyledKey = styled.p`
  ${typography.text.S};
`;

export const StyledValue = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #fff;
`;

export const StyledSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 56px 0;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const StyledSepatorItem = styled.div`
  width: 24px;
  height: 24px;
  background: ${baseColors.bckg()};
  border-radius: 100px;
`;

const generateSeparatorItemBigPosition = (position: "first" | "last") => {
  if (position === "first") {
    return css`
      right: 0;
      top: 0;
    `;
  }
  return css`
    left: 0;
    top: 0;
  `;
};

export const StyledSeparatorItemBig = styled.div<{
  position: "first" | "last";
}>`
  position: relative;
  height: 96px;
  width: 48px;
  ::before {
    content: "";
    display: block;
    height: 96px;
    width: 96px;
    position: absolute;
    background: ${baseColors.bckg()};
    border-radius: 200px;
    ${({ position }) => generateSeparatorItemBigPosition(position)};
  }
`;

export const StyledTicketFooter = styled.div`
  box-sizing: border-box;
  padding: 0 48px 64px 48px;
  position: relative;
  z-index: 1;
`;

export const StyledFactoid = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledFactoidValue = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 128px;
  line-height: 72px;
  color: ${baseColors.primary()};
`;

export const StyledFactoidKey = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${baseColors.primary()};
`;

export const StyledRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledContainerForm = styled.div`
  box-sizing: border-box;
  padding-top: 20px;
`;

export const StyledContainerFormLogoHeader = styled.div`
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 32px;
  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
  }
`;

export const StyledPropmo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: space-between;
`;

export const StyledPromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 48px;
  padding-bottom: 32px;
`;

export const StyledPromoTitle = styled.p`
  ${typography.text.L};
  color: rgba(255, 255, 255, 0.8);
`;

export const StyledPromoText = styled.p`
  ${typography.text.M};
  color: rgba(255, 255, 255, 0.64);
`;
