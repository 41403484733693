import { IOnboardingHintConfig } from '../../types';
import {
    StatusHint,
    AttackHint,
    DestroyHint,
    DifficultyHint,
    InfoHint,
    LabNameHint,
    MoreHint,
    PlannedDestroyHint,
    TimeToDestroy,
    TimelineHint,
} from './components/index';
import {
    CARD_FIELDS,
    ACTION_BAR_BUTTONS,
    CARD_FOOTER_FIELDS,
} from '../../constants';

export const onboardingHintConfig: IOnboardingHintConfig = {
    [CARD_FIELDS.STATUS]: {
        size: 20,
        direction: 'top-left',
        contentDirection: 'left',
        lineHeight: 196,
        children: StatusHint(),
    },
    [CARD_FIELDS.LAB_NAME]: {
        size: 20,
        direction: 'bottom-left',
        contentDirection: 'left',
        lineHeight: 164,
        children: LabNameHint(),
    },
    [CARD_FIELDS.DIFFICULTY]: {
        size: 20,
        direction: 'bottom-left',
        contentDirection: 'right',
        lineHeight: 258,
        children: DifficultyHint(),
    },
    [CARD_FIELDS.PLANED_DESTROY_DATA]: {
        size: 20,
        direction: 'top-left',
        contentDirection: 'left',
        lineHeight: 228,
        children: PlannedDestroyHint(),
    },
    [CARD_FIELDS.TIME_TO_DESTROY]: {
        size: 20,
        direction: 'top-left',
        contentDirection: 'right',
        lineHeight: 112,
        children: TimeToDestroy(),
    },
    ATTACK: {
        size: 24,
        direction: 'bottom',
        contentDirection: 'right',
        lineHeight: 316,
        children: AttackHint(),
    },
    DESTROY: {
        size: 24,
        direction: 'bottom',
        contentDirection: 'right',
        lineHeight: 215,
        children: DestroyHint(),
    },
    INFO: {
        size: 24,
        direction: 'bottom',
        contentDirection: 'right',
        lineHeight: 115,
        children: InfoHint(),
    },
    MORE: {
        size: 24,
        direction: 'bottom',
        contentDirection: 'right',
        lineHeight: 320,
        children: MoreHint(),
    },
    TIMELINE: {
        size: 20,
        direction: 'bottom-left',
        contentDirection: 'left',
        lineHeight: 136,
        children: TimelineHint(),
    },
};
