import { FC, useEffect, useState } from 'react';
import { StyledPageContainer } from '../styled';
import { Button, IconButton, IconLink, SideBar } from '@/components';
import { getDateUTC } from '@/utils/date';
import { openModal } from '@/store/reducers/ActionCreators';
import { useAppDispatch } from '@/hooks/redux';
import { useAppSelector } from '../../hooks/redux';
import { getWorkshops } from '@/store/reducers/WorkshopsSlice';
import {
    StyledCell,
    StyledCellHeader,
    StyledRow,
    StyledRowHeader,
    StyledTable,
} from '@/components/Table/styled';
import { useNavigate } from 'react-router-dom';
import { CreateWorkshop } from '@/components/Forms';
import { SideBarContext } from '@/context/sideBarContext';

const TableHeader = ({ onClick }: { onClick?: (e: any) => void }) => (
    <h2>
        Workshops <Button onClick={onClick}>Create</Button>
    </h2>
);
export const Workshops: FC = () => {
    const dispatch = useAppDispatch();
    const { WorkshopsSlice } = useAppSelector((state) => state);
    useEffect(() => {
        dispatch(getWorkshops());
    }, [dispatch]);
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(false);
    return (
        <StyledPageContainer style={{ alignItems: 'center' }}>
            <SideBarContext.Provider value={{ isOpened, setIsOpened }}>
                <TableHeader onClick={() => setIsOpened(true)} />
                <StyledTable>
                    <StyledRowHeader>
                        <StyledCellHeader width="50px">Status</StyledCellHeader>
                        <StyledCellHeader width="50px">Lab</StyledCellHeader>
                        <StyledCellHeader width="50px">
                            Student count
                        </StyledCellHeader>
                        <StyledCellHeader width="100px">
                            Start date
                        </StyledCellHeader>
                        <StyledCellHeader width="100px">
                            End date
                        </StyledCellHeader>
                        <StyledCellHeader width="20px"></StyledCellHeader>
                    </StyledRowHeader>

                    {WorkshopsSlice.workshops.map((item) => (
                        <StyledRow>
                            <StyledCell width="50px">
                                {item.active ? 'Active' : 'Not active'}
                            </StyledCell>
                            <StyledCell width="50px">
                                {item.lab_template}
                            </StyledCell>
                            <StyledCell width="50px">
                                {item.students_count}
                            </StyledCell>
                            <StyledCell width="100px">
                                {getDateUTC(item.start_date)}
                            </StyledCell>
                            <StyledCell width="100px">
                                {getDateUTC(item.finish_date)}
                            </StyledCell>
                            <StyledCell width="20px">
                                <IconButton
                                    icon={<IconLink />}
                                    onClick={() =>
                                        navigate(`/workshops/${item.id}`)
                                    }
                                />
                            </StyledCell>
                        </StyledRow>
                    ))}
                </StyledTable>
                {/* <SideBar title="Create workshop">
                        <CreateWorkshop/>
                </SideBar> */}
            </SideBarContext.Provider>
        </StyledPageContainer>
    );
};
