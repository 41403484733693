import { CopyItem, Tooltip } from "@/components";
import { StyledCellHeader, StyledRowHeader, StyledTable, StyledRow, StyledCell } from "@/components/Table/styled"
import { useAppSelector } from "@/hooks/redux";
import { StyledAchieveCounter } from '../../../components/Table/styled';
import { baseColors } from '../../../constants/main';

export const LaunchedLabs = ({}) => {
    const { WorkshopsSlice } = useAppSelector(state => state);
    const getLink = (uuid: string) => `${window.location.origin}/workshoplab/${uuid}/`;
    return <StyledTable style={{marginBottom: 10}}>
        <StyledRowHeader style={{backgroundColor: baseColors.levelTwo()}}>
            <StyledCellHeader width="80px">Student name</StyledCellHeader>
            <StyledCellHeader width="50px">Attempts</StyledCellHeader>
            <StyledCellHeader width="50px">Points</StyledCellHeader>
            <StyledCellHeader width="500px">Link</StyledCellHeader>
            <StyledCellHeader width="100px">Achievements</StyledCellHeader>
        </StyledRowHeader>
        
        { WorkshopsSlice.workshop?.labs.map(item => <StyledRow>
                <StyledCell width="80px">
                    { item.student_name }
                </StyledCell>
                <StyledCell width="50px">
                    {item.attempts}
                </StyledCell>
                <StyledCell width="50px">
                    {item.points}
                </StyledCell>
                <StyledCell width="500px">
                    <CopyItem valueText={getLink(item.uuid)}></CopyItem>
                    
                </StyledCell>
                <StyledCell width="50px">
                {item.achievements.length ? (
                        item.achievements.map((a, idx) => {
                            if (idx <= 3) {
                                return (
                                    <Tooltip
                                        text={a.title}
                                        key={a.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            key={a.id}
                                            src={a.picture || "/"}
                                            style={{ height: '40px' }}
                                            alt="achieve"
                                        />
                                    </Tooltip>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div style={{ height: '40px' }}>
                            <p>–</p>
                        </div>
                    )}
                    {item.achievements.length > 5 ? (
                        <StyledAchieveCounter>
                            +{item.achievements.length - 5}
                        </StyledAchieveCounter>
                    ) : null}
                </StyledCell>
        </StyledRow>) }
    </StyledTable>
} 