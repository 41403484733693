import styled from 'styled-components';
import { typography } from '../../constants/main';

export const StyledNoData = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 522px;
    text-align: center;
    gap: 8px;
`;

export const StyledTitle = styled.span`
    ${typography.text.L};
    font-weight: 700;
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

export const StyledButtons = styled.div`
    display: flex;
    gap: 8px;
`;
