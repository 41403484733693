import { ReactNode } from 'react';
import { PagesTabs } from '../../';
import { IData } from '../../Tabs/PagesTabs/types';
import {
    StyledPageHeader,
    StyledTitle,
    StyledNavigation,
    StyledTitleContainer,
} from './styled';

interface IProps {
    title: ReactNode;
    tabs?: IData[];
    actions?: ReactNode;
}

export const PageHeader = ({ title, tabs, actions }: IProps) => {
    const renderTitle = () => {
        if (typeof title === 'string') {
            return (
                <StyledTitleContainer>
                    <StyledTitle>{title}</StyledTitle>
                </StyledTitleContainer>
            );
        }
        return title;
    };
    return (
        <StyledPageHeader>
            <StyledNavigation>
                {renderTitle()}
                {tabs && <PagesTabs navLink data={tabs} size="L" />}
            </StyledNavigation>
            {actions}
        </StyledPageHeader>
    );
};
