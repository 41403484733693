import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

const openedOverlayStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    background: ${baseColors.black(0.8)};
`;

const closedOverlayStyle = css`
    position: absolute;
    height: 100%;
    box-sizing: border-box;
    padding: 8px 0;
    right: 0;
    top: 0;
    width: 48px;
    max-width: 48px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledOverlay = styled.div<{ isOpened: boolean }>`
    ${({ isOpened }) => (isOpened ? openedOverlayStyle : closedOverlayStyle)};
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
`;

export const StyledCloseArea = styled.div`
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const openedSideCellStyle = css`
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;
`;

const closedSideCellStyle = css`
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 48px;
    min-width: 48px;
`;

const closedSideCellWithOpenedBarStyle = css`
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;
    width: 48px;
    min-width: 48px;
`;

const generateSideCellStyle = ({
    width,
    isOpened,
    isOpenedBar,
}: {
    width?: string;
    isOpened: boolean;
    isOpenedBar: boolean;
}) => {
    if (isOpened && isOpenedBar) {
        return css`
            ${openedSideCellStyle};
            width: ${!!width ? width : '640px'};
            min-width: ${!!width ? width : '640px'};
        `;
    }
    if (!isOpened && isOpenedBar) {
        return closedSideCellWithOpenedBarStyle;
    }
    return closedSideCellStyle;
};

export const StyledSideCell = styled.div<{
    width?: string;
    order: number;
    isOpened: boolean;
    isOpenedBar: boolean;
}>`
    background: ${baseColors.levelTwo()};
    order: ${({ order }) => order};
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    ${generateSideCellStyle};
`;

const openedHeaderStyle = css`
    height: 48px;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid ${baseColors.white(0.1)};
    opacity: 1;
    padding-left: 16px;
`;

const closedHeaderStyle = css`
    transform: rotate(90deg);
    transform-origin: bottom left;
    width: calc(100vh - 48px);
    height: 48px;
    margin-top: -32px;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

export const StyledHeader = styled.div<{ isOpened: boolean }>`
    display: flex;
    position: relative;
    gap: 16px;
    box-sizing: border-box;
    ${({ isOpened }) => (isOpened ? openedHeaderStyle : closedHeaderStyle)};
`;

export const StyledExpandBtn = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledTitleContainer = styled.div<{ isOpened: boolean }>`
    display: flex;
    gap: 12px;
    align-items: center;
    width: max-content;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
    text-wrap: nowrap;
`;

export const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
    padding: 96px 56px 0;
`;
