import { Icon } from "../Icon";

interface IconPlayProps {
  figureFill?: string;
  bckgFill?: string;
  size?: number;
}

export const IconPlay = (props: IconPlayProps) => {
  const { figureFill, bckgFill, size } = props;
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            fill={bckgFill || "#145ADB"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 9.06079V14.9392L14.762 12L11 9.06079ZM12.7709 6.63728C10.8763 5.15711 8 6.42017 8 8.73227V15.2677C8 17.5798 10.8763 18.8429 12.7709 17.3627L16.9534 14.095C18.3489 13.0047 18.3489 10.9953 16.9534 9.90502L12.7709 6.63728Z"
            fill={figureFill || "white"}
          />
        </>
      }
    />
  );
};
