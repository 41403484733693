import { LabContext } from "@/context/labContext"
import { useAppSelector } from "@/hooks/redux";
import { UuidWithBasePath } from "@/store/reducers/types/general";
import { useContext, useMemo } from "react"

export const useCurrentLabRequestConfig = (): UuidWithBasePath => {
    const { labUrl } = useContext(LabContext);
    const { currentCard } = useAppSelector(
        (state) => state.launchCardReducer
    );
    const result = useMemo(() => ({basePath: labUrl, uuid: currentCard?.uuid || ""}), [currentCard?.uuid, labUrl]);
    return result;
}