import styled from 'styled-components';
import { typography, baseColors, baseColorHover } from '@/constants/main';

export const StyledOverlay = styled.div<{ top: number; left: number }>`
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    box-shadow: 0px 6px 20px rgba(18, 13, 33, 0.46);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

export const StyledDropdownContainer = styled.div`
    position: absolute;
    z-index: 100000;
    box-shadow: 0px 6px 20px rgba(18, 13, 33, 0.46);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

export const StyledDropdown = styled.div`
    height: fit-content;
`;

export const StyledDropdownMenu = styled.div``;

export const StyledDropdownMenuItem = styled.div`
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    background: ${baseColors.board()};
    :hover {
        background: ${baseColorHover.board()};
    }
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledTitle = styled.p`
    ${typography.text.M};
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;
